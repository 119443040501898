import React, { Component } from 'react'
import {TransparentButton,CircleButton} from '../../components/Button'
import {connect} from 'react-redux'
import uuid from '../../libs/functions/uuid'
import Input from '../../components/Input'
import Icon from '../../components/Icon'
import {firebaseDatabase} from '../../config/FirebaseUtils'
import moment from 'moment'


class EditEvent extends Component {
    constructor(props){
        super(props);
        this.state = {
            user:''
        }
    }
    componentDidMount(){
        this.setState({user:this.props.user})
    }
    render(){
        let event = this.props.event?this.props.event:{};

        if(this.props.newEvent){
            event.id = uuid()
            event.speakerID = this.state.user.key
            event.userOwner = this.state.user.key
            event.public = true
            event.status = 1
            event.createdAt = moment().format('DD/MM/YYYY HH:mm:ss')
            event.pesquisas = []
        }

        return(
            <div style={{backgroundColor:'rgba(130,130,130,0.5)',position:'absolute', justifyContent:'center', alignItems:'center', display:'flex',height:'100vh', width:'100vw'}}>
            <div style={{backgroundColor:'white', display:'flex', flexDirection:'column', height:450, width:400, borderRadius:20, padding:20}}>
                <div style={{width:'100%',height:30, display:'flex', justifyContent:'flex-end', alignItems:'flex-start'}}>
                    <TransparentButton onClick={()=>{this.props.close()}}>
                        <Icon name={'cancelar'} style={{fontSize:30, color:'#FF6600'}}/>
                    </TransparentButton>
                </div>
                <div style={{flex:1, width:'100%'}}>
                    <p style={styles.label}>{this.props.newEvent?"CRIAR NOVO EVENTO":"EDITAR EVENTO"}</p>
                    <span style={styles.label}>Titulo</span>
                    <Input 
                        onTextChange={(name)=>{event.name = name}} 
                        placeHolder={'Evento...'}
                        defaultValue={event.name} 
                        style={styles.input}/>

                    <span style={styles.label}>Local / Endereço</span>
                    <Input 
                        onTextChange={(address)=>{event.address = address}}
                        placeHolder={'Rua exemplo, nº10...'} 
                        defaultValue={event.address}  
                        style={styles.input}/>

                    <span style={styles.label}>Data</span>
                    <Input 
                        onTextChange={(date)=>{event.date = moment(date,"YYYY-MM-DD").format('DD/MM/YYYY')}} 
                        type={'date'} 
                        defaultValue={moment(event.date,'DD/MM/YYYY').format("YYYY-MM-DD")} 
                        placeHolder={'Data (dd/mm/aaaa)'} 
                        style={styles.input}/>

                    <div style={{display:'flex',justifyContent:'center'}}>
                        <div style={{margin:0, flex:1}}>
                            <span style={styles.label}>Ínicio</span>
                            <Input 
                                onTextChange={(init)=>{event.initTime = init}} 
                                type={'time'} 
                                defaultValue={event.initTime} 
                                placeHolder={'Início (HH:mm)'} 
                                style={{marginRight:5}}/>
                        </div>
                        <div style={{margin:0, flex:1}}>
                            <span style={styles.label}>Final Aproximado</span>
                            <Input 
                                onTextChange={(end)=>{event.endTime = end}} 
                                type={'time'} 
                                defaultValue={event.endTime} 
                                placeHolder={'Fim aproximado (HH:mm)'} 
                                style={{marginLeft:5}}/>
                        </div>
                    </div>
                    <div style={{width:'100%', height:50, display:'flex', justifyContent:'flex-end', alignItems:'flex-end'}}>
                    <CircleButton 
                    style={{backgroundColor:'#FF6600', height:35, width:35}}
                    label={{text:'OK',style:{fontWeight:'bold',color:'white',fontSize:12,marginBottom:0}}} 
                    onClick={()=>{
                        console.log("chamado",'firebase edit event')
                        if(event.id && event.userOwner){
                            firebaseDatabase
                            .ref('events')
                            .child(event.id)
                            .update({...event})
                            .then(()=>{
                                firebaseDatabase
                                .ref('events')
                                .child(event.id)
                                .once('value')
                                .then((snap)=>{
                                    console.log(snap.val())
                                })
                            })
                        }
                    }}/>
                    </div>
                </div>
            </div>
        </div>
        )
    }
}

const styles =  {
    label:{
        fontSize:14, 
        fontWeight:'bold', 
        color:'#666', 
        marginLeft:5
    },
    input:{
        marginBottom:10
    }
}

const mapStateToProps = state => ({
    event : state.EventReducers.event,
});

const mapDispatchToProps = {
};
  
export default connect(mapStateToProps, mapDispatchToProps)(EditEvent);