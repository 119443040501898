import {combineReducers} from 'redux';
import SectionReducers from '../libs/redux/reducers/SectionReducers';
import UserReducer from '../libs/redux/reducers/UserReducer';
import EventReducers from '../libs/redux/reducers/EventReducers'
import AnalyticsReducer from '../libs/redux/reducers/AnalyticsReducer'
import EnquetesReducer from '../libs/redux/reducers/EnquetesReducer'

const AppReducers = combineReducers({
    SectionReducers,
    EventReducers,
    UserReducer,
    AnalyticsReducer,
    EnquetesReducer
})

export default AppReducers;
