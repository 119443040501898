import React, {Component} from 'react';
import { Provider } from 'react-redux';
import {store, persistor} from './config/Store';
import {PersistGate} from 'redux-persist/integration/react'
import Routes from './config/Routes'

var timer;

class App extends Component {

  constructor(props){
    super(props);
    this.state = {
      width:window.innerWidth
    }
  }

  componentDidMount(){
    timer = setInterval(()=>{
      this.setState({width:window.innerWidth})
    },1000) 
  }

  render() {
    return (
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <Routes/>
        </PersistGate>
      </Provider>
    )
  }
}

export default App;
