import { LOADING, LOAD_EVENTS, LOAD_ANALYTICS } from '../../../config/Types'
import {firebaseDatabase} from '../../../config/FirebaseUtils';
import _ from 'lodash';
import moment from 'moment'

export const loadAnalytics = (event, interval) => {
  return (dispatch) => {

    dispatch({
      type: LOADING,
      payload: true
    })

    firebaseDatabase
      .ref('users')
      .once('value', (data) => {
        let users = (data) ? _.values(data.val()) : [];

        let sections = [];
        let textNotes = 0;
        let photoNotes = 0;
        let markNotes = 0;
        //let emoteNotes = 0;

        let happyNotes = 0
        let sadNotes = 0
        let loveNotes = 0
        let deterNotes = 0
        let zzzNotes = 0
        //Adição do emote importante
        let importanteNotes = 0

        let totalNotes = 0;

        users.forEach((user) => {
          if (user.sections) {
            user.sections.forEach((s) => {
              if (s.event_id === event.id) sections.push(s);
            })
          }
        })

        console.log('calculing analytics', interval)
        //console.log('all sections for this event: ', sections);
        //let analytics = '';

        sections.forEach((s) => {

          //REALIZA CONTAGEM DE NOTAS
          if (s.notes) {
            s.notes.forEach((n) => {
              if (n.type === 'P') photoNotes = photoNotes + 1;
              if (n.type === 'T') textNotes = textNotes + 1;
              //if (n.type == 'ios-happy') emoteNotes = emoteNotes + 1;
              if (n.type === 'happy') happyNotes = happyNotes + 1;
              if (n.type === 'sad') sadNotes = sadNotes + 1;
              if (n.type === 'love') loveNotes = loveNotes + 1;
              if (n.type === 'determinado') deterNotes = deterNotes + 1;
              if (n.type === 'zzz') zzzNotes = zzzNotes + 1;
              //Adição do emote importante
              if (n.type === 'importante') importanteNotes = importanteNotes + 1;

              if (n.type === 'M') markNotes = markNotes + 1;
            })
          }
        })

        let totals = [
          { value: happyNotes, text: `${(happyNotes > 1) ? ' emotes' : ' emote'} Feliz/Animado`, type: 'happy' },
          { value: sadNotes, text: `${(sadNotes > 1) ? ' emotes' : ' emote'} Triste/Desanimado`, type: 'sad' },
          { value: loveNotes, text: `${(loveNotes > 1) ? ' emotes' : ' emote'} Gostei/Amei`, type: 'love' },
          { value: deterNotes, text: `${(deterNotes > 1) ? ' emotes' : ' emote'} Determinado`, type: 'determinado' },
          { value: zzzNotes, text: `${(zzzNotes > 1) ? ' emotes' : ' emote'} Cansativo`, type: 'zzz' },
          //Adição do emote importante
          { value: importanteNotes, text: `${(importanteNotes > 1) ? ' emotes' : ' emote'} Importante`, type: 'importante' },
          { value: textNotes, text: ` ${(textNotes > 1) ? 'notas' : 'nota'} de texto`, type: 'T' },
          { value: photoNotes, text: ` ${(photoNotes > 1) ? 'notas' : 'nota'} de foto`, type: 'P' },
          { value: markNotes, text: ` ${(markNotes > 1) ? 'notas' : 'nota'} de mark`, type: 'M' },
          //{ value: emoteNotes, text: ` ${(emoteNotes > 1) ? 'notas' : 'nota'}  Legal`, type: 'ios-happy' },
        ]

        let totalToPdf = [];

        totals.map(n => {
          totalToPdf.push(n.value)
        })

        console.log(totalToPdf)
        totals.sort((a, b) => b.value - a.value)
        //Calculo de notas adicionadas de 10 em 10 minutos

        let initTimeEventInMS = calculeMS(event.initTime + ':00');
        let endTimeEventInMS = calculeMS(event.endTime + ':00');

        // console.log('initTimeEventInMS', initTimeEventInMS)
        // console.log('endTimeEventInMS', endTimeEventInMS)

        let separateTimes = [initTimeEventInMS];

        while (initTimeEventInMS < endTimeEventInMS) {
          initTimeEventInMS = initTimeEventInMS + ((interval * 1000) * 60);
          separateTimes.push(initTimeEventInMS)
        }

        // console.log('separateTimes', separateTimes)
        // separateTimes.forEach(s => {
        //   console.log(moment('00:00:00', 'hh:mm:ss').add(s).format("HH:mm:ss"))
        // });


        let textNotesInTime = [];
        let photoNotesInTime = [];
        let markNotesInTime = [];
        //let emoteInTime = [];
        let happyNotesInTime = []
        let sadNotesInTime = []
        let loveNotesInTime = []
        let deterNotesInTime = []
        let zzzNotesInTime = []
        //Adição do emote importante
        let importanteNotesInTime = []

        //CRIA UM ARRAY COM TODAS AS NOTAS ADICINOADAS NO EVENTO, FACILITANDO A MANIPULAÇÃO
        let allNotes = [];

        sections.forEach((s) => {
          if (s.notes) {
            s.notes.forEach((n) => {
              allNotes.push(n);
            })
          }
        })

        // console.log(sections)
        // console.log(allNotes)

        //CALCULA QUANTIDADE DE NOTAS ADICIONADAS DE TEMPO EM TEMPO
        separateTimes.forEach((s, index) => {
          photoNotesInTime[index] = 0;
          textNotesInTime[index] = 0;
          markNotesInTime[index] = 0;
          //emoteInTime[index] = 0;
          happyNotesInTime[index] = 0;
          sadNotesInTime[index] = 0;
          loveNotesInTime[index] = 0;
          deterNotesInTime[index] = 0;
          //Adição do emote importante
          importanteNotesInTime[index] = 0;

          allNotes.forEach(n => {
            let noteMS = calculeMS(n.registerHour);
            //console.log(noteMS)
            if (noteMS >= s && noteMS <= (s + ((interval * 1000) * 60))) {
              //FOI ADICIONADA NESSE INTERVALO
              if (n.type === 'P') photoNotesInTime[index] = photoNotesInTime[index] + 1;
              if (n.type === 'T') textNotesInTime[index] = textNotesInTime[index] + 1;
              if (n.type === 'M') markNotesInTime[index] = markNotesInTime[index] + 1;
              // if (n.type == 'ios-happy') emoteInTime[index] = emoteInTime[index] + 1;
              if (n.type === 'happy') happyNotesInTime[index] = happyNotesInTime[index] + 1;
              if (n.type === 'sad') sadNotesInTime[index] = sadNotesInTime[index] + 1;
              if (n.type === 'love') loveNotesInTime[index] = loveNotesInTime[index] + 1;
              if (n.type === 'determinado') deterNotesInTime[index] = deterNotesInTime[index] + 1;
              if (n.type === 'zzz') zzzNotesInTime[index] = zzzNotesInTime[index] + 1;
              //Adição do emote importante
              if (n.type === 'importante') importanteNotesInTime[index] = importanteNotesInTime[index] + 1;
            }
          });
        });

        let notesTime = [];

        let textNotesTime = [];
        let photoNotesTime = [];
        let markNotesTime = [];
        //let emoteNotesTime = [];
        let happyNotesTime = []
        let sadNotesTime = []
        let loveNotesTime = []
        let deterNotesTime = []
        let zzzNotesTime = []
        //Adição do emote importante
        let importanteNotesTime = []

        let maxPhotoNote = { y: 0 };
        let maxPhotoNotes = [];

        let maxTextNote = { y: 0 };
        let maxTextNotes = [];

        let maxMarkNote = { y: 0 };
        let maxMarkNotes = [];

        let maxHappyNote = { y: 0 };
        let maxHappyNotes = []

        let maxSadNote = { y: 0 };
        let maxSadNotes = []

        let maxLoveNote = { y: 0 };
        let maxLoveNotes = []

        let maxDeterNote = { y: 0 };
        let maxDeterNotes = []

        let maxZzzNote = { y: 0 };
        let maxZzzNotes = []

        //Adição do emote importante
        let maxImportanteNote = { y: 0 };
        let maxImportanteNotes = []

        separateTimes.forEach((s, index) => {
          notesTime[index] = {
            'time': `${moment('00:00:00', 'hh:mm:ss').add((s + (interval * 1000) * 60)).format("HH:mm")}`,
            'textNotes': textNotesInTime[index],
            'photoNotes': photoNotesInTime[index],
            'markNotes': markNotesInTime[index],
            //'emoteNotes': emoteInTime[index],
            'happyNotes': happyNotesInTime[index],
            'sadNotes': sadNotesInTime[index],
            'loveNotes': loveNotesInTime[index],
            'deterNotes': deterNotesInTime[index],
            'zzzNotes': zzzNotesInTime[index],
            //Adição do emote importante
            'importanteNotes': importanteNotesInTime[index],

            'total': (textNotesInTime[index] + photoNotesInTime[index] + markNotesInTime[index] + happyNotesInTime[index] + sadNotesInTime[index] + loveNotesInTime[index] + deterNotesInTime[index] + zzzNotesInTime[index] + importanteNotesInTime[index])
          };

          textNotesTime[index] = {
            'x': `${moment('00:00:00', 'hh:mm:ss').add((s + (interval * 1000) * 60)).format("HH:mm")}`,
            'y': textNotesInTime[index],
            //'y': Math.round(Math.random() * 20)
          };

          /*emoteNotesTime[index] = {
            'x': `${moment('00:00:00', 'hh:mm:ss').add((s + (interval * 1000) * 60)).format("HH:mm")}`,
            'y': emoteInTime[index],
            //'y': Math.round(Math.random() * 20)
          };
          */
          happyNotesTime[index] = {
            'x': `${moment('00:00:00', 'hh:mm:ss').add((s + (interval * 1000) * 60)).format("HH:mm")}`,
            'y': happyNotesInTime[index],
            //'y': Math.round(Math.random() * 20)
          };
          sadNotesTime[index] = {
            'x': `${moment('00:00:00', 'hh:mm:ss').add((s + (interval * 1000) * 60)).format("HH:mm")}`,
            'y': sadNotesInTime[index],
            //'y': Math.round(Math.random() * 20)
          };
          loveNotesTime[index] = {
            'x': `${moment('00:00:00', 'hh:mm:ss').add((s + (interval * 1000) * 60)).format("HH:mm")}`,
            'y': loveNotesInTime[index],
            //'y': Math.round(Math.random() * 20)
          };
          deterNotesTime[index] = {
            'x': `${moment('00:00:00', 'hh:mm:ss').add((s + (interval * 1000) * 60)).format("HH:mm")}`,
            'y': deterNotesInTime[index],
            //'y': Math.round(Math.random() * 20)
          };

          zzzNotesTime[index] = {
            'x': `${moment('00:00:00', 'hh:mm:ss').add((s + (interval * 1000) * 60)).format("HH:mm")}`,
            'y': zzzNotesInTime[index],
            //'y': Math.round(Math.random() * 20)
          };
          //Adição do emote importante

          importanteNotesTime[index] = {
            'x': `${moment('00:00:00', 'hh:mm:ss').add((s + (interval * 1000) * 60)).format("HH:mm")}`,
            'y': importanteNotesInTime[index],
            //'y': Math.round(Math.random() * 20)
          };

          photoNotesTime[index] = {
            'x': `${moment('00:00:00', 'hh:mm:ss').add((s + (interval * 1000) * 60)).format("HH:mm")}`,
            'y': photoNotesInTime[index],
            //'y': Math.round(Math.random() * 15),
          };

          markNotesTime[index] = {
            'x': `${moment('00:00:00', 'hh:mm:ss').add((s + (interval * 1000) * 60)).format("HH:mm")}`,
            'y': markNotesInTime[index],
            //'y': Math.round(Math.random() * 10),
          };

          //TOP PHOTOS NOTES
          if (photoNotesTime[index].y > maxPhotoNote.y) maxPhotoNote = photoNotesTime[index];

          //TOP TEXT NOTES
          if (textNotesTime[index].y > maxTextNote.y) maxTextNote = textNotesTime[index];

          //TOP MARK NOTES
          if (markNotesTime[index].y > maxMarkNote.y) maxMarkNote = markNotesTime[index];

          //if (emoteNotesTime[index].y > maxEmoteNote.y) maxEmoteNote = emoteNotesTime[index];
          if (happyNotesTime[index].y > maxHappyNote.y) maxHappyNote = happyNotesTime[index];
          if (sadNotesTime[index].y > maxSadNote.y) maxSadNote = sadNotesTime[index];
          if (loveNotesTime[index].y > maxLoveNote.y) maxLoveNote = loveNotesTime[index];
          if (deterNotesTime[index].y > maxDeterNote.y) maxDeterNote = deterNotesTime[index];
          //Adição do emote importante
          if (importanteNotesTime[index].y > maxImportanteNote.y) maxImportanteNote = importanteNotesTime[index];

        });

        // console.log('maxPhotoNotes', maxPhotoNotes);

        notesTime.sort((a, b) => moment(b.time, 'HH:mm').unix() - moment(a.time, 'HH:mm').unix());

        textNotesTime.sort((a, b) => moment(b.x, 'HH:mm').unix() - moment(a.x, 'HH:mm').unix());
        photoNotesTime.sort((a, b) => moment(b.x, 'HH:mm').unix() - moment(a.x, 'HH:mm').unix());
        markNotesTime.sort((a, b) => moment(b.x, 'HH:mm').unix() - moment(a.x, 'HH:mm').unix());
        //emoteNotesTime.sort((a, b) => moment(b.x, 'HH:mm').unix() - moment(a.x, 'HH:mm').unix());
        happyNotesTime.sort((a, b) => moment(b.x, 'HH:mm').unix() - moment(a.x, 'HH:mm').unix());
        sadNotesTime.sort((a, b) => moment(b.x, 'HH:mm').unix() - moment(a.x, 'HH:mm').unix());
        loveNotesTime.sort((a, b) => moment(b.x, 'HH:mm').unix() - moment(a.x, 'HH:mm').unix());
        deterNotesTime.sort((a, b) => moment(b.x, 'HH:mm').unix() - moment(a.x, 'HH:mm').unix());
        zzzNotesTime.sort((a, b) => moment(b.x, 'HH:mm').unix() - moment(a.x, 'HH:mm').unix());
        //Adição do emote importante
        importanteNotesTime.sort((a, b) => moment(b.x, 'HH:mm').unix() - moment(a.x, 'HH:mm').unix());


        totalNotes = textNotes + photoNotes + markNotes + happyNotes + sadNotes + loveNotes + deterNotes + zzzNotes + importanteNotes;
        //Adição do emote importante

        let analytics = {
          photoNotes,
          textNotes,
          markNotes,
          //emoteNotes,
          happyNotes,
          sadNotes,
          loveNotes,
          deterNotes,
          zzzNotes,
          importanteNotes,

          totalNotes,
          notesTime,
          totals,
          totalToPdf,
          textNotesTime,
          photoNotesTime,
          //emoteNotesTime,
          happyNotesTime,
          sadNotesTime,
          loveNotesTime,
          deterNotesTime,
          zzzNotesTime,
          importanteNotesTime,

          maxTextNote,
          maxPhotoNote,
          maxMarkNote,
          //maxEmoteNote,
          maxDeterNote,
          maxHappyNote,
          maxLoveNote,
          maxSadNote,
          maxZzzNote,
          maxImportanteNote,

          markNotesTime
        };
        console.log(analytics)

        dispatch({
          type: LOADING,
          payload: false
        })

        dispatch({
          type: LOAD_ANALYTICS,
          payload: analytics
        })
      })
  }
}

const calculeMS = (time) => {
  //RECEBE UMA STRING DE HORA, EX 08:30:32, E RETORNA ESSA HORA EM MILISEGUNDOS
  let hours;
  let minutes;
  let seconds;
  let milliseconds;

  if (time.length > 6) {
    //maior que uma hora
    hours = time.substring(0, 2);
    minutes = time.substring(3, 5);
    seconds = time.substring(6, 8);

    milliseconds = (hours * 60 * 60 * 1000) + (minutes * 60 * 1000) + (seconds * 1000);

  } else {
    // menor que uma hora
    minutes = time.substring(0, 2);
    seconds = time.substring(3, 5);

    milliseconds = (minutes * 60 * 1000) + (seconds * 1000);
  }

  return milliseconds;
}


