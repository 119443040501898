import axios from 'axios'
import { firebaseStorage, firebaseDatabase } from '../../config/FirebaseUtils';
import firebase from 'firebase/app';
import 'firebase/database';
import moment from 'moment';

const WORDS_PER_TRANSCRIPT_BLOCK = 50; // Used to map Operation response into transcript file and to separate transcript into blocks of words
const TEMP_BUCKET_NAME = 'uano-arquivos-temp';
const SPEECH_TO_TEXT_API_KEY = 'AIzaSyCa8IJA7wXBOgiO0Wws-E3sYKFQHWdIPmg'; // Access Speech-to-Text API and reference temp bucket files, all credentials list: https://console.cloud.google.com/apis/credentials

// Possible states of the ongoing audio transcript generation
const TRANSCRIPTION_STATUS = { 
    PENDING: 'pending',                                     // Set by client when requesting transcript by creating Realtime Database node thus triggering Cloud Function
  
    DOWNLOADING_SOURCE_AUDIO: 'downloading_source_audio',   // Set by function before downloading the audio from Firebase Storage default bucket
    CONVERTING_SOURCE_AUDIO: 'converting_source_audio',     // Set by function after downloading (file available locally for conversion), before converting the audio
    UPLOADING_CONVERTED_AUDIO: 'uploading_converted_audio', // Set by function before uploading converted audio to Cloud Storage bucket (accessible by Speech-to-Text API)
    CONVERTED_AUDIO_UPLOADED: 'uploaded_audio_converted',   // Set by function after upload of converting audio has been completed
    FUNCTION_ERROR: 'function_error',                       // Set by function so client can know when something went wrong during the execution of the Cloud Function, 
    
    AWAITING_OPERATION: 'awaiting_operation',               // Set by client after speech recogition Operation has been created
    UPLOADING_TRANSCRIPT: 'uploading_transcript',           // Set by client after transcript is available (Operation is done) and before uploading it to Firebase Storage
    TRANSCRIPT_UPLOADED: 'transcript_uploaded',             // Set by client after transcript upload has been completed
    CLIENT_ERROR: 'client_error'                            // Set by client when a error occurs during code execution / api calls / etc
}

const getTranscriptRef = (user, sectionIndex, audioIndex) => {
    return firebaseDatabase.ref(`users/${user}/sections/${sectionIndex}/audios/${audioIndex}/transcript`);
}

// Tries to predict if audio transcription generation will fail (because of Cloud Functions / Speech-to-Text API limits) based on audio metadata
// Cloud Function limits: https://cloud.google.com/functions/quotas#resource_limits
// Cloud Speech-to-Text API limits: https://cloud.google.com/speech-to-text/quotas#content
const validateAudioMetadataForTranscription = (audioMetadata) => {
    const momentDuration = moment(audioMetadata.duration, 'HH:mm:ss');
    const bytes = audioMetadata.sizeInBytes;
    if (momentDuration.isAfter(moment('06:00:00', 'HH:mm:ss'))) { // Max: 6h
        return `O áudio é muito longo (${momentDuration.format('HH:mm:ss')})`;
    } else if (bytes > 524288000) { // Max: 500MB
        return `O áudio é muito pesado (${Math.ceil((bytes / (1024 * 1024)) * 100) / 100}MB)`;
    }
   
    return false;
}

// Returns interval in ms based on audio duration, longer audios have bigger intervals
const getOperationRequestInterval = (audioMetadata) => {
    const momentDuration = moment(audioMetadata.duration, 'HH:mm:ss');
    if (momentDuration.isBefore(moment('00:01:00', 'HH:mm:ss'))) {
        return 3000;
    } else if (momentDuration.isBefore(moment('00:05:00', 'HH:mm:ss'))) {
        return 7000;
    } else if (momentDuration.isBefore(moment('00:30:00', 'HH:mm:ss'))) {
        return 15000;
    } else if (momentDuration.isBefore(moment('01:00:00', 'HH:mm:ss'))) {
        return 20000;
    } else if (momentDuration.isBefore(moment('02:00:00', 'HH:mm:ss'))) {
        return 35000;
    } else {
        return 50000;
    }
}

// Create transcript node that will trigger Cloud Function
const createNewTranscriptNode = async transcriptRef => {
    console.log('Creating new transcript node at', getRefPath(transcriptRef));
    return transcriptRef.set({
        status: TRANSCRIPTION_STATUS.PENDING,
        startedAt: Date.now(),
        startedAtServer: firebase.database.ServerValue.TIMESTAMP
    });
}

// Marks transcript as generated, transcript file should be available at this point
const finishTranscriptNode = async transcriptRef => {
    console.log('Finishing transcript node at', getRefPath(transcriptRef));
    return transcriptRef.update({
        finishedAt: Date.now(),
        finishedAtServer: firebase.database.ServerValue.TIMESTAMP,
        status: TRANSCRIPTION_STATUS.TRANSCRIPT_UPLOADED 
    });
}

// Call Cloud Speech-to-Text API to start longrunning recognition Operation of speech recognition; 
// Provides URI to audio generated by uano-gfc-convert-audio Cloud Function,
// available in public Google Storage bucket (accessible by Cloud Speech-to-Text REST API)
// Cloud Function code: https://github.com/newgotecnologia/uano-gfc-convert-audio
// Google Cloud Console, Functions list: https://console.cloud.google.com/functions/list
// Endpoint doc: https://cloud.google.com/speech-to-text/docs/reference/rest/v1p1beta1/speech/longrunningrecognize
const startLongrunningRecognition = async (audioId) => {
    const audio = {
        uri: getAudioTempFileGSURI(audioId)
    };
    const config = {
        encoding: 'LINEAR16',
        languageCode: 'pt-BR',
        sampleRateHertz: 16000,
        enableWordTimeOffsets: true,
    };
    const body = { audio, config };
    console.log('Starting longrunning recognition with request body:', body);
    return axios.post(`https://speech.googleapis.com/v1p1beta1/speech:longrunningrecognize?key=${SPEECH_TO_TEXT_API_KEY}`, body);
}

const putTranscriptFile = async (user, audioId, transcriptData) => {
    const fileRef = firebaseStorage.ref(getTranscriptFileStoragePath(user, audioId));
    console.log('Put transcript file at', fileRef.fullPath, 'with data:', transcriptData);
    const blob = new Blob([JSON.stringify(transcriptData)], {type: "application/json"});
    return fileRef.put(blob);
}

const putOperationFile = async (user, audioId, operationId, operationData) => {
    const fileRef = firebaseStorage.ref(getOperationFileStoragePath(user, audioId, operationId));
    console.log('Put Operation file at', fileRef.fullPath, 'with data:', operationData);
    const blob = new Blob([JSON.stringify(operationData)], {type: "application/json"});
    return fileRef.put(blob);
}

// Gets the mapped transcript JSON file stored in Firebase Storage
const getTranscriptFile = async (user, audioId) => {
    const fileRef = firebaseStorage.ref(getTranscriptFileStoragePath(user, audioId));
    console.log('Get transcript file at', fileRef.fullPath);
    const downloadURL = await fileRef.getDownloadURL();
    return axios.get(downloadURL);
}

// Gets the Cloud Speech-to-Text API longrunning recognition Operation response backed up in Firebase Storage
const getOperationFile = async (user, audioId, operationId) => {
    const fileRef = firebaseStorage.ref(getOperationFileStoragePath(user, audioId, operationId));
    console.log('Get Operation file at', fileRef.fullPath);
    const downloadURL = fileRef.getDownloadURL();
    return axios.get(downloadURL);
}

// Get data from a Operation started by Cloud Speech-to-Text-API longrunning recognition method
// Endpoint doc: https://cloud.google.com/speech-to-text/docs/reference/rest/v1/operations/get#google.longrunning.Operations.GetOperation
const getOperation = async (operationId) => {
    return axios.get(`https://speech.googleapis.com/v1/operations/${operationId}?key=${SPEECH_TO_TEXT_API_KEY}`);
}

// Map the completed Cloud Speech-to-Text API longrunning recognition Operation response to a transcript JSON file
// Operation format: https://cloud.google.com/speech-to-text/docs/reference/rest/Shared.Types/ListOperationsResponse#Operation
const mapOperationToTranscriptFile = (operationData) => {
    let allResultsBlocks = [];
    // let fullTranscript = '';

    if (operationData.results) {
        operationData.results.forEach(r => {
            const alternative = r.alternatives[0];
            const blocks = alternative.words.reduce((accumulator, current, index, array) => {
                const time = moment('00:00:00', 'HH:mm:ss');
        
                // Start a new block of words
                if (index % WORDS_PER_TRANSCRIPT_BLOCK === 0) {
                    const startSecond = Number(current.startTime.replace('s', ''));
                    accumulator.push({
                        startTimeFormatted: time.add(Math.round(startSecond), 'seconds').format('HH:mm:ss'),
                        startTime: startSecond,
                    })
                }
        
                // Add current word to current block
                const accumulatedText = accumulator[accumulator.length - 1].text || '';
                accumulator[accumulator.length - 1].text = accumulatedText ? `${accumulatedText} ${current.word}` : current.word;
        
                // Finish current word block if there's no more words or WORDS_PER_TRANSCRIPT_BLOCK was reached
                if (index % WORDS_PER_TRANSCRIPT_BLOCK === WORDS_PER_TRANSCRIPT_BLOCK - 1 || index === array.length - 1) {
                    const endSecond = Number(current.endTime.replace('s', ''));
                    accumulator[accumulator.length - 1].endTime = endSecond;
                    accumulator[accumulator.length - 1].endTimeFormatted = time.add(Math.round(endSecond), 'seconds').format('HH:mm:ss');
                }
                return accumulator;
            }, []);
    
            allResultsBlocks.push(blocks);
            // fullTranscript = fullTranscript ? `${fullTranscript}\n${alternative.transcript}` : alternative.transcript;
        });
    } else {
        allResultsBlocks = [{
            startTime: 0,
            startTimeFormatted: '00:00:00',
            endTime: 1,
            endTimeFormatted: '00:00:01',
            text: '(Nenhuma fala foi reconhecida)'
        }];
    }

    return {
        generatedAt: Date.now(),
        lastEditedAt: null,
        // fullTranscript,
        operation: operationData.name,
        blocks: allResultsBlocks.flat() // Map subarrays blocks to same array
    }
}

const getFullTranscript = transcriptData => {
    return transcriptData.blocks.reduce((previous, current) => previous ? `${previous}\n${current.text}` : current.text, '');
}

// Get path to audio transcript JSON file relative to root of Firebase Storage bucket
const getTranscriptFileStoragePath = (user, audioId) => {
    return `audios/${user}/transcripts/${audioId}.json`;
}

// Get path to operation backup file relative to root of Firebase Storage bucket
const getOperationFileStoragePath = (user, audioId, operationId) => {
    return `audios/${user}/transcripts/operation_backups/${audioId}_${operationId}.json`;
}

// Get full URI ('gs://' scheme) of audio temp file
const getAudioTempFileGSURI = (audioId) => {
    return `gs://${TEMP_BUCKET_NAME}/${getAudioTempFileStoragePath(audioId)}`;
}

// Get path to audio temp file relative to root of Google Storage bucket
const getAudioTempFileStoragePath = (audioId) => {
    return `temp/transcript_audios/${audioId}.pcm`;
}

const getRefPath = ref => {
    return ref.toString().substring(ref.root.toString().length - 1);
}

export { 
    TRANSCRIPTION_STATUS, WORDS_PER_TRANSCRIPT_BLOCK, 
    validateAudioMetadataForTranscription, createNewTranscriptNode, getOperation, getOperationFile, getTranscriptFile, getTranscriptRef, 
    startLongrunningRecognition, getOperationRequestInterval, putTranscriptFile, putOperationFile, finishTranscriptNode,
    mapOperationToTranscriptFile, getRefPath, getFullTranscript
};