import {
  SCREENS,
  USER
} from '../../../config/Types'

const INITIAL_STATE = {
  screen: '',
  user:{}
}


export default (state = INITIAL_STATE, action) => {
    switch(action.type){
      case SCREENS: return  { ...state, screen: action.payload };
      case USER: return  { ...state, user: action.payload };
      default: return state;
  }
}