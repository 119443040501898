import React,{Component} from 'react';

import moment from 'moment'
import {connect} from 'react-redux'
import {delNote, editNote} from '../../libs/redux/actions/SectionActions'
import {TransparentButton, CircleButton} from '../../components/Button'

 class EditNotes extends Component{
    constructor(props){
        super(props)
        this.state = {
            time:'00:00:00',
            duration:'00:00:00',
            firstTime:'00:00:00',
            firstDuration:'00:00:00',
            circleIndex:0
        }
    }


    componentDidMount(){
        setTimeout(()=>{
            const {section, note} = this.props
            const currentTime = moment(note.registerTime,'HH:mm:ss').diff(moment("00:00:00","HH:mm:ss"),'seconds')

            //const currentTime = note.registerTime
            const time = moment(section.startAt, 'DD/MM/YYYY HH:mm:ss').add('seconds',currentTime).format('HH:mm:ss')
            const duration = moment('00:00:00', 'HH:mm:ss').add('seconds',currentTime).format('HH:mm:ss')
            this.setState({
                firstTime:time,
                time:time,
                firstDuration:duration,
                duration:duration
            })
        },500)
    }

    addTime(seconds){
        let {time, duration, firstTime, firstDuration} = this.state;
        let newTime = moment(time,'HH:mm:ss').add('seconds',seconds).format('HH:mm:ss')
        let newDuration = moment(duration,'HH:mm:ss').add('seconds',seconds).format('HH:mm:ss')

        if(seconds === null){
            this.setState({
                time:firstTime,
                duration: firstDuration
            })
        }else{
            if(moment(newDuration,'HH:mm:ss').diff(moment('00:00:00', 'HH:mm:ss'),'hours') < 12){
                this.setState({
                    time:newTime,
                    duration:newDuration
                })
            }else{                
                    this.setState({
                        time:firstTime,
                        duration: '00:00:00'
                    })
            }
        }
    }

    EditNote = (props) => {
        console.log(props)
        return(
            <div style={{flex:1, display:'flex', alignItems:'center'}}>
                <div style={styles.syncContainer}>
                    <p style={{...styles.textTime, color:'#FF6600'}}>
                        Sincronizar
                    </p>
                    <p style={{...styles.textTime, color:'#888', fontSize:14}}>{props.time}</p>
                    <div style={{display:'flex', alignItems:'center'}}>
                        <TransparentButton 
                            style={styles.roundButton} 
                            icon={{name:'uano-avancar',style:{position:'absolute', transform:"scaleX(-1)",color:"#FF6600",fontSize:50, margin:'2px 0 0 0'}}}
                            label={{text:-10,style:{...styles.secondsLabel, color:"#FF6600"}}}
                            onClick={()=>{this.addTime(-10)}}
                        />
                        <TransparentButton 
                            style={styles.roundButton} 
                            icon={{name:'uano-avancar',style:{position:'absolute', transform:"scaleX(-1)",color:"#FF6600",fontSize:50, margin:'2px 0 0 0'}}}
                            label={{text:-5,style:{...styles.secondsLabel, color:"#FF6600"}}}
                            onClick={()=>{this.addTime(-5)}}
                        />
                        <p style={{...styles.textTime, color:'#FF6600', fontSize:14, margin:20}}>{props.duration}</p>
                        <TransparentButton 
                            style={styles.roundButton} 
                            icon={{name:'uano-avancar',style:{position:'absolute',color:"#FF6600",fontSize:50, margin:'2px 0 0 3px'}}}
                            label={{text:'+5',style:{...styles.secondsLabel, color:"#FF6600"}}}
                            onClick={()=>{this.addTime(5)}}
                        />
                        <TransparentButton 
                            style={styles.roundButton} 
                            icon={{name:'uano-avancar',style:{position:'absolute',color:"#FF6600",fontSize:50, margin:'2px 0 0 3px'}}}
                            label={{text:'+10',style:{...styles.secondsLabel, color:"#FF6600"}}}
                            onClick={()=>{this.addTime(10)}}
                        />
                    </div>
                </div>
            </div>
        )
    }

    render(){
        const {time, duration} =  this.state;
        const {user} = this.props
        return(
            <div style={{display:'flex', flex:1, alignItems:'center', justifyContent:'center', flexDirection:'column'}}>
                <this.EditNote {...{time, duration}} />
                <div style={{display:'flex', width:'90%', height:100, alignItems:'center'}}>
                    <TransparentButton
                        icon={{name:'excluir',style:{fontSize:35, color:'#FF6600'}}}
                        onClick={()=>{
                            this.props.delNote(user.key,this.props.note,this.props.section)
                            let newSection = this.props.section
                            newSection.notes = this.props.section.notes.filter(item => item.id !== this.props.note.id)
                            this.props.close()
                        }}
                    />
                    <p style={{margin:'0 20px 0 20px', textAlign:'center'}}>Toque quantas vezes precisar para adiantar ou retroceder na timeline.
                        <span style={{color:'#FF6600'}}> A nova posição estará no contador laranja. </span>
                    </p>
                    <CircleButton 
                    style={styles.roundButton}
                    label={{text:'OK',style:{fontWeight:'bold',color:'white',fontSize:12,marginBottom:0}}}
                    onClick={()=>{
                        const {section, close, imagePath,note} = this.props;

                        note.registerHour = time;
                        note.registerTime = duration;
                        note.photoURL = imagePath?imagePath:''

                        editNote(user.key,note, section)
                        close();
                        }}/>
                </div>
            </div>
        )
    }
}

const styles = {
    syncContainer:{
        display:'flex',
        flex:1,
        justifyContent:'center',
        flexDirection:'column',
        textAlign:"center", 
        padding:10
    },
    buttonsContainer:{
        display:'flex',
        flex:1,
        flexDirection:'row',
        justifyContent:'space-between',
        padding:20,
    },
    submitContainer:{
        display:'flex',
        flex:0.6,
        flexDirection:'row',
        justifyContent:'flex-end',
        padding:10,
        paddingRight:20,
    },
    textTime:{
        fontSize:20,
        margin:0
    },
    roundButton:{
        minWidth:40, 
        height:40, 
        borderRadius:100, 
        backgroundColor:'#FF6600', 
        display:'flex', 
        flexDirection:'column',
        justifyContent:'center', 
        alignItems:'center',
        margin:10
    },
    secondsLabel:{
        color:'white',
        fontSize:14,
        marginBottom:-5
    }
}

const mapStateToProps = state => ({
    pastas : state.SectionReducers.pastas,
    user: state.UserReducer.user,
  });
  
  const mapDispatchToProps = {
    editNote,
    delNote,
  };
  
export default connect(mapStateToProps, mapDispatchToProps)(EditNotes);