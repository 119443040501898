import React, { Component} from 'react';
import { connect } from 'react-redux';
import Menu from '../Menu/Menu';
import { TransparentButton } from '../../components/Button';
import { selectEnqueteEvent } from '../../libs/redux/actions/EnquetesActions';
import { Link } from 'react-router-dom';
import Icon from '../../components/Icon'
import BackContainer from '../../components/BackContainer'

class EventosVinculados extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    componentDidMount() {
    }

    render() {
        return (
            <div style={styles.mainContainer} >
                <div style={{ marginLeft: 10, flex:1}}>
                    <EventList {...this.props} />
                </div>
            </div>
        )
    }
}

const EventList = (props) => {
    const events = props.events;
    const { selectEnqueteEvent } = props
    console.log('renderizando')
    if (!events) {
        return <p>Esta enquete não possui eventos vinculados.</p>
    } else {
        return (
            <div style={styles.eventListContainer}>
                {Object.values(events)
                    .map(event =>
                        <Link to={"/visualizar_enquete"} style={{ textDecoration: 'none', display: 'flex' }}>
                            <TransparentButton
                                style={{ borderBottom: '1px solid #eee', textAlign: 'left', flex: 1}}
                                onClick={() => { selectEnqueteEvent(event) }}>
                                <p style={{ fontSize: 16, margin: '5px 0px 5px 10px', fontWeight: 'bold', color: '#555' }}>{event.name}</p>
                                <p style={{ fontSize: 11, margin: '5px 0px 5px 10px' }}>{event.date}</p>
                            </TransparentButton>
                        </Link>
                    )}
            </div>
        )
    }
}

const styles = {
    mainContainer: {
        display: 'flex',
        padding: 0,
        margin: 0,
        flex:1,
        //height: '100vh',
    },
    menuContainer: {
        flex: 1,
        maxWidth: 300,
        minWidth: 250
    },
    eventContainer: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#e5e5e0'
    },
    sideContainer: {
        backgroundColor: '#ddd',
        maxWidth: 400,
        minWidth: 220,
        marginLeft: -3,
        zIndex: 1,
        borderLeft: '1px solid #eee',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: 20
    },
    icon: {
        fontSize: 20,
        marginRight: 5,
        color: '#ff6600'
    },
    enqueteTitle: {
        fontSize: 15,
        fontWeight: 'bolder',
        color: '#555'
    },
    enqueteDescricao: {
        fontSize: 12,
        fontWeight: 'bolder',
        color: '#555'
    },
    title: {
        color: '#333',
        margin: '15px 15px 0px 15px',
        borderBottom: '1px solid #eee'
    },
    eventListContainer: {
        flex: 1,
        overflowX: 'auto',
        //height: '90vh',
        display: 'flex',
        flexDirection: 'column'
    },

}

const mapStateToProps = state => ({
    user: state.UserReducer.user,
    enquetes: state.EnquetesReducer.enquetes,
    //selectedEnquete: state.EnquetesReducer.selectedEnquete
});

const mapDispatchToProps = {
    selectEnqueteEvent,
};

export default connect(mapStateToProps, mapDispatchToProps)(EventosVinculados);