/* eslint-disable default-case */
import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Row, Col, Spinner } from 'react-bootstrap';
import moment from 'moment';
import fileDownload from 'js-file-download'

import Menu from '../Menu/Menu'
import Player from '../Audio/Player'
import AddTextNotes from './AddTextNotes'
import AddMarkNotes from './AddMarkNotes'
import AddPhotoNotes from './AddPhotoNotes'
import getEvent from '../../libs/functions/getEvent'
import { sectionTimes } from '../../libs/functions/sectionTimes'
import Icon from '../../components/Icon'
import axios from 'axios'
import { firebaseStorage, getUserKey } from '../../config/FirebaseUtils'
import createFirebaseLink from '../../libs/functions/generateLink'
import { Link } from 'react-router-dom'
import { Button, TransparentButton } from '../../components/Button';
import {
    TRANSCRIPTION_STATUS, getTranscriptRef, createNewTranscriptNode, validateAudioMetadataForTranscription, startLongrunningRecognition,
    getOperation, getOperationRequestInterval, putOperationFile, putTranscriptFile, finishTranscriptNode,
    mapOperationToTranscriptFile, getTranscriptFile, getRefPath, getFullTranscript
} from '../../libs/functions/audioTranscript';
import TextArea from '../../components/TextArea';
import BackContainer from '../../components/BackContainer';

/*

valores noteModal:

1: AddTextNotes,
2: AddMarkNotes
3: AddMarkNotes,

outros: Desabilita o modal.
*/

class NotesList extends Component {
    constructor(props) {
        super(props);
        this.refs = []
        this.index = []
        this.i = 0

        this.transcriptRef = null;            // Realtime Database node reference to section audio transcript
        this.operationRequestInterval = null; // Holds interval to check Operation

        this.state = {
            section: {},
            notes: [],
            textNoteModal: false,
            noteModal: 0,
            scrolling: false,
            photoModal: false,
            photoURL: '',
            address: null,
            note: null,
            width: 0,
            link: '',
            editar: '',

            // Used to control and store Player related info
            currentTime: 0,         // Stores the current time, updated by Player event
            currentNote: { id: 0 }, // Stores data about current note or transcript block, set by scrollToNote
            noteTime: null,         // Given to Player to change time

            // Audio transcript
            transcriptMode: false,    // Toggle UI between rendering notes or transcript
            transcriptBusy: false,    // Used to disable buttons and other components while performing async logic
            transcriptStatusText: 'Transcrição do áudio', // Updated with transcript generation status
            transcriptFileData: null, // Transcript file data (not db node, which is available at this.state.section), contains text blocks
            currentTranscriptBlock: { index: 0 },
            transcriptEdit: {         // Transcript editing
                editing: false,       // Edit button was clicked at least once
                activeBlockIndex: null, // Index of the block is currently being edited (textarea is shown instead of text)
                blocks: null          // copy of transcriptFileData with user edits applied
            },
        }
    }

    updateDimensions() {
        if (window.innerWidth < 500) {
            this.setState({ width: 450 });
        } else {
            let update_width = window.innerWidth - 100;
            this.setState({ width: update_width });
        }
    }

    async componentDidMount() {
        this.updateDimensions();
        window.addEventListener("resize", this.updateDimensions.bind(this));

        const section = this.props.section
        console.info('Current section:', section);
        //getEvent(section.event_id)
        //.then(address => this.setState({address}))
        //.catch(err=>console.log(err))

        this.generateIndex(section)
    }

    componentWillUnmount() {
        clearInterval(this.operationRequestInterval) // In case interval is somehow not cleared properly (i.e live-reloading, leaving page, etc)
        if (this.transcriptRef) this.transcriptRef.off();
    }

    componentDidUpdate() {
        // console.info('ref:', this.refs, this.index)
    }

    generateIndex(section) {
        if (section.notes) {
            section.notes = section.notes.sort((a, b) => {
                const a_time = moment(a.registerTime, 'HH:mm:ss').format('X');
                const b_time = moment(b.registerTime, 'HH:mm:ss').format('X');
                return a_time - b_time
            })
        }

        this.index = []
        if (!section.notes) section.notes = []
        this.setState({ section: section, notes: section.notes })
        if (section.notes) {
            this.refs = section.notes.reduce((acc, value) => {
                acc[moment(value.registerTime, 'HH:mm:ss').diff(moment('00:00:00', 'HH:mm:ss'), 'seconds')] = React.createRef();
                return acc;
            }, {});

            section.notes.map((note) => {
                this.index.push({
                    time: moment(note.registerTime, 'HH:mm:ss').diff(moment('00:00:00', 'HH:mm:ss'), 'seconds'),
                    note
                })
            })
        }

        console.log('Generated indexes for note list.\nrefs:', this.refs, '\nindex:', this.index);
    }

    renderNotes(data) {
        if (data) {
            return data.map((note) => {
                return (
                    <div key={note.id} style={{ ...styles.row, backgroundColor: note.id === this.state.currentNote.id ? 'rgb(255, 239, 229)' : 'white' }}
                        ref={this.refs[moment(note.registerTime, 'HH:mm:ss').diff(moment('00:00:00', 'HH:mm:ss'), 'seconds')]}
                    >
                        <div style={styles.itemContainer} >
                            <div style={{ margin: 5, minWidth: 150, cursor: 'pointer' }}
                                onClick={() => {
                                    this.setState({
                                        noteTime: moment(note.registerTime, 'HH:mm:ss')
                                            .diff(moment('00:00:00', 'HH:mm:ss'), 'seconds')
                                    })
                                }}>
                                <div style={styles.barContainer}>
                                    <div style={styles.barTextConatiner}>
                                        <p style={styles.lateralText}>{note.registerHour}</p>
                                        <p style={styles.lateralText}>{note.registerTime}</p>
                                    </div>
                                    <div style={{ minWidth: '100%', display: 'flex', justifyContent: 'center', marginTop: -10 }}>
                                        <TransparentButton
                                            label={{ text: 'Editar', style: { fontSize: 12, margin: '2px 5px 2px 5px', color: '#aaa' } }}
                                            style={{ border: '1px solid #ddd', borderRadius: 5 }}
                                            onClick={() => {
                                                this.setState({ note, editar: true })
                                                switch (note.type) {
                                                    case "T": this.setState({ noteModal: 1 }); break;
                                                    case "P": this.setState({ noteModal: 2 }); break;
                                                    case "M": this.setState({ noteModal: 3 }); break;
                                                }
                                            }} />
                                    </div>
                                </div>
                                <div style={{ padding: 5, margin: 5, borderLeftWidth: 1, borderLeftColor: '#ddd', borderLeftStyle: 'solid' }}>
                                    {this.noteFilter(note)}
                                </div>
                            </div>
                        </div>
                    </div>
                );
            })
        } else {
            return <div />
        }
    }

    noteIcon(note) {
        switch (note.type) {
            case "M": return (
                <Icon name={"uano-bookmark"} style={{ fontSize: 23, color: '#888' }} />
            );
            case "P": return (
                <Icon name={"camera"} style={{ fontSize: 23, color: '#888' }} />
            );
            case "T": return (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <p style={{ fontSize: 14, fontWeight: 'bold', color: '#666', marginTop: 7 }}>ABC</p>
                </div>
            );
            case "happy": return (
                <Icon name={"uano-feliz"} style={{ fontSize: 23, color: '#888' }} />
            );
            case "sad": return (
                <Icon name={"uano-triste"} style={{ fontSize: 23, color: '#888' }} />
            );
            case "zzz": return (
                <Icon name={"uano-cansativo"} style={{ fontSize: 23, color: '#888' }} />
            );
            case "love": return (
                <Icon name={"uano-curti"} style={{ fontSize: 23, color: '#888' }} />
            );
            case "determinado": return (
                <Icon name={"uano-determinado"} style={{ fontSize: 23, color: '#888' }} />
            );
            case "importante": return (
                <Icon name={"uano-importante"} style={{ fontSize: 23, color: '#888' }} />
            );
        }
    }

    noteFilter(note) {
        switch (note.type) {
            case "M": return (
                <div style={{ display: 'flex' }}>
                    <Icon name={"uano-bookmark"} style={{ fontSize: 30, color: note.label_color }} />
                    <p>{note.title}</p>
                </div>
            );
            case "T": return (<p style={{ textAlign: 'justify' }}>{note.text}</p>);
            case "P":
                let imagePath = '';
                if (note.firebaseThumbURL) {
                    imagePath = note.firebaseThumbURL
                } else {
                    imagePath = note.firebasePhotoURL
                }
                return (<img onClick={() => {
                    this.setState({ photoURL: note.firebasePhotoURL, photoModal: true, })
                }} style={{ cursor: 'pointer', maxHeight: 200, maxWidth: 500 }} src={imagePath} />);
            case "happy": return (<Icon name={"uano-feliz"} style={{ fontSize: 30, color: '#FF6600' }} />);
            case "sad": return (<Icon name={"uano-triste"} style={{ fontSize: 30, color: '#FF6600' }} />);
            case "love": return (<Icon name={"uano-curti"} style={{ fontSize: 30, color: '#FF6600' }} />);
            case "determinado": return (<Icon name={"uano-determinado"} style={{ fontSize: 30, color: '#FF6600' }} />);
            case "zzz": return (<Icon name={"uano-cansativo"} style={{ fontSize: 30, color: '#FF6600' }} />);
            case "importante": return (<Icon name={"uano-importante"} style={{ fontSize: 30, color: '#FF6600' }} />);
        }
    }

    scrollToNote(time) {
        let i = (this.i - 1 < 0 ? 0 : this.i - 1)

        if (this.index.length > 0) {
            while (time > this.index[i].time) {
                i++
                this.i++
                if (i > this.index.length - 1) {
                    i = this.index.length - 1
                    this.i = this.index.length - 1
                    break
                }
            }

            while (time < this.index[i].time) {
                i--
                this.i--
                if (i < 0 || this.i < 0) {
                    i = 0
                    this.i = 0
                    break
                }
            }

            this.setState({ currentNote: { ...this.index[i].note, index: i, indexTime: time } })
            this.refs[Math.round(this.index[i].time)].current.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
            });
        }

    }

    noteModal() {
        const { noteModal, note, section, currentTime, editar } = this.state;
        const styles = { container: { position: 'absolute', width: '100%', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' } }
        const close = () => {
            this.setState({ noteModal: 0, note: null, editar: false })
            this.generateIndex(this.props.section)
        }

        let time = currentTime;

        if (this.index[this.state.currentNote.index]) {
            time = moment(this.index[this.state.currentNote.index].note.registerTime, "HH:mm:ss")
                .diff(moment("00:00:00", 'HH:mm:ss'), 'seconds')
        }

        switch (noteModal) {
            case 1: return (
                <div style={styles.container}>
                    <AddTextNotes
                        editar={editar}
                        note={note}
                        noteTime={time}
                        currentTime={currentTime}
                        section={section}
                        close={() => close()} />
                </div>);
            case 2: return (
                <div style={styles.container}>
                    <AddPhotoNotes
                        editar={editar}
                        note={note}
                        currentTime={time}
                        section={section}
                        close={() => close()} />
                </div>);
            case 3: return (
                <div style={styles.container}>
                    <AddMarkNotes
                        editar={editar}
                        note={note}
                        currentTime={time}
                        section={section}
                        close={() => close()} />
                </div>
            );
            default: return null;
        }
    }

    photoModal() {
        if (this.state.photoModal === true) {
            return (
                <div onClick={() => { this.setState({ photoModal: false }) }} style={{ position: 'absolute', backgroundColor: 'rgba(100,100,100,0.5)', width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <img onClick={() => { this.setState({ photoModal: false }) }} src={this.state.photoURL} style={{ maxWidth: 1024, maxHeight: 720, borderRadius: 5 }} />
                </div>
            )
        }
    }

    // Toggles between note list and transcript
    toggleTranscriptMode() {
        if (this.state.transcriptBusy) return;

        // Want to enable transcriptMode but no transcript is loaded
        if (!this.state.transcriptMode && !this.state.transcriptFileData) {
            console.log('Trying to enable transcript mode but no transcript found')
            this.listenToTranscriptGeneration();
            return;
        }

        if (!this.state.transcriptMode) {
            this.generateTranscriptIndexes(this.state.transcriptFileData.blocks);
        } else {
            this.generateIndex(this.state.section);
        }

        // Toggle between note list and transcript and reset transcript edit data
        this.setState({
            transcriptMode: !this.state.transcriptMode,
            transcriptEdit: { editing: false, activeBlockIndex: null, blocks: null },
        }, () => console.info('Transcript mode:', this.state.transcriptMode));
    }

    // Subscribes to transcript status Realtime Database node and handles the value event until the transcript is generated and uploaded
    listenToTranscriptGeneration() {
        console.log('listenTranscriptGeneration');
        if (this.state.transcriptBusy) return;

        // TODO: refactor to handle sections with multiple audios (corner case), probably better to refactor Cloud Function to append audios
        const defaultAudioIndex = 0;

        // Validate audio metadata
        const audioMetadata = this.state.section.audios[defaultAudioIndex];
        const error = validateAudioMetadataForTranscription(audioMetadata);
        if (error) {
            this.setState({ transcriptStatusText: error });
            return;
        }

        // Reset state and other variables
        this.setState({
            transcriptBusy: true,
            transcriptStatusText: 'Buscando transcrição...'
        });
        clearInterval(this.operationRequestInterval);

        const sectionIndex = this.state.section._index;
        let operationData = null;

        // Create Realtime Database reference to transcript node
        if (this.transcriptRef) this.transcriptRef.off();
        this.transcriptRef = getTranscriptRef(getUserKey(), sectionIndex, defaultAudioIndex);

        // Start listener, handling current and future status
        console.info(`Starting transcript listener at ${getRefPath(this.transcriptRef)}`);
        this.transcriptRef.on('value', async snapshot => {
            const transcript = snapshot.val();
            const status = transcript ? transcript.status : null;
            console.log('Transcript snapshot val:', transcript);

            const operationName = transcript ? transcript.operationName : null; // Used in AWAITING_OPERATION and UPLOADING_TRANSCRIPT

            try {
                switch (status) {
                    case null: // No transcript yet
                        this.setState({ transcriptStatusText: 'Registrando transcrição...' });
                        createNewTranscriptNode(this.transcriptRef);
                        break;

                    // Statuses set by Cloud Function, waiting
                    case TRANSCRIPTION_STATUS.PENDING:
                        this.setState({ transcriptStatusText: 'Detectando registro...' });
                        break;
                    case TRANSCRIPTION_STATUS.DOWNLOADING_SOURCE_AUDIO:
                        this.setState({ transcriptStatusText: 'Recuperando áudio...' });
                        break;
                    case TRANSCRIPTION_STATUS.CONVERTING_SOURCE_AUDIO:
                        this.setState({ transcriptStatusText: 'Convertendo áudio...' });
                        break;
                    case TRANSCRIPTION_STATUS.UPLOADING_CONVERTED_AUDIO:
                        this.setState({ transcriptStatusText: 'Enviando áudio...' });
                        break;

                    case TRANSCRIPTION_STATUS.CONVERTED_AUDIO_UPLOADED:
                        this.setState({ transcriptStatusText: 'Iniciando reconhecimento de voz...' });
                        startLongrunningRecognition(audioMetadata.id)
                            .then(res => {
                                this.transcriptRef.update({
                                    status: TRANSCRIPTION_STATUS.AWAITING_OPERATION,
                                    operationName: res.data.name // Save operation name needed to check operation
                                });
                            })
                            .catch(err => {
                                console.error('startLongrunningRecognition error:', err);
                                this.transcriptRef.update({ status: TRANSCRIPTION_STATUS.CLIENT_ERROR });
                            });
                        break;
                    case TRANSCRIPTION_STATUS.AWAITING_OPERATION:
                        this.setState({ transcriptStatusText: 'Reconhecendo voz...' });

                        const timeout = getOperationRequestInterval(audioMetadata);

                        console.log(`Checking operation ${operationName} every ${timeout / 1000}s`);
                        this.operationRequestInterval = setInterval(() => {
                            getOperation(operationName)
                                .then(res => {
                                    console.log('Check Operation:', res.data);
                                    if (res.data.done) {
                                        console.log('Operation done! Response:', res.data.response);
                                        operationData = res.data;
                                        this.setState({ transcriptFileData: mapOperationToTranscriptFile(res.data.response) });
                                        this.transcriptRef.update({ status: TRANSCRIPTION_STATUS.UPLOADING_TRANSCRIPT });
                                        clearInterval(this.operationRequestInterval);
                                    }
                                })
                                .catch(err => {
                                    console.error('Error inside operation request interval:', err);
                                    this.transcriptRef.update({ status: TRANSCRIPTION_STATUS.CLIENT_ERROR });
                                    clearInterval(this.operationRequestInterval);
                                });
                        }, timeout);
                        break;
                    case TRANSCRIPTION_STATUS.UPLOADING_TRANSCRIPT:
                        // 'Move back' one status if operationData is not available (i.e restarted the page)
                        if (!operationData) {
                            this.transcriptRef.update({ status: TRANSCRIPTION_STATUS.AWAITING_OPERATION });
                            break;
                        }

                        this.setState({ transcriptStatusText: 'Salvando transcrição...' });

                        // Upload Operation and transcript file to Firebase Storage
                        Promise.all([
                            putOperationFile(getUserKey(), audioMetadata.id, operationName, operationData),
                            putTranscriptFile(getUserKey(), audioMetadata.id, this.state.transcriptFileData)
                        ])
                            .then(values => {
                                finishTranscriptNode(this.transcriptRef);
                            })
                            .catch(err => {
                                console.error('put files error:', err);
                                this.transcriptRef.update({ status: TRANSCRIPTION_STATUS.CLIENT_ERROR });
                            });
                        break;
                    case TRANSCRIPTION_STATUS.TRANSCRIPT_UPLOADED:
                        this.transcriptRef.off(); // Generation is done

                        // Download parse and save transcript file in state if not already available
                        let transcriptFileData = this.state.transcriptFileData;
                        if (!transcriptFileData) {
                            console.log('Transcript file data not available locally, downloading from Firebase Storage');
                            transcriptFileData = await getTranscriptFile(getUserKey(), audioMetadata.id).then(res => res.data);
                        }
                        console.log('Transcript file data:', transcriptFileData);

                        this.generateTranscriptIndexes(transcriptFileData.blocks);

                        this.setState({
                            transcriptFileData: transcriptFileData,

                            transcriptStatusText: 'Transcrição do áudio',
                            transcriptBusy: false,
                            transcriptMode: true,

                            transcriptEdit: { editing: false, activeBlockIndex: null, blocks: null },
                        }, () => console.info('Transcript downloaded from Storage, transcript mode:', this.state.transcriptMode));
                        break;
                    case TRANSCRIPTION_STATUS.CLIENT_ERROR:
                        throw `Client-side error`;
                    case TRANSCRIPTION_STATUS.FUNCTION_ERROR:
                        throw `Cloud Function error, check logs at https://console.cloud.google.com/logs/viewer?project=uano-app&interval=PT1H&resource=cloud_function`;
                }
            } catch (err) {
                console.error('Error handling transcript snapshot:', err);
                clearInterval(this.operationRequestInterval);
                this.transcriptRef.off();
                await this.transcriptRef.remove(); // Delete node to restart transcript generation
                this.setState({
                    transcriptStatusText: 'Ocorreu um erro, clique para gerar a transcrição novamente',
                    transcriptBusy: false
                });
            }
        });
    }

    // Prompts download to .txt file containing transcript text
    downloadTranscriptTxt() {
        const fileName = `Transcrição_${this.state.section.name.replace(/[^\w\d]/g, '_').substr(0, 30)}.txt`;
        console.log('Downloading text file', fileName);
        fileDownload(new Blob([getFullTranscript(this.state.transcriptFileData)]), fileName, 'text/plain');
    }

    // Calls 'app' Cloud Function to generate section PDF then downloads and opens it
    async downloadSectionPDF() {
        document.querySelector('#gerando-pdf').animate(
            [{ transform: 'translateY(-10vh)' }, { transform: 'translateY(15vh)' }],
            { fill: 'forwards', duration: 500 }
        );
        document.querySelector('#gerando-pdf').animate(
            [{ transform: 'translateY(15vh)' }, { transform: 'translateY(-10vh)' }],
            { fill: 'forwards', delay: 3000, duration: 500 }
        );

        let body;

        if (this.state.transcriptMode) {
            // Maps transcript blocks as text notes so the same PDF generation endpoint can be used
            const transcriptAsNoteList = this.state.transcriptFileData.blocks.map(b => {
                return {
                    registerHour: b.startTimeFormatted,
                    text: b.text,
                    type: 'T'
                }
            })
            body = {
                section: {
                    ...this.state.section,
                    notes: transcriptAsNoteList
                },
                notes: transcriptAsNoteList
            };
        } else {
            body = {
                section: this.state.section,
                notes: this.state.section.notes
            };
        }

        console.log('Generating pdf file with request body:', body);

        return axios
            .post('https://us-central1-uano-app.cloudfunctions.net/app/pdf/create/', body)
            .then(path => firebaseStorage.ref(path.data.filepath).getDownloadURL())
            .then(url => { window.open(url, 'pdf').focus() })
            .catch(err => console.log('Error generating PDF:', err));
    }

    // Toggles edit mode in a transcript block, toggles off others
    toggleTranscriptBlockEditing(blockIndex) {
        // When it's the first time entering edit mode after entering transcript mode, copy the blocks array from transcriptFileData
        let blocks = this.state.transcriptEdit.blocks || this.state.transcriptFileData.blocks;

        // Marks transcript as edited (changes made, can be saved), toggles activeBlockIndex and set new blocks
        this.setState({
            transcriptEdit: {
                editing: true,
                activeBlockIndex: this.state.transcriptEdit.activeBlockIndex === blockIndex ? null : blockIndex,
                blocks
            }
        }, () => console.log(`Toggled transcript block editing, activeBlockIndex:`, this.state.transcriptEdit.activeBlockIndex));
    }

    // Save transcriptEditData in Firebase Storage and then update transcriptFileData
    async saveTranscriptEdit() {
        const defaultAudioIndex = 0;
        const transcriptData = this.state.transcriptFileData;
        transcriptData.blocks = this.state.transcriptEdit.blocks;
        transcriptData.lastEditedAt = Date.now();

        this.setState({ transcriptBusy: true })
        await putTranscriptFile(getUserKey(), this.state.section.audios[defaultAudioIndex].id, transcriptData)
            .then(() => {
                console.log('Transcript edit saved');

                // Reset editing and save edited blocks to transcriptFileData
                this.setState({
                    transcriptFileData: transcriptData,
                    transcriptEdit: { editing: false, activeBlockIndex: null, blocks: null }
                })
            })
            .catch(err => {
                console.error('Error saving transcript edit:', err);
            })
        this.setState({ transcriptBusy: false })
    }

    // Render transcript blocks (with timestamps, text, etc)
    renderTranscript(transcriptFileData) {
        // return (<pre>{JSON.stringify(transcriptFileData, null, '   ')}</pre>); // debug
        if (transcriptFileData) {
            // Chooses transcript blocks source based on if it's editing or not
            const transcriptBlocks = this.state.transcriptEdit.editing ? this.state.transcriptEdit.blocks : transcriptFileData.blocks;
            return (<>
                <div className="container" style={{ margin: 0, textAlign: 'left', backgroundColor: 'rgba(0,0,0,0.0)' }}>
                    <h4 style={{ margin: '40px 20px 20px', }}>
                        {`Transcrição do áudio da sessão: `}
                        <span style={{ color: 'rgb(255, 102, 0)' }}>{`"${this.state.section.name}"`}</span>
                    </h4>
                </div>
                { transcriptBlocks.map((block, index) => {
                    const isActive = index === this.state.transcriptEdit.activeBlockIndex;
                    return (
                        <div key={index} style={{ ...styles.row, backgroundColor: index === this.state.currentNote.index ? 'rgb(255, 239, 229)' : 'white' }}
                            ref={this.refs[moment(block.startTimeFormatted, 'HH:mm:ss').diff(moment('00:00:00', 'HH:mm:ss'), 'seconds')]}
                        >
                            <div style={styles.itemContainer} >
                                <div style={{ margin: 5, minWidth: 150, cursor: 'pointer' }}
                                    onClick={() => {
                                        this.setState({
                                            noteTime: moment(block.startTimeFormatted, 'HH:mm:ss').diff(moment('00:00:00', 'HH:mm:ss'), 'seconds')
                                        });
                                    }}
                                >
                                    <div style={{ ...styles.barContainer, textAlign: 'center' }}>
                                        <div style={{ margin: 0, flex: 1 }}>
                                            <p style={{ ...styles.lateralText, textAlign: 'center' }}>{block.startTimeFormatted}</p>
                                            <p style={{ ...styles.lateralText, textAlign: 'center' }}>{block.endTimeFormatted}</p>
                                        </div>
                                    </div>
                                    <div style={{ minWidth: '100%', display: 'flex', justifyContent: 'center', marginTop: -10 }}>
                                        <TransparentButton
                                            label={{
                                                text: isActive ? 'Aplicar' : 'Editar',
                                                style: { fontSize: 12, margin: '2px 5px 2px 5px', color: isActive ? 'rgb(255, 102, 0)' : '#aaa' }
                                            }}
                                            style={{ border: `1px solid ${isActive ? 'rgb(255, 102, 0)' : '#ddd'}`, borderRadius: 5 }}
                                            onClick={() => {
                                                this.toggleTranscriptBlockEditing(index)
                                            }}
                                        />
                                    </div>
                                </div>
                                <div style={{ padding: '0 15px', margin: 5, width: '100%', borderLeftWidth: 1, borderLeftColor: '#ddd', borderLeftStyle: 'solid' }}>
                                    {
                                        isActive ? (
                                            <TextArea style={styles.blockTextArea} defaultValue={block.text} maxLength={4000}
                                                onTextChange={text => {
                                                    // Update block which changed text
                                                    let blocks = this.state.transcriptEdit.blocks;
                                                    blocks[index].text = text;
                                                    this.setState({ transcriptEdit: { ...this.state.transcriptEdit, blocks } })
                                                }}
                                            />
                                        ) : (<span>{block.text}</span>)
                                    }

                                </div>
                            </div>
                        </div>
                    );
                })}
            </>);
        } else {
            return null;
        }
    }

    // Return timestamps of each transcript block
    generateTranscriptIndexes(blocks = []) {
        if (blocks) {
            blocks = blocks.sort((a, b) => {
                const a_time = moment(a.startTimeFormatted, 'HH:mm:ss').format('X');
                const b_time = moment(b.startTimeFormatted, 'HH:mm:ss').format('X');
                return a_time - b_time;
            })
        }

        this.refs = blocks.reduce((acc, value) => {
            acc[moment(value.startTimeFormatted, 'HH:mm:ss').diff(moment('00:00:00', 'HH:mm:ss'), 'seconds')] = React.createRef();
            return acc;
        }, {});


        this.index = []
        blocks.forEach(b => {
            this.index.push({
                time: moment(b.startTimeFormatted, 'HH:mm:ss').diff(moment('00:00:00', 'HH:mm:ss'), 'seconds'),
            });
        })

        console.log('Generated indexes for transcript blocks.\nrefs:', this.refs, '\nindex:', this.index);
    }

    render() {
        const { notes, section, scrolling, transcriptMode, transcriptFileData } = this.state;
        const { day, date, initHour, initMinute, endHour, endMinute } = sectionTimes(section)
        const { address } = this.state;
        return (
            <div style={{ display: 'flex', alignItems: 'space-between', padding: 0, margin: 0, height: '100vh' }} onScroll={() => {
                if (scrolling === false) {
                    this.setState({ scrolling: true })
                    setTimeout(() => { this.setState({ scrolling: false }) }, 2000)
                }
            }}>
                <div style={{ flex: 1, maxWidth: 300, minWidth: 250 }}>
                    <Menu style={{ flex: 1 }} />
                </div>
                <div style={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' }}>
                    <BackContainer href={'/sections'} style={{ marginLeft: -10 }} />
                    <div style={styles.scroller}>
                        {
                            transcriptMode ? (
                                transcriptFileData ?
                                    this.renderTranscript(transcriptFileData) :
                                    (<p style={{ margin: 5 }}>Não foi possível encontrar a transcrição do áudio.</p>)
                            ) : (
                                    notes && notes.length > 0 ?
                                        this.renderNotes(notes) :
                                        (<p style={{ margin: 5 }}>Sem notas.</p>)
                                )
                        }
                    </div>
                    <div style={{ left: 35, right: 0, bottom: 0, zIndex: 2 }}>
                        <Player
                            hideExtraOptions={transcriptMode}
                            audios={section.audios}
                            time={this.state.noteTime}
                            index={this.index}
                            noteIndex={this.state.currentNote.index}
                            openNoteModal={(type) => {
                                switch (type) {
                                    case 'T': this.setState({ noteModal: 1 }); break;
                                    case 'P': this.setState({ noteModal: 2 }); break;
                                    case 'M': this.setState({ noteModal: 3 }); break;
                                }

                            }}
                            audioCurrentTime={(time) => {
                                if (!scrolling) {
                                    this.scrollToNote(time)
                                    this.setState({ currentTime: time })
                                }
                            }} />
                    </div>
                </div>
                <div style={{ padding: 0, marginLeft: -3, zIndex: 1, backgroundColor: 'white', display: 'flex', flex: 1, flexDirection: 'column', maxWidth: 300, minWidth: 260 }}>
                    <div style={{ flex: 1, padding: '30px 10px 10px 10px' }}>
                        <p style={{ fontSize: 14, fontWeight: 'bold', color: '#FF7700', margin: 0 }}>{day} - {date}</p>
                        <p style={{ fontSize: 12, color: '#FF7700', margin: 0 }}>{initHour}h{initMinute} a {endHour}h{endMinute}</p>
                        <p style={{ fontWeight: 'bold', fontSize: '14', margin: "5px 0 5px 0" }}>{section.name}</p>
                        <div style={{ display: 'flex', flexDirection: 'column', flex: 1, alignItems: 'flex-start' }}>
                            {
                                address ? (<>
                                    <p style={{ fontSize: 10, color: 'gray' }}>Local de gravação da sessão</p>
                                    <iframe frameborder="0" height='200' width='200' allowFullScreen
                                        src={`https://www.google.com/maps/embed/v1/place?q=${address}&key=AIzaSyD0HfewnEbHhpKl_lyIKJT_wNEzigYRV4g`}
                                    />
                                </>) : null
                            }
                            {/*<div style={{flex:1}}>

                            </div>
                            <div style={{flex:1}}>
                                <div style={{display:'flex',flex:1, height:40, backgroundColor:'#FF6600', alignItems:'center', padding:5}}>
                                    <p style={{color:'white', marginBottom:0}}>Sessão Compartilhada com:</p>
                                </div>
                            </div>*/}
                            {
                                this.state.transcriptMode ? (
                                    <TransparentButton onClick={this.downloadTranscriptTxt.bind(this)} disabled={this.state.transcriptBusy}
                                        icon={{ name: 'file-text2', style: { color: '#FF6600', fontSize: 30 } }}
                                        label={{ text: 'Salvar em TXT', style: { margin: '0 0 0 10px', fontSize: 12 } }}
                                        style={styles.rightSideBarButtons}
                                    />
                                ) : (
                                        <TransparentButton onClick={this.toggleTranscriptMode.bind(this)} disabled={this.state.transcriptBusy}
                                            icon={{ name: 'file-text2', style: { color: '#FF6600', fontSize: 30 } }}
                                            label={{ text: this.state.transcriptStatusText, style: { margin: '0 0 0 10px', fontSize: 12 } }}
                                            style={styles.rightSideBarButtons}
                                        />
                                    )
                            }
                            <TransparentButton onClick={this.downloadSectionPDF.bind(this)}
                                icon={{ name: 'file-pdf', style: { color: '#FF6600', fontSize: 30 } }}
                                label={{ text: 'Salvar em PDF', style: { margin: '0 0 0 10px', fontSize: 12 } }}
                                style={styles.rightSideBarButtons}
                            />
                            {
                                this.state.link === '' ?
                                    (<TransparentButton onClick={() => {
                                        createFirebaseLink(this.state.section).then((url) => this.setState({ link: url }))
                                    }}
                                        icon={{ name: 'share1', style: { color: '#FF6600', fontSize: 30 } }}
                                        label={{ text: 'Compartilhar', style: { margin: '0 0 0 10px', fontSize: 12 } }}
                                        style={styles.rightSideBarButtons} />)
                                    : <a href={this.state.link} style={{ fontSize: 10 }}>{this.state.link}</a>
                            }
                        </div>
                        <div style={{ marginTop: 20 }}>
                            {
                                transcriptMode ? (<>
                                    {   /* Appears when editing mode was entered at least once (transcriptEdit.blocks created) and is not currently editing any block (no activeBlockIndex) */
                                        this.state.transcriptEdit.activeBlockIndex === null && this.state.transcriptEdit.blocks ? (
                                            <Button onClick={this.saveTranscriptEdit.bind(this)} style={styles.roundBorderButton} disabled={this.state.transcriptBusy}>
                                                <b style={{ color: 'white' }}>{'Salvar'}</b>
                                            </Button>
                                        ) : null
                                    }
                                    <Button onClick={this.toggleTranscriptMode.bind(this)} style={{ ...styles.roundBorderButton, backgroundColor: 'rgb(153, 153, 153)' }}
                                        disabled={this.state.transcriptBusy}
                                    >
                                        <b style={{ color: 'white' }}>{'Sair'}</b>
                                    </Button>
                                </>) : null
                            }
                        </div>
                    </div>
                    <div style={{ flex: 1 }}>

                    </div>
                </div>
                {!transcriptMode ? this.noteModal() : null}
                {!transcriptMode ? this.photoModal() : null}
                <div
                    id='gerando-pdf'
                    style={{
                        position: 'absolute',
                        top: '-10vh',
                        left: '43vw',
                        backgroundColor: '#FF8800',
                        width: 200,
                        height: 40,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        borderRadius: 30,
                    }}>
                    <p style={{ margin: 0, color: 'white', fontWeight: 'bold' }}>Gerando PDF...</p>
                </div>
            </div>
        )
    }
}

const styles = {
    row: {
        //alignItems:'center',
        //width:'100%'
    },
    itemContainer: {
        display: "flex",
        //justifyContent:'center', 
        //alignItems:'center',
    },
    scroller: {
        flex: 1,
        display: "block",
        overflow: "auto",
        width: "100%",
        paddingLeft: 15,
        top: 0,
        bottom: 0,
    },
    barContainer: {
        display: "flex",
        flexDirection: 'row',
        fontSize: 14,
        padding: 25,
    },
    icons: {
        fontSize: 30,
        color: '#FF6600',
        paddingLeft: 10
    },
    miniIcons: {
        fontSize: 23,
        color: '#666',
    },
    lateralText: {
        fontSize: 11,
        marginBottom: 0,
        marginTop: 0
    },
    barIconContainer: {
        display: 'flex',
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center'
    },
    barTextConatiner: {
        marginRight: 10
    },
    backContainer: {
        position: 'absolute',
        zIndex: 3,
        backgroundColor: '#2E2D33',
        width: 35,
        height: "100%",
        marginLeft: -15,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        boxShadow: '5px 2px 10px rgba(0,0,0,0.2), 8px 2px 20px rgba(0,0,0,0.25)',
    },
    backButton: {
        cursor: 'pointer'
    },
    roundBorderButton: {
        display: 'flex',
        backgroundColor: '#FF6600',
        borderRadius: 100,
        height: 35,
        width: 150,
        padding: '0 10px 0 10px',
        marginTop: 10,
        marginBottom: 10,
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'row'
    },
    blockTextArea: {
        width: '100%',
        height: '100%',
        outline: 'none',
        resize: 'none',
        border: '1px solid #ddd',
        borderRadius: 10,
        padding: '0 10px'
    },
    rightSideBarButtons: {
        marginLeft: -10,
        marginTop: 20,
        display: 'flex',
        alignItems: 'center'
    }
}

const mapStateToProps = state => ({
    section: state.SectionReducers.section
});

const mapDispatchToProps = {
};

const notesList = connect(mapStateToProps, mapDispatchToProps)(NotesList)

export default notesList;