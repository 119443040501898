const searchSectionText = (sections, text) => {
    if(text){
        return sections.filter((data)=>{
            let name = data.name.toLowerCase().indexOf(text.toLowerCase()) !== -1;
            let start = data.startAt.toLowerCase().indexOf(text.toLowerCase()) !== -1;
            let event = data.eventName?data.eventName.toLowerCase().indexOf(text.toLowerCase()) !== -1:'';
            return (name || start || event);
        })
    }else{
        return sections;
    }
}
const searchFolderText = (folder, text) => {
    if(text){
        return folder.filter((data)=>{
            let name = data.name.toLowerCase().indexOf(text.toLowerCase()) !== -1;
            let createdAt = data.createdAt.toLowerCase().indexOf(text.toLowerCase()) !== -1;
            return (name || createdAt);
        })
    }else{
        return folder;
    }
}

export {searchSectionText, searchFolderText };