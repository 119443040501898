import React, { Component, useState } from 'react';
import { connect } from 'react-redux'
import Menu from '../Menu/Menu'
import { Button } from '../../components/Button'
import { selectEnquete, selectEnqueteEvent } from '../../libs/redux/actions/EnquetesActions'
import { Link } from 'react-router-dom'
import Icon from '../../components/Icon'
import EventosVinculados from './EventosVinculados';
import CriarEnquete from './CriarEnquete'
import { Accordion, Card, } from 'react-bootstrap';

class Enquetes extends Component {
    constructor(props) {
        super(props);
        this.state = {
            enqueteId: '',
            criarEnquete: false
        }
    }

    componentDidMount() {
    }

    render() {
        return (
            <div style={styles.mainContainer}>
                <div style={styles.menuContainer}>
                    <Menu />
                </div>
                <div style={styles.enqueteListContainer}>
                    <EnqueteList {...this.props} handleEnqueteId={(id) => { this.setState({ enqueteId: id }) }} selectedEnquete={this.state.enqueteId} />
                </div>
                <div style={styles.sideContainer}>
                    <Button style={{ display: 'flex' }} onClick={() => {
                        this.setState({criarEnquete:true})
                    }}>
                        <Icon name={"edit"} style={styles.icon} />
                        <p style={styles.enqueteTitle}>Nova Enquete</p>
                    </Button>
                </div>
                {
                    this.state.criarEnquete ?
                        (<div style={{ position: 'absolute', display: 'flex', height: '100vh', width: '100vw', justifyContent: 'center', alignItems: 'center' }}>
                                <CriarEnquete close={()=>{
                                    this.setState({criarEnquete:false})
                                }}/>
                        </div>):(<></>)
                }
            </div>
        )
    }
}

const EnqueteList = (props) => {
    const { enquetes, selectEnquete, handleEnqueteId } = props;
    const [selectedEnqueteIndex, selectEnqueteIndex] = useState(null)

    //const enquetes = {}
    const enquetesLength = Object.keys(enquetes).length;
    if (enquetesLength < 1) {
        return <p>Nenhuma enquete criada.</p>
    } else {
        return (
            <Accordion>
                {Object.values(enquetes)
                    .map((enquete, index) =>
                        <Card onClick={() => {
                            selectEnqueteIndex(index)
                            selectEnquete(enquete)
                        }}>
                            <Accordion.Toggle as={Card.Header} style={styles.card} eventKey={index}>
                                <p style={{ fontSize: 14, margin: 0 }}>{enquete.name}</p>
                                <p style={{ fontSize: 12, margin: 0 }}>{enquete.date}</p>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey={index} >
                                <Card.Body style={{ padding: 0 }}>
                                    {selectedEnqueteIndex === index ? <EventosVinculados events={enquete.historyEvents} /> : <></>}
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    )}
            </Accordion>
        )
    }
}

const styles = {
    mainContainer: {
        display: 'flex',
        padding: 0,
        margin: 0,
        height: '100vh'
    },
    menuContainer: {
        flex: 1,
        maxWidth: 300,
        minWidth: 250
    },
    enqueteListContainer: {
        flex: 1,
        height: '100vh',
        backgroundColor: 'white',
        overflow: 'auto',
        flexDirection: 'row'
    },
    link: {
        textDecoration: 'none'
    },
    enqueteButton: {
        display: 'flex',
        borderBottom: '1px solid #eee',
        textAlign: 'left',
        width: '100%'
    },
    sideContainer: {
        backgroundColor: 'white',
        maxWidth: 400,
        minWidth: 220,
        marginLeft: -3,
        zIndex: 1,
        borderLeft: '1px solid #eee',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: 20
    },
    icon: {
        fontSize: 20,
        marginRight: 5,
        color: '#ff6600'
    },
    card: {
        backgroundColor: '#fafafa'
    }
}


const mapStateToProps = state => ({
    user: state.UserReducer.user,
    enquetes: state.EnquetesReducer.enquetes,
    selectedEnquete: state.EnquetesReducer.selectedEnquete
});

const mapDispatchToProps = {
    selectEnquete,
};

export default connect(mapStateToProps, mapDispatchToProps)(Enquetes);