import React, { Component } from 'react'
import {TransparentButton,CircleButton} from '../../components/Button'
import Input from '../../components/Input'
import {connect} from 'react-redux'
import Icon from '../../components/Icon'
import {firebaseDatabase} from '../../config/FirebaseUtils'
import moment from 'moment'

class Leads extends Component {
    constructor(props){
        super(props);
        this.state = {
            leads:[]
        }
    }

    componentDidMount(){
        this.leads(this.props.event);
        this.setState({leads:JSON.parse(sessionStorage.getItem('leads'))})
    }

    leads = (event) =>{
        console.log("chamado",'firebase leads')

        firebaseDatabase
        .ref('users')
        .once('value')
        .then(snap=>{
            let users = snap.val()
            const leads = []
            Object.keys(users).map(key=>{
                if(users[key].sections){
                    users[key].sections.map(section=>{
                        if(section.event_id === event.id){
                            leads.push({
                                name:users[key].name,
                                email:users[key].email,
                                photo:users[key].photoURL,
                                palestrante:users[key].palestrante,
                            })
                        }
                    })
                }
            })
            this.setState({leads})
            sessionStorage.setItem('leads',JSON.stringify(leads))
        })
    }

    render(){
        let event = this.props.event;
        return(
            <div style={{backgroundColor:'rgba(130,130,130,0.5)',position:'absolute', justifyContent:'center', alignItems:'center', display:'flex',height:'100vh', width:'100vw'}}>
                <div style={{backgroundColor:'white', display:'flex', flexDirection:'column', height:600, width:550, borderRadius:20, padding:20}}>
                    <div style={{width:'100%',flex:0.3, display:'flex', justifyContent:'flex-end', alignItems:'flex-start'}}>
                        <TransparentButton onClick={()=>{this.props.close()}}>
                            <Icon name={'cancelar'} style={{fontSize:30, color:'#FF6600'}}/>
                        </TransparentButton>
                    </div>
                    <div style={{display:'flex', flexDirection:'column', flex:1, overflow:'auto'}}>
                        {
                            this.state.leads?(
                                this.state.leads.map(lead=>{
                                    return (
                                        <div style={{display:'flex', alignItems:'center',margin:5, borderBottom:'1px solid #ddd', padding:10}}>
                                            <img src={lead.photo} style={{width:60, height:60, marginRight:15, objectFit:"cover", borderRadius:100}}/>
                                            <div>
                                                <p style={{margin:0}}>{lead.name}</p>
                                                <p style={{margin:0}}>{lead.email}</p>
                                            </div>
                                        </div>
                                    )
                                })
                            ):(<div/>)
                        }
                    </div>
                    <div style={{flex:0.1, width:'100%'}}>
                        {/*
                            <div style={{width:'100%', height:50, display:'flex', justifyContent:'flex-end', alignItems:'flex-end'}}>
                            <CircleButton 
                            style={{backgroundColor:'#FF6600', height:35, width:35}}
                            label={{text:'OK',style:{fontWeight:'bold',color:'white',fontSize:12,marginBottom:0}}} 
                            onClick={()=>{}}/>
                            </div>
                        */}
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    event : state.EventReducers.event,
});

const mapDispatchToProps = {
};
  
export default connect(mapStateToProps, mapDispatchToProps)(Leads);