import React,{Component} from 'react';
import { connect } from 'react-redux'

import Menu from '../Menu/Menu'
import EventList from './EventList'
import EditEvent from './EditEvent'
import {CircleButton} from '../../components/Button'
import './Sections.css';

class Events extends Component {
    constructor(props){
        super(props);
        this.state = {
            createEvent:false
        }
    }

    componentDidMount(){
    }

    render(){
        return(
            <div style={{display:'flex', padding:0, margin:0, height:'100vh'}}>
                <div style={{flex:1, maxWidth:300, minWidth:250}}>
                    <Menu style={{flex:1}}/>
                </div>
                <div style={{flex:1, height:'100vh', paddingLeft:20, minWidth:300, overflow:'auto'}}>
                    <EventList/>   
                </div>
                <div  style={{backgroundColor:'white', maxWidth:400, minWidth:220, marginLeft:-3, zIndex:1}}>
                <CircleButton style={styles.buttonStyle} icon={{name:"cruz", style:{color:'white',fontSize:50}}}
                    onClick={()=>{this.setState({createEvent:true})}}/>
                </div>
                {   this.state.createEvent === true?
                    (<div style={{position:'absolute', zIndex:99}}>
                        <EditEvent user={this.props.user} newEvent={true} close={()=>{this.setState({createEvent:false})}}/>
                    </div>):(null)
                }
            </div>
        )
    }  
}

let styles = {
    buttonStyle:{
        backgroundImage:"linear-gradient(135deg, rgba(255,102,0,1) 42%, rgba(77,7,87,0.8) 85%)",
        borderRadius:100,
        height:60,
        width:60,
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        position:'absolute',
        top:140,
        cursor:'pointer'
    }
}

const mapStateToProps = state => ({
    user: state.UserReducer.user
  });
  
  const mapDispatchToProps = {

  };
  
export default connect(mapStateToProps, mapDispatchToProps)(Events);