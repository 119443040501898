import React, {Component} from 'react';
import { BrowserRouter, Route, Switch} from "react-router-dom";
import { connect } from "react-redux";

import Sections from '../screens/Sessoes/Sections'
import Events from '../screens/Eventos/Events'
import NotesList from '../screens/Notas/NotesList'
import Login from '../screens/Login/Login'
import SincronizarNotas from '../screens/Sincronia/SincronizarNotas'
import SelectedEvent from '../screens/Eventos/SelectedEvent'
import Profile from '../screens/Profile/Profile'
import Enquetes from '../screens/Enquetes/Enquetes';
import EventosVinculados from '../screens/Enquetes/EventosVinculados';
import VisualizarEnquete from '../screens/Enquetes/VisualizarEnquete'

class Routes extends Component {

  constructor(props) {
    super(props)
  }
  
  render() {
    return (
        <div>
            <BrowserRouter>
                <Route exact path="/" component={Login} />
                <Route exact path="/notes_list" component={NotesList}/>
                <Route exact path="/sections" component={Sections}/>
                <Route exact path="/profile" component={Profile}/>
                <Route exact path="/events" component={Events}/>
                <Route exact path="/selected_event" component={SelectedEvent} />
                <Route exact path="/sincronizar_notas" component={SincronizarNotas} />
                <Route exact path="/enquetes" component={Enquetes} />
                <Route exact path="/eventos_vinculados" component={EventosVinculados} />
                <Route exact path="/visualizar_enquete" component={VisualizarEnquete} />
            </BrowserRouter>
        </div>
    )
  }
}

const mapStateToProps = state => ({
    screen: state.UserReducer.screen,
});

const mapDispatchToProps = {
  
};

export default connect(mapStateToProps, mapDispatchToProps)(Routes)

