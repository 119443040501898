import React,{Component} from 'react';
import { connect } from 'react-redux'
import { Row } from 'react-bootstrap';
import {TransparentButton} from '../../components/Button';
import '../../icons/style.css'



class SideContainer extends Component {
    constructor(props){
        super(props);
        this.state = {
            pastas:[],
        }
    }

    loadPastasData(data){
        const {selectedFolder} =this.props
        //data =  data.slice(data.length - 5, data.length)
        if(data){
          return data.map((folder) => {
              return (
                  <Row className="item" style={{alignItems:'center'}} key={folder.id}>
                    <TransparentButton 
                    label={{text:folder.name,style:{fontSize:12, margin:0, color:'white', textAlign:'left'}}}
                    onClick={()=>{selectedFolder(folder)}}/>
                  </Row>
              );
          })
        }else{
            return <div/>
        }
    }

    render(){
        const {pastas} = this.props;
        const {sort, sortNumber, folders} =  this.props;
        return(
            <div style={{display:'flex', flexDirection:'column', padding:10}}>
                <TransparentButton label={{text:'Sessões',style:styles.title}} 
                onClick={()=>{
                    sort(0)
                    folders(false)
                }}/>
                <div style={{display:'flex', flexDirection:'column', alignItems:'flex-start', margin:'20px 0 20px'}}>
                    <TransparentButton label={{text:'Ultima',style:styles.sortItens}}
                    onClick={()=>{ 
                        if(sortNumber === 1)sort(2) 
                        else sort(1) 
                    }}/>

                    <TransparentButton label={{text:'Por Nome',style:styles.sortItens}}
                    onClick={()=>{
                        if(sortNumber === 3)sort(4)
                        else sort(3)
                    }}/>
                </div>
                <div style={{width:'100%', height:1,backgroundColor:'gray'}}/>
                    <TransparentButton label={{text:'Pastas',style:styles.title}}
                    onClick={()=>{
                        sort(0)
                        folders(true)
                    }}/>
                <div>
                    <div className="" style={{position:'relative',overflow:'auto', height:400, marginTop:0}}>
                        {this.loadPastasData(pastas)}
                    </div>
                    <div style={{marginTop:20}}>
                        <TransparentButton icon={{name:'copy', style:{color:"#FF6600", fontSize:25}}} onClick={()=> this.props.openModal()}/>
                    </div>
                    {
                    //<button style={{display:'flex', alignItems:'center'}}>
                     //   <Icon name="uano-engrenagem" color="#FF6600" size={25}/>
                     //   <p style={{color:'white', fontSize:10, fontWeight:'bold',marginBottom:2, marginLeft:6}}>Configurar pastas</p>
                    //</button>
                    }
                </div>
            </div>
        )
    }  
}

const styles = {
    sortItens:{
        color:'white',
        fontSize:12,
        cursor:'pointer',
    },
    title:{
        fontWeight:'bold', 
        color:'#FF6600', 
        marginLeft:-1,
        cursor:'pointer',
        marginTop:10,
        fontSize:20,
        textAlign:'left'
    }
}

const mapStateToProps = state => ({
    pastas : state.SectionReducers.pastas
  });
  
  const mapDispatchToProps = {
  };
  
export default connect(mapStateToProps, mapDispatchToProps)(SideContainer);