import React, { Component } from 'react';
import Modal from '../../components/Modal';
import Input from '../../components/Input';
import { Button, TransparentButton } from '../../components/Button';
import moment from 'moment'
import generateUUID from '../../libs/functions/uuid'
import TextArea from '../../components/TextArea';
import { criarEnquete } from '../../libs/redux/actions/EnquetesActions'
import { connect } from 'react-redux';
class CriarEnquete extends Component {
    constructor(props) {
        super(props);
        this._id = generateUUID();
        this.state = {
            enquete: {
                id: generateUUID(),
                date: moment().format('DD/MM/YYYY HH:mm:ss'),
                index_date: moment().format('x'),
                name: '',
                descricao: '',
                respostas: {
                    [this._id]: {
                        id: this._id,
                        name: '',
                        total: 0
                    },
                },
            },
            valid: false,
        }
    }

    novaResposta = () => {
        const { enquete } = this.state;
        const _id = generateUUID();

        enquete.respostas[_id] = {
            id: _id,
            name: '',
            total: 0,
        }
        this.setState({ enquete })
    }

    deletarResposta = (respostaID) => {
        const { enquete } = this.state;
        delete enquete.respostas[respostaID];
        this.setState(enquete)
    }

    editarResposta(id, name) {
        const { enquete } = this.state;
        enquete.respostas[id].name = name;
        this.setState(enquete)
    }

    salvarRespostas() {
        const { enquete } = this.state;
        const { user, criarEnquete, close } = this.props;
        this.verificarEnquete(enquete)
        .then(() => {
            criarEnquete(enquete, user.key);
            close();
        })
        .catch(err => console.log(err))
    }

    verificarEnquete = async (enquete) => {
        let invalido = false;

        return new Promise((resolve, reject) => {
            if (!enquete.name) {
                let input = document.querySelector(`#input-title`)
                input.style.border = "2px solid #ff6600"
                input.placeholder = "Titulo Obrigatorio!";
                invalido = true
                reject("Titulo obrigatorio")
            };

            if(Object.values(enquete.respostas).length < 2){
                alert("A enquete precisa ter no minimo 2 respostas") //Mudar para um "Toast" que vem de cima para baixo com esta mensagem.
                reject("Deve ter pelomenos 2 respostas")
            }

            Object.values(enquete.respostas)
                .map(resposta => {
                    if (!resposta.name) {
                        let input = document.querySelector(`#input-${resposta.id}`)
                        input.style.border = "2px solid #ff6600"
                        input.placeholder = "Resposta é obrigatória!";
                        invalido = true

                        reject("Respota obrigatoria")
                    };
                })

            if (invalido === false) {
                resolve('ok')
            }
        })
    }

    render() {
        const { enquete } = this.state;
        return (<Modal headerTitle="Criar enquete" close={() => { this.props.close() }}>
            <main style={styles.mainContainer}>
                <div style={styles.inputsContainer}>
                    <Input placeHolder={'Titulo'}
                        id="input-title"
                        onFocus={({ target }) => {
                            target.style.border = "1px solid #dfdfdf";
                            target.placeholder = "Titulo";
                        }}
                        onTextChange={(text) => {
                            enquete.name = text;
                            this.setState(enquete)
                        }} />
                    <TextArea style={styles.textArea} placeHolder={'Descricao'}
                        onTextChange={(text) => {
                            enquete.descricao = text;
                            this.setState(enquete)
                        }} />
                </div>
                <div style={styles.respostasMainContainer}>
                    <p style={{ color: '#ff6600' }}>Respostas</p>
                    <RespostasList
                        respostas={enquete.respostas}
                        novaResposta={() => this.novaResposta()}
                        deletarResposta={(respostaID) => this.deletarResposta(respostaID)}
                        editarResposta={(respostaID, text) => this.editarResposta(respostaID, text)}
                    />
                </div>
            </main>
            <footer style={styles.footerContainer}>
                <TransparentButton style={{ flex: 1 }} onClick={() => this.salvarRespostas()}>
                    <p style={styles.text}>Salvar</p>
                </TransparentButton>
                <div style={styles.separator} />
                <TransparentButton style={{ flex: 1 }} onClick={() => this.props.close()}>
                    <p style={{ ...styles.text, color: '#ff6600' }}>Cancelar</p>
                </TransparentButton>
            </footer>
        </Modal>)
    }
}

const RespostasList = ({ respostas, novaResposta, deletarResposta, editarResposta }) => {
    return (
        <div style={styles.respostasListContainer}>
            {
                Object.values(respostas).map((resposta) => (
                    <SingleResposta
                        respostaName={(text) => editarResposta(resposta.id, text)}
                        resposta={resposta}
                        key={resposta.id}
                        deletarResposta={() => deletarResposta(resposta.id)} />))
            }
            <Button style={styles.adddRespostaButton} onClick={novaResposta}>Nova Resposta</Button>
        </div>
    )
}

const SingleResposta = ({ deletarResposta, respostaName, resposta }) => {
    return (
        <div style={{ display: "flex", justifyContent: 'space-between', flex: 1, width: '100%', alignItems: 'center', marginBottom: 5 }}>
            <Button icon={{ name: "minus", style: { color: "white", fontSize: 10, margin: 0 } }}
                style={{ backgroundColor: '#ff6600', borderRadius: 100, padding: 0, width: 30, height: 30 }}
                onClick={deletarResposta} />
            <Input
                onFocus={({ target }) => {
                    target.style.border = "1px solid #dfdfdf";
                    target.placeholder = "Resposta";
                }}
                id={`input-${resposta.id}`}
                placeHolder="Resposta"
                style={{ flex: 0.99 }}
                onTextChange={(text) => respostaName(text)} />
        </div>
    )
}

const styles = {
    mainContainer: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        padding: 10
    },
    inputsContainer: {
        display: 'flex',
        flexDirection: 'column',
        margin: 5
    },
    textArea: {
        resize: 'none',
        height: 100,
        padding: 8,
        outline: 'none',
        backgroundColor: '#fff',
        border: '1px solid #dfdfdf',
        borderRadius: 5,
        marginTop: 5,
        fontSize: 12
    },
    respostasMainContainer: {
        flex: 1,
        margin: 5
    },
    footerContainer: {
        flex: 1,
        display: 'flex',
        justifyContent: 'space-around',
        borderTop: '1px solid #eee'
    },
    text: {
        flex: 1,
        padding: 10,
        margin: 0,
        textAlign: 'center'
    },
    separator: {
        width: 1,
        borderLeft: '1px solid #eee'
    },
    respostasListContainer: {
        maxHeight: 210,
        overflowX: 'auto',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
    },
    adddRespostaButton: {
        backgroundColor: '#ff6600',
        color: 'white',
        borderRadius: 5,
        fontSize: 14,
        padding: 5,
        maxWidth: 150
    }
}

const mapStateToProps = state => ({
    user: state.UserReducer.user,
    enquetes: state.EnquetesReducer.enquetes
})

const mapDispatchToProps = {
    criarEnquete,
}

export default connect(mapStateToProps, mapDispatchToProps)(CriarEnquete);