import {
    LOAD_SECTIONS,
    LOAD_PASTAS,
    SELECTED_SECTION,
    SORT_SECTION,
    ADD_NOTES,
    SELECTED_FOLDER,
    SET_SECTION_FOLDER
} from '../../../config/Types'

const INITIAL_STATE = {
    sections:[],
    originalSections:[],
    pastas:[],
    originalPastas:[],
    section:{},
    notes:[],
    folder:{},
}

export default (state = INITIAL_STATE, action) => {
    switch(action.type){
        case LOAD_SECTIONS: return {...state, sections: action.payload, originalSections: action.payload};
        case LOAD_PASTAS: return {...state, pastas: action.payload, originalPastas:action.payload};
        case SELECTED_SECTION: return {...state, section: action.payload};
        case SELECTED_FOLDER: return {...state, folder: action.payload};
        case SET_SECTION_FOLDER: return {...state, sections: action.payload};
        case SORT_SECTION : return {...state, sections: action.payload}
        case ADD_NOTES : return {...state, notes: action.payload}
        default: return state;
    }
}