import {firebaseStorage,firebaseDatabase} from '../../config/FirebaseUtils'
import Resizer from 'react-image-file-resizer';

const compressImage = (type, url)=>{
    return new Promise((resolve, reject)=>{
        if(!url || url =='')
            reject({error:'url error'})
        if(type===0){
            resolve(url)
        }else{
            Resizer.imageFileResizer(
                url, //is the file of the new image that can now be uploaded...
                1000, // is the maxWidth of the  new image
                1000, // is the maxHeight of the  new image
                'JPEG', // is the compressFormat of the  new image
                70, // is the quality of the  new image
                0, // is the rotatoion of the  new image
                uri=>resolve(uri),  // is the callBack function of the new image URI
                'blob'  // is the output type of the new image
            )
        }
    })
}

const uploadImage = (note, path)=> {
    const uploadPhoto = ()=>{
        return new Promise((resolve, reject)=>{
           const photoFirebaseRefs = [`images/${note.id}`,`images/_thumb_${note.id}`]
               for(let x = 0; x <= 1; x++){
                   compressImage(x,note.photoURL).then((uri)=>{
                       const uploadTask = firebaseStorage.ref(photoFirebaseRefs[x]).put(uri)
                       uploadTask.on("state_changed",
                       snapshot=>{console.log(snapshot)},
                       error => {
                           reject(error);
                       },
                       () => {
                           firebaseStorage
                           .ref(photoFirebaseRefs[x])
                           .getDownloadURL()
                           .then(url => {
                               if(x===0){
                                   note.firebasePhotoURL = url;
                               }else{
                                   note.firebaseThumbURL = url;
                                   note.photoURL = url
                               }
                           });
                       });
                   })
               }
            resolve(note)
        })
    }
    uploadPhoto().then((data)=>{
        setTimeout(()=>{
            firebaseDatabase.ref(path).update(data)
            .then(() =>{ return data })
            .catch(err=>console.log('error',err))
        },3000)
    })
}

export default uploadImage;