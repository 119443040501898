const dayPTBR = (day) =>{
    //recebe a data em ingles e retorna em portugues.
    switch(day){
        case 'Sunday': return  'Domingo'; 
        case 'Monday': return  'Segunda'; 
        case 'Tuesday': return  'Terça'; 
        case 'Wednesday': return  'Quarta'; 
        case 'Thursday': return  'Quinta'; 
        case 'Friday': return  'Sexta'; 
        case 'Saturday': return  'Sabado'; 
        //default:return 'bacon '+ day
    }
}

export default dayPTBR;