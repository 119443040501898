import { 
  LOADING, 
  LOAD_EVENTS, 
  LOAD_ANALYTICS 
} from '../../../config/Types'

const INITIAL_STATE = {
  loading: false,
  events: [],
  analytics: ''
}


export default (state = INITIAL_STATE, action) => {
  switch(action.type){
    case LOAD_ANALYTICS : return { ...state, analytics: action.payload }
    case LOAD_EVENTS : return { ...state, events: action.payload }
    case LOADING : return { ...state, loading: action.payload }
    default: return state;
  }
}