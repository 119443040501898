import React from 'react';
import Icon from './Icon'
import {TransparentButton} from './Button'

const Header = (childs) => {
    const child = childs.children.filter(child => child.type === 'header')
    if(child) 
        return (<div style={{margin:'0 10px 0 10px'}}>{child}</div>)
    else 
        return null
}

const Main = (childs) => {
    const child = childs.children.filter(child => child.type === 'main')
    if(child)         
        return (<div style={{flex:1, display:'flex', flexDirection:'column'}}>{child}</div>)
    else 
        return null
}

const Footer = (childs) => {
    const child = childs.children.filter(child => child.type === 'footer')
    if(child)         
        return (<div style={{display:'flex'}}>{child}</div>)
    else 
        return null
}

const Modal  = (props) => {
    const {close} = props;
    const children = props.children
    return(
        <div style={styles.mainContainer}>
            <header style={styles.titleDiv}>
                <p style={{marginTop:15,flex:1,textAlign:'center', fontWeight:'bold', color:'#FF6600'}}>{props.headerTitle}</p>
                <TransparentButton onClick={()=>{close()}}
                icon={{name:'uano-cancelar', style:{color:'#FF6600', fontSize:35}}}/>                        
            </header>
            <Header children = {children}/>
            <Main children={children}/>
            <Footer children={children}/>
        </div>
    )
    
}

const styles = {
    mainContainer:{
        backgroundColor:'white', 
        borderRadius:20,
        width:650, 
        height:550,
        display:'flex',
        flexDirection:'column',
        boxShadow:'5px 2px 10px rgba(0,0,0,0.2), 8px 2px 20px rgba(0,0,0,0.25)'
    },
    titleDiv:{
        marginTop:20,
        textAlign:'center',
        margin:'20px 10px 0px 60px',
        height:50,
        display:'flex',
        justifyContent:'space-between',
        alignItems:'center'
    },
}

    
export default Modal;