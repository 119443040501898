import React,{Component} from 'react';
import { connect } from 'react-redux'
import { Row } from 'react-bootstrap';
import {selectedSection} from '../../libs/redux/actions/SectionActions'
import {Link} from 'react-router-dom'
import {sortSections} from '../../libs/functions/sortData'
import './SectionList.css';
import '../../icons/style.css'
import {sectionTimes} from '../../libs/functions/sectionTimes';
import {searchSectionText} from '../../libs/functions/searchText';

class SectionList extends Component {
    constructor(props){
        super(props);
        this.state = {
            sections:[],
            pastas:[]
        }
    }

    loadSectionData(data){
        const {sortType, searchText, folderId} =  this.props;
        if(data){
            data = data.filter(item=>item)
            if(folderId){
                data = data.filter(item=>{if(item.pasta)return item.pasta.id == folderId})
          }

          const sortedData =  sortSections(data, sortType);
          const filteredData = searchSectionText(sortedData, searchText);
          return filteredData.map((section) => {
              
              const {day,date,initHour, initMinute, endHour, endMinute} = sectionTimes(section)

              return (
                  <Row style={{alignItems:'center', cursor:'pointer',margin:"50px 0 0 10px"}} key={section.id}
                  onClick={()=>{
                      const {selectedSection, getSection } =  this.props;
                      selectedSection(section)
                      
                      if(getSection) getSection(section)                      
                      else document.querySelector('#Link').click()
                      
                    }}>
                        <div className="iconContainer" style={{display:'flex', justifyContent:'center', alignItems:'center',height: 27,width:27,borderRadius: 100,backgroundColor: "#FF6600",margin:8}}>
                            <span className="icon-play" style={{color:'white', fontSize:16, marginLeft:5, padding:0}}></span>
                        </div>
                        <div>
                            <p className="textItem" id="textDay">{`${day} - ${date} - ${initHour}h${initMinute} a ${endHour}h${endMinute}`}</p>
                            <p id="textSection">{section.name}</p>
                            <p className="textItem" id="textLocal">Local de Gravação da Sessão</p>
                        </div>
                </Row>
              );
            })
        }else{
            return <div/>
        }
    }
    
    render(){
        const {searchText, sections} = this.props;
        return(
            <div style={{overflow:'auto'}}>
                {this.loadSectionData(sections,searchText)}
                <Link id="Link" to={'/notes_list'} style={{textDecoration:'none'}}/>                  
            </div>
        )
    }  
}

const mapStateToProps = state => ({
    sections:state.SectionReducers.sections
});
  
  const mapDispatchToProps = {
    selectedSection,
};
  
export default connect(mapStateToProps, mapDispatchToProps)(SectionList);