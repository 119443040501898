import {
    LOAD_EVENTS,
    SELECTED_EVENT,
} from '../../../config/Types'

const INITIAL_STATE = {
    originalEvents:[],
    events:[],
    event:{},
}

export default (state = INITIAL_STATE, action) => {
    switch(action.type){
        case LOAD_EVENTS: return {...state, events: action.payload, originalEvents: action.payload};
        case SELECTED_EVENT: return {...state, event: action.payload};
        default: return state;
    }
}