import React, { Component } from 'react';
import { connect } from 'react-redux'

import { CircleButton } from '../../components/Button'
import Menu from '../Menu/Menu'
import Input from '../../components/Input'
import SectionList from './SectionList'
import FoldersList from '../Pastas/FoldersList'
import SideContainer from '../Menu/SideContainer'
import { selectedSection, selectFolder } from '../../libs/redux/actions/SectionActions'
import AddFolders from '../Pastas/AddFolders'
import SelectEvent from '../Eventos/SelectEvent'

class Sections extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sortNumber: 1,
            searchText: '',
            folders: false,
            selectedFolder: null,
            foldersModal: false,
            selectSectionFolder: false,
            selectSectionModal: false,
            folderSideListSort: 0,
            pasta: {}
        }
    }

    addFoldersModal() {
        const { foldersModal } = this.state;
        if (foldersModal === true) {
            return (
                <div style={{ position: 'absolute', zIndex: 99, display: 'flex', width: '50vw', height: '100vh', justifyContent: 'center', alignItems: 'center' }}>
                    <AddFolders close={() => { this.setState({ foldersModal: false }) }} />
                </div>
            )
        }
    }

    sectionToFolder() {
        const { selectSectionModal, pasta } = this.state;
        if (selectSectionModal === true && pasta) {
            return (
                <div style={{ position: 'absolute', zIndex: 99, display: 'flex', width: '50vw', height: '100vh', justifyContent: 'center', alignItems: 'center' }}>
                    <SelectEvent close={() => { this.setState({ selectSectionModal: false }) }} />
                </div>
            )
        }
    }

    render() {
        const { sortNumber, searchText, folders, selectedFolder } = this.state;
        return (
            <div style={{ display: 'flex', margin: 0, padding: 0, height: '100vh', width: '100vw' }}>
                <div>
                    <Menu style={{ flex: 1, maxWidth: 300, minWidth: 250 }} />
                </div>
                <div style={{ flex: 1, backgroundColor: '#2E2D33', minWidth: 120, maxWidth: 150, boxShadow: '5px 4px 10px rgba(0,0,0,0.2), 10px 1px 22px rgba(0,0,0,0.25)' }}>
                    <SideContainer
                        openModal={() => { this.setState({ foldersModal: true }) }}
                        sort={n => this.setState({ sortNumber: n })}
                        sortNumber={sortNumber}
                        folders={n => this.setState({ folders: n, selectedFolder: null })}
                        selectedFolder={pasta => {
                            this.props.selectFolder(pasta)
                            this.setState({ selectedFolder: pasta.id, folders: false, selectSectionFolder: true })
                        }
                        } />
                </div>
                <div style={{ marginTop: 20, flex: 1, display: 'flex', flexDirection: 'column' }}>
                    <div style={{ marginLeft: 10 }}>
                        <Input style={{ width: 300, height: 25, marginLeft: 20 }} id="search-input" placeholder="Procure sessões ou pastas" onTextChange={(text) => { this.setState({ searchText: text }) }} />
                    </div>
                    {this.addFoldersModal()}
                    {this.sectionToFolder()}
                    {
                        /*                        
                    <div style={{position:'absolute', zIndex:99}}>
                        <Modal headerTitle="Configuração de pastas">
                            <main>
                                <RadioGroup
                                    options={{
                                        name:'ordem-pastas',
                                        title:'Ordem',
                                        itens:[
                                            {value:1, label:'Mais recente'},
                                            {value:2, label:'Ordem Alfabetica'},
                                            {value:3, label:'Quantidade de Sesões'},
                                        ]
                                    }}
                                    onChange={value =>this.setState({folderSideListSort:value})}
                                />
                                <input type="text" style={{outline:'none'}}/>
                            </main>
                            <footer>

                            </footer>
                        </Modal>
                    </div>
                        */
                    }
                    <div className="container" style={{ backgroundColor: 'rgba(0,0,0,0.0)' }}>
                        <p id="ultimasSessoes" style={{ marginTop: 20 }}>{folders ? 'Pastas' : 'Sessões'}</p>
                    </div>
                    <div style={{ flex: 1, height: '100vh', overflow: 'auto', padding: '0px 20px 0px 20px' }}>
                        {
                            folders === true ?
                                (<FoldersList
                                    onClick={(pasta) => {
                                        this.setState({ pasta })
                                        this.setState({ selectSectionFolder: true })
                                    }}
                                    searchText={searchText}
                                    sortType={sortNumber}
                                />) :
                                (<SectionList sortType={sortNumber} searchText={searchText} folderId={selectedFolder} />)
                        }
                    </div>
                </div>
                <div style={{ flex: 1, maxWidth: 150, zIndex: 1, marginLeft: -5, backgroundColor: 'white' }}>
                    <CircleButton style={styles.buttonStyle} icon={{ name: "cruz", style: { color: 'white', fontSize: 50 } }}
                        onClick={() => {
                            const { selectSectionFolder } = this.state
                            if (folders === true && selectSectionFolder === false) this.setState({ foldersModal: true })
                            if (selectSectionFolder === true) this.setState({ selectSectionModal: true })
                        }} />
                </div>
            </div>
        )
    }
}

let styles = {
    buttonStyle: {
        backgroundImage: "linear-gradient(135deg, rgba(255,102,0,1) 42%, rgba(77,7,87,0.8) 85%)",
        borderRadius: 100,
        height: 60,
        width: 60,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        top: 140,
        cursor: 'pointer'
    }
}

const mapStateToProps = state => ({
    pastas: state.SectionReducers.pastas,
});

const mapDispatchToProps = {
    selectedSection,
    selectFolder,
};

export default connect(mapStateToProps, mapDispatchToProps)(Sections);