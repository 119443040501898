import React,{Component} from 'react';
import MicRecorder from 'mic-recorder-to-mp3';
import { connect } from 'react-redux'
import WaveSurfer from 'wavesurfer.js';
import MicrophonePlugin from 'wavesurfer.js/dist/plugin/wavesurfer.microphone.min.js';
import {firebaseStorage, firebaseDatabase} from '../../config/FirebaseUtils'
import moment from 'moment'
import uuid from '../../libs/functions/uuid'
import Icon from '../../components/Icon'
import {TransparentButton} from '../../components/Button'

class PalestranteAudioRecorder extends Component{
    Mp3Recorder = new MicRecorder({ bitRate: 128 });
    microphone = ""
    timer = ""
    ms=0
    constructor(props){
        super(props);
        this.state = {
            isRecording: false,
            blobURL: '',
            isBlocked: false,
        }
    }

    componentDidMount(){
        if(this.props.event.audioMaster){
            this.setState({blobURL:this.props.event.audioMaster[0].storageURL})
        }
        var wavesurfer = WaveSurfer.create({
            container     : '#waveform',
            waveColor     : '#FF6600',
            barWidth: 2,
            barHeight:2,
            height: 100,
            barGap: 3,
            interact      : false,
            cursorWidth   : 0,
            plugins: [
                MicrophonePlugin.create()
              ]
        });

        this.microphone = wavesurfer.microphone;

        navigator.getUserMedia({ audio: true },
            () => {
              console.log('Permission Granted');
              this.setState({ isBlocked: false });
            },

            () => {
              console.log('Permission Denied');
              this.setState({ isBlocked: true })
            },
        );
    }

    start = () => {
            this.microphone.start(); 
            if (this.state.isBlocked) {
              console.log('Permission Denied');
            } else {
              this.Mp3Recorder
                .start()
                .then(() => {
                  this.setState({ isRecording: true });
                }).catch((e) => console.error(e));
            }
      };

      stop = () => {
        const duration = moment('00:00:00','HH:mm:ss').add(this.ms,'ms').format('HH:mm:ss')
        const id = uuid();
        const start = moment().format('DD-MM-YYYY-HH-mm-ss')

        this.microphone.stop(); 
        this.ms = 0
        this.Mp3Recorder
        .stop()
        .getMp3()
        .then(([buffer, blob]) => {
            const blobURL = URL.createObjectURL(blob)
            this.setState({ blobURL, isRecording: false });     
            console.log("chamado",'firebase audio record')

            const uploadTask = firebaseStorage.ref('audios').child(this.props.event.userOwner).child(id+".mp3").put(blob)

            console.log(blob)

            uploadTask.on("state_changed",
                       snapshot=>{console.log(snapshot)},
                       error => {
                           console.log(error);
                       },
                       () => {
                           firebaseStorage
                           .ref('audios')
                           .child(this.props.event.userOwner)
                           .child(id+".mp3")
                           .getDownloadURL()
                           .then(url => {
                                firebaseDatabase
                                .ref('events')
                                .child(this.props.event.id)
                                .child('audioMaster/0/')
                                .update({
                                    duration:duration,
                                    format: "mp3",
                                    id:id,
                                    name: start,
                                    sizeInBytes: blob.size,
                                    startAt:start,
                                    status: 1,
                                    storageURL: url,
                                })
                                .then(this.setState({blobURL:url}))
                           });
                       });
          }).catch((e) => console.log(e));
      };
      

    render(){
        return(
            <div style={{backgroundColor:'rgba(130,130,130,0.5)',position:'absolute', justifyContent:'center', alignItems:'center', display:'flex',height:'100vh', width:'100vw'}}>
            <div style={{backgroundColor:'white', display:'flex', flexDirection:'column', height:400, width:400, borderRadius:20, padding:20}}>
                <div style={{width:'100%',flex:0.3, display:'flex', justifyContent:'flex-end', alignItems:'flex-start'}}>
                    <TransparentButton onClick={()=>{this.props.close()}}>
                        <Icon name={'cancelar'} style={{fontSize:30, color:'#FF6600'}}/>
                    </TransparentButton>
                </div>
                <div style={{flex:1}}>
                <div style={{display:'flex', flexDirection:'column', flex:1,justifyContent:'center', alignItems:'center'}}>
                    <div style={{flex:1, display:'flex', alignItems:'center'}}> 
                        <button 
                        style={{height:60, width:60, backgroundColor:'#FF6600', display:'flex', alignItems:'center', justifyContent:'center', borderRadius:100, padding:0}}
                        onClick={()=>{
                            if(!this.state.isRecording){
                                this.timer = setInterval(()=>this.ms = this.ms + 100,100)
                                this.start()
                            }else{
                                clearInterval(this.timer)
                                this.stop()
                            }
                        }}>
                            {
                            !this.state.isRecording?
                            <div style={{backgroundColor:'white', height:40, width:40, borderRadius:100, margin:0}}/>:
                            <div style={{backgroundColor:'white', height:35, width:35, borderRadius:5, margin:0}}/>
                            }
                        </button>
                    </div>
                    <div id='waveform' style={{width:'100%', height:100, margin:5}}/>
                    <div style={{display:'flex',alignSelf:'flex-bottom', flexDirection:'column', justifyContent:'center'}}>

                        {this.state.isRecording === false && this.state.blobURL !== '' ?(<audio src={this.state.blobURL} controls="controls" />):(null)}
                        {this.state.isRecording === true?(<p>Gravando...</p>):(null)}
                        {this.state.isRecording === false && this.state.blobURL === ''?(<p>Iniciar Gravação</p>):(null)}
                    </div>
                </div>
                </div>
            </div>
        </div>
        )
    }
}

const mapStateToProps = state => ({
    event : state.EventReducers.event,
});

const mapDispatchToProps = {
};
  
export default connect(mapStateToProps, mapDispatchToProps)(PalestranteAudioRecorder);