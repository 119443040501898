import React,{Component} from 'react';

import moment from 'moment'
import TextArea from '../../components/TextArea'
import {Button} from '../../components/Button'
import Icon from '../../components/Icon'
import AddNotesFooter from './AddNotesFooter';
import EditNotes from './EditNotes'

import Modal from '../../components/Modal'

export default class AddTextNotes extends Component{
    constructor(props){
        super(props);
        this.state = {
            text:'',
        };
    }

    componentDidMount(){
        console.log('time', this.props.currentTime)
        const {note} = this.props;
        if(note){
            if(note.type === 'T'){
                this.setState({text:note.text})
            }
        }
    }

    render(){
        const {close, note}=this.props;
        return(
            <Modal headerTitle="Nota de Texto" close={()=>{close()}}>
                <main style={{height:'100%', display:'flex'}}>
                    <TextArea style={styles.textArea} placeholder='Digite o texto aqui...' defaultValue={this.state.text} onTextChange={(text)=>{
                            this.setState({text})
                            if(note){
                                note.text = text
                            }
                        }}/>
                </main>
                <footer style={{flex:1}}>
                    {
                        this.props.editar?
                        <EditNotes note={note} {...this.props} text={this.state.text} type="T"/>
                        :
                        <AddNotesFooter note={note} {...this.props} text={this.state.text} type="T"/>
                    }
                </footer>
            </Modal>
        )
    }
}

const styles = {
    textArea:{
        margin:30,
        outline:'none',
        resize:'none',
        border:'1px solid #ddd',
        borderRadius:30,
        flex:1,
        padding:30
    },
    textTime:{
        fontSize:10,
        margin:0
    }
}