import React,{Component} from 'react';
import { connect } from 'react-redux'
import {Link} from 'react-router-dom'
import {TransparentButton} from '../../components/Button'
import '../../icons/style.css'


class Menu extends Component {
    constructor(props){
        super(props);
        this.state = {
            avatarURL:'http://naturalemporium.com.br/wp-content/uploads/2018/10/avatar-369-456321.png',
        }
    }
    componentDidMount(){
    }

    render(){
        const logo = "https://uanoapp.com/themes/uano/assets/images/logo.png"
        const {user} =  this.props
        return(
            <div style={styles.menuContainer}>
                <Link to={"/sections"} style={{ textDecoration: 'none' }}>                    
                    <TransparentButton style={{padding:20}}>
                        <img id="logo" src={logo} style={{alignSelf:'center'}}/>
                    </TransparentButton>
                </Link>
                <Link to={"/profile"} style={{ textDecoration: 'none' }}>                    
                    <TransparentButton style={styles.avatarButton}>
                            <img style={styles.avatarImage} src={user.photoURL}/>
                            <p style={styles.avatarText}>{user.name}</p>
                    </TransparentButton>
                </Link>
                <Link to={"/sections"} style={{ textDecoration: 'none' }}>                    
                    <TransparentButton style={styles.iconButton} 
                    icon={{name:'play',style:{...styles.icons,fontSize:15,paddingLeft:5}}}>
                        <p style={styles.iconText}>Sessões</p>
                    </TransparentButton>
                </Link> 
                <Link to={"/events"} style={{ textDecoration: 'none' }}>                                       
                    <TransparentButton style={{...styles.iconButton,borderTop:'1px solid rgba(255,255,255,0.5)'}}
                    icon={{name:'mic-preto',style:{...styles.icons,fontSize:20}}}>
                        <p style={styles.iconText}>Area do Palestrante</p>
                    </TransparentButton>
                </Link>
                <Link to={"/enquetes"} style={{ textDecoration: 'none' }}>                                       
                    <TransparentButton style={{...styles.iconButton}}
                    icon={{name:'edit',style:{...styles.icons,fontSize:20}}}>
                        <p style={styles.iconText}>Visualizar Enquetes</p>
                    </TransparentButton>
                </Link>
                <div style={{borderBottom:'1px solid rgba(255,255,255,0.5)', borderTop:'1px solid rgba(255,255,255,0.5)'}}>
                    <TransparentButton style={{...styles.iconButton}}
                    icon={{name:'contatos',style:{...styles.icons}}}>
                        <p style={styles.iconText}>Amigos</p>
                    </TransparentButton>
                    <TransparentButton style={styles.iconButton}
                    icon={{name:'uano-group',style:{...styles.icons}}}>
                        <p style={styles.iconText} >Grupos</p>
                    </TransparentButton>
                    <TransparentButton style={styles.iconButton} 
                    icon={{name:'uano-event',style:{...styles.icons}}}>
                        <p style={styles.iconText} >Eventos</p>
                    </TransparentButton>
                </div>
                <div  style={{borderColor:'rgba(0,0,0,0.0)'}}>
                    <TransparentButton style={styles.iconButton} 
                    icon={{name:'uano-engrenagem',style:{...styles.icons}}}>                         
                        <p style={styles.iconText} >Configurações</p>
                    </TransparentButton>                        
                    <TransparentButton style={styles.iconButton} 
                    icon={{name:'ajuda',style:{...styles.icons}}}>                            
                        <p style={styles.iconText} >Ajuda</p>
                    </TransparentButton>
                    <div style={{marginLeft:20}}>                        
                        <p style={styles.iconText} >Tutoriais</p>
                        <p style={styles.iconText} >Fale Conosco</p>
                        <p style={styles.iconText} >FAQ</p>
                    </div>                        
                </div>
            </div>
        )
    }  
}

const styles = {
    menuContainer:{
        background: "linear-gradient(-20deg, #FF6600, #4D0757, #4D0757)",
        height: '100%',
        display:'flex',
        flexDirection:'column',
        padding:30,
        marginRight:-15
    },
    avatarButton:{
        width:200,
        display:'flex',
        alignItems:'center',
    },
    avatarImage:{
        width:60,
        height:60,
        borderRadius:100,
        padding:0
    },
    avatarText:{
        color:'white',
        marginLeft:5,
        marginTop:10,
        textAlign:'left',
        fontSize:14,
    },
    iconButton:{
      display:'flex',
      alignItems:'center',
      padding:'15px 0 15px 0',
      flex:1,
      width:'100%'
    },
    iconText:{
        color:'white',
        margin:'0 0 0 10px',
        fontSize:13
    },
    icons : {
        color:"white",
        fontSize:20
    }
};

const mapStateToProps = state => ({
    user: state.UserReducer.user
  });
  
  const mapDispatchToProps = {
  };
  
export default connect(mapStateToProps, mapDispatchToProps)(Menu);