import React,{Component} from 'react';

import moment from 'moment'
import TextArea from '../../components/TextArea'
import {TransparentButton, Button} from '../../components/Button'
import AddNotesFooter from './AddNotesFooter'
import EditNotes from './EditNotes'
import Modal from '../../components/Modal'
import Icon from '../../components/Icon'

export default class AddPhotoNotes extends Component{
    constructor(props){
        super(props);
        this.state = {
            text:'',
            imageLocalPath:''
        };
    }

    render(){
        const {close, note}=this.props;
        return(
            <Modal headerTitle="Nota de Photo" close={()=>close()}>
                <main style={{flex:1, display:'flex'}}>
                    <div style={{display:'flex',justifyContent:'center', alignItems:'center', padding:20,flexDirection:'column',flex:1, margin:20, borderRadius:15}}>
                        <input type="file" name="file" 
                        style={{display:'none'}}
                        onChange={()=>{
                            let files = document.getElementsByName('file')[0].files
                            if(files && files[0]){
                                if(note){
                                    if(note.type === 'P'){
                                        note.photoURL = files[0]
                                        this.setState({imageLocalPath:files[0]})
                                    }
                                }else{
                                    this.setState({imageLocalPath:files[0]})
                                }
                            }
                       }}/>
                       {
                           this.state.imageLocalPath === ''?
                           (
                               <TransparentButton 
                               icon={{name:'pasta',style:{fontSize:70, color:'#FF6600'}}} 
                               label={{text:'Escolher em seus arquivos',style:{fontSize:12, margin:8, color:'gray'}}}
                               onClick={()=>{document.getElementsByName('file')[0].click()}}/>
                           ):
                           (
                               <Button onClick={()=>{document.getElementsByName('file')[0].click()}} style={{flex:1}}>
                                    <img src={URL.createObjectURL(this.state.imageLocalPath)} 
                                    style={{maxWidth:'100%', maxHeight:'100%'}}/>
                               </Button>
                           )
                       }
                    </div>
                </main>
                <footer style={{flex:1}}>
                    { 
                        this.props.editar?
                        <EditNotes {...this.props} note={note} imagePath={this.state.imageLocalPath} type="P"/>
                        :
                        <AddNotesFooter {...this.props} note={note} imagePath={this.state.imageLocalPath} type="P"/>
                    }
                </footer>
            </Modal>
        )
    }
}