import React,{Component} from 'react';
import { connect } from 'react-redux'
import { Row, Col } from 'react-bootstrap';
import Menu from '../Menu/Menu'
import Input from '../../components/Input'
import TouchableOpacity from '../../components/TouchableOpacity'
import {getUserData, updateUserData} from '../../libs/redux/actions/UserActions'

class Profile extends Component {
    constructor(props){
        super(props);
        this.state = {
            user:props.user,
            refresh:false,
            estados:null,
        }
    }
    
   getEstados(){
        const {estados} = this.state;

        if(estados){
            return (
                <select style={{display:"none"}}>
                    {
                        estados.map(estado=>{
                            return(<option>{estado.sigla}</option>)
                        })
                    }
                </select>
            )
        }
    }

    render(){
        let {user} = this.state;

        return(
            <Row id ="row" onLoad={()=>{}}>
                <Col md={3}>
                    <Menu/>
                </Col>
                <Col md={3} lg={2}>
                    <div style={{paddingTop:125, display:'flex',flexDirection:'column', alignItems:'flex-end'}}>
                        <div style={{height:120, width:120, borderRadius:20}}>
                            <div id="sobreporImagen" style={{height:120, width:120, borderRadius:20,backgroundColor:'gray',position:'absolute', display:'flex',alignItems:'center', justifyContent:'center', opacity:0}}
                            onMouseEnter={()=>{document.getElementById('sobreporImagen').style.opacity = 0.6}}
                            onMouseLeave={()=>{document.getElementById('sobreporImagen').style.opacity = 0}}
                            >
                                <p style={{color:'white'}}>Alterar Foto</p>
                            </div>
                            <img src={user.photoURL} style={{width:120, height:120,borderRadius:20, zIndex:0}} alt='Avatar'/>
                        </div>
                    </div>
                </Col>
                <Col md={6} style={{paddingTop:100}}>
                    <div style={styles.formContainer}>
                        <p style={styles.title}>Informações de acesso</p>
                        <Input style={{margin:"0px 0px 10px 0px"}} placeholder="Nome Usuario" defaultValue={user.name} onTextChange={(text)=> user.name = text}/>
                        {/*
                        <Input placeholder="endereco_de@email.com" defaultValue={user.email} margin={"10px 0px 10px 0px"} onTextChange={(text)=> user.email = text}/>
                        */}
                    </div>
                    <div style={styles.formContainer}>
                        <p style={styles.title}>Informações Adicionais</p>
                        <div>
                            <Input style={{margin:"0px 0px 20px 0px"}} placeholder="Endereco" defaultValue={user.endereco} onTextChange={(text)=> user.endereco = text}/>
                        </div>
                        <div style={{display:'flex'}}>
                            <Input style={{margin:"0px 20px 20px 0px", width:100}} placeholder="Numero" defaultValue={user.numero} onTextChange={(text)=> user.numero = text}/>
                            <Input style={{margin:"0px 0px 20px 0px"}} placeholder="Complemento" defaultValue={user.complemento} onTextChange={(text)=> user.complemento = text}/>
                        </div>
                        <div style={{display:'flex'}}>
                            <Input style={{margin:"0px 20px 0px 0px", width:100}} placeholder="Cep" defaultValue={user.cep} onTextChange={(text)=> user.cep = text}/>
                            <Input style={{margin:"0px 20px 0px 0px", width:120}} placeholder="Estado" defaultValue={user.estado} onTextChange={(text)=> user.estado = text}/>
                            <Input placeholder="Cidade" defaultValue={user.cidade} onTextChange={(text)=> user.cidade = text}/>
                        </div>
                    </div>
                    <div  style={styles.formContainer} >
                            {/*
                        <p style={styles.title}>Informações Adicionais</p>
                        <div style={{display:'flex', flexDirection:'column', alignItems:'flex-end'}}>
                            <Input placeholder="Nova senha"  margin={"0px 0px 20px 0px"}/>
                            <Input placeholder="Repita a senha" margin={"00px 0px 20px 0px"}/>
                            
                            <Input placeholder="Celular para recuperação (00) 00000-0000" defaultValue={user.celular} width={300} onTextChange={(text)=> user.celular = text}/>
                        </div>
                            */}
                        <TouchableOpacity hoverColor={"#FF6600"} textAlign = {'center'} text={"atualizar"} fontCase="upper" onClick={()=>{
                                this.props.updateUserData(user)
                        }} />
                    </div>
                </Col>
                <div style={{position:'absolute', right:0, top:0}}>
                    <TouchableOpacity text="logout"/>
                </div>
            </Row>
        )
    }  
}



const styles = {
    title:{
        fontSize:18,
        color:'#555'
    },
    formContainer:{
        padding:20
    },
}

const mapStateToProps = state => ({
    user: state.UserReducer.user
});

const mapDispatchToProps = {
    getUserData,
    updateUserData
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
