import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/auth';
import 'firebase/storage';

  // Initialize Firebase

  const firebaseConfig = {
    apiKey: "AIzaSyD0HfewnEbHhpKl_lyIKJT_wNEzigYRV4g",
    authDomain: "uano-app.firebaseapp.com",
    databaseURL: "https://uano-app.firebaseio.com",
    projectId: "uano-app",
    storageBucket: "uano-app.appspot.com",
    messagingSenderId: "423903246579",
    appId: "1:423903246579:web:831b924d638145c2e1df86"
  };

  const firebaseLinksConfig = {
    apiKey: "AIzaSyC7xs20JWgsGgBXTNpjRSpXoZWkfxhjwj8",
    authDomain: "uano-links.firebaseapp.com",
    databaseURL: "https://uano-links.firebaseio.com",
    projectId: "uano-links",
    storageBucket: "uano-links.appspot.com",
    messagingSenderId: "32835786532",
    appId: "1:32835786532:web:a81354d564dd6876e305b1"
  };

  const firebaseInit = firebase.initializeApp(firebaseConfig);
  const firebaseDatabase = firebaseInit.database();
  const firebaseLinkDatabase = firebaseInit.database('https://uano-app-links.firebaseio.com/');
  const firebaseAuth = firebaseInit.auth();
  const firebaseStorage = firebaseInit.storage();

  // Get user key used to separate user specific Realtime Database nodes and Storage folders, available after sign in
  function getUserKey() {
    return firebaseAuth.currentUser.email.toLowerCase().replace(/[^\w\s]/gi, '')
  }

  export {firebaseDatabase, firebaseAuth, firebaseStorage, firebaseLinkDatabase, getUserKey};
