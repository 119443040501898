import moment from 'moment';
import dayPTBR from './daysPTBR';


const sectionTimes = (section) => {
    const day = section.startAt?dayPTBR(moment(section.startAt,'DD/MM/YYYY HH:mm:ss').format('dddd')):'----'
    const date = section.startAt?moment(section.startAt,'DD/MM/YYYY HH:mm:ss').format('DD/MM/YYYY'):'--/--/--'
    const initHour = section.startAt?moment(section.startAt,'DD/MM/YYYY HH:mm:ss').format('HH'):'--'
    const initMinute = section.startAt?moment(section.startAt,'DD/MM/YYYY HH:mm:ss').format('mm'):'--'
    const endHour = section.endAt?moment(section.endAt,'DD/MM/YYYY HH:mm:ss').format('HH'):'--'
    const endMinute = section.endAt?moment(section.endAt,'DD/MM/YYYY HH:mm:ss').format('mm'):'--'

    return {
        day,
        date,
        initHour,
        initMinute,
        endHour,
        endMinute,
    }
}

const eventTimes = (event) => {
    const day = event.date?dayPTBR(moment(event.date,'DD/MM/YYYY HH:mm:ss').format('dddd')):'----'
    const date = event.date?event.date:'--/--/--'
    const initHour = event.initTime?moment(event.initTime,'HH:mm').format('HH'):'--'
    const initMinute = event.initTime?moment(event.initTime,'HH:mm').format('mm'):'--'
    const endHour = event.endTime?moment(event.endTime,'HH:mm').format('HH'):'--'
    const endMinute = event.endTime?moment(event.endTime,'HH:mm').format('mm'):'--'

    return {
        day,
        date,
        initHour,
        initMinute,
        endHour,
        endMinute,
    }
}

export {sectionTimes,eventTimes};