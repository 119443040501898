import React, { Component } from 'react'
import {TransparentButton,CircleButton} from '../../components/Button'
import {connect} from 'react-redux'
import Icon from '../../components/Icon'
import {firebaseDatabase, firebaseStorage} from '../../config/FirebaseUtils'
import uuid from '../../libs/functions/uuid'
import moment from 'moment'


class PalestrandeAudioUpload extends Component {
    constructor(props){
        super(props);
        this.state = {
            audio:{
                path:'',
                name:'',
                size:0,
                type:'',
            },
            url:'',
            blob:'',
        }
    }

    componentDidMount(){
    }

    render(){
        return(
            <div style={{backgroundColor:'rgba(130,130,130,0.5)',position:'absolute', justifyContent:'center', alignItems:'center', display:'flex',height:'100vh', width:'100vw'}}>
                <div style={{backgroundColor:'white', display:'flex', flexDirection:'column', height:420, width:370, borderRadius:20, padding:20}}>
                    <div style={{width:'100%',flex:0.3, display:'flex', justifyContent:'flex-end', alignItems:'flex-start'}}>
                        <TransparentButton onClick={()=>{this.props.close()}}>
                            <Icon name={'cancelar'} style={{fontSize:30, color:'#FF6600'}}/>
                        </TransparentButton>
                    </div>
                    <div style={{display:'flex', flexDirection:'column', flex:1, justifyContent:'center', alignItems:'center'}}>
                    <input type="file" name="file" 
                        accept="audio/*"
                        style={{display:'none'}}
                        onChange={()=>{
                            let files = document.getElementsByName('file')[0].files
                            if(files[0]){
                                this.setState({
                                audio:{
                                    path:URL.createObjectURL(files[0]),
                                    name:files[0].name,
                                    size:files[0].size,
                                    type:files[0].type,
                                },
                                blob:files[0]
                            })
                        }
                       }}/>     
                        <TransparentButton 
                            style={{marginBotton:10}}
                            icon={{name:'pasta',style:{fontSize:70, color:'#FF6600'}}} 
                            label={{text:'Procurar o audio em seus arquivos',style:{fontSize:12, margin:8, color:'gray'}}}
                            onClick={()=>{document.getElementsByName('file')[0].click()}}/>
                            <p>{this.state.audio.name}</p>
                            {
                                this.state.audio.path?
                                (<audio src={this.state.audio.path} controls="controls" />):
                                (null)
                            }
                    </div>
                    <div style={{flex:0.1, width:'100%'}}>  
                        <div style={{width:'100%', height:50, display:'flex', justifyContent:'flex-end', alignItems:'flex-end'}}>
                            <CircleButton 
                            style={{backgroundColor:'#FF6600', height:35, width:35}}
                            label={{text:'OK',style:{fontWeight:'bold',color:'white',fontSize:12,marginBottom:0}}} 
                            onClick={()=>{
                                console.log(this.state.audio.path)
                                
                                const id = uuid();
                                const name = moment().format('DD-MM-YYYY-HH-mm-ss')
                                const type = this.state.audio.type.split('/')[1]

                                const uploadTask = firebaseStorage
                                    .ref('audios')
                                    .child(this.props.event.userOwner)
                                    .child(`${id}.${type}`)
                                    .put(this.state.blob)
                                    
                                uploadTask
                                .on("state_changed", 
                                snapshot=>{console.log(snapshot)},
                                error => {console.log(error);},
                                () => { 
                                    console.log("chamado",'firebase audio upload')

                                    firebaseStorage
                                    .ref('audios')
                                    .child(this.props.event.userOwner)
                                    .child(`${id}.${type}`)
                                    .getDownloadURL()
                                    .then(url => {
                                        firebaseDatabase
                                        .ref('events')
                                        .child(this.props.event.id)
                                        .child('audioMaster/0/')
                                        .update({
                                            format: type,
                                            id:id,
                                            name: name,
                                            sizeInBytes: this.state.audio.size,
                                            status: 1,
                                            storageURL: url,
                                        })
                                        .then(this.setState({url:url}))
                                    });
                                });
                            }}/>
                        </div>     
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    event : state.EventReducers.event,
});

const mapDispatchToProps = {
};
  
export default connect(mapStateToProps, mapDispatchToProps)(PalestrandeAudioUpload);