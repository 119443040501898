import {
  ENQUETES,
  SELECTED_ENQUETE,
  SELECTED_ENQUETE_EVENT,
  RESPOSTAS,
  RESPOSTAS_TOTAL,
  ENVIADO,
} from '../../../config/Types';
import { firebaseDatabase } from '../../../config/FirebaseUtils';

export const listarEnquetes = (speakerID) => {
  return (dispatch) => {
    firebaseDatabase
      .ref('enquetes')
      .child(speakerID)
      .on('value', (enquetes) => {
        enquetes = enquetes.val() ? enquetes.val() : {}
        dispatch({
          type: ENQUETES,
          payload: enquetes
        })
      })
  }
}

export const selectEnquete = (enquete) => {
  return (dispatch) => {
    dispatch({
      type: SELECTED_ENQUETE,
      payload: enquete
    })
  }
}

export const selectEnqueteEvent = (event) => {
  return (dispatch) => {
    dispatch({
      type: SELECTED_ENQUETE_EVENT,
      payload: event
    })
  }
}

export const respostasRealTime = (event, enquete) => {
  return (dispatch) => {
    firebaseDatabase
      .ref('enquetes')
      .child(event.speakerID)
      .child(enquete.id)
      .child('respostas')
      .on('value', (respostas) => {
        let total = 0
        respostas = respostas.val() ? respostas.val() : {}
        Object.values(respostas).map(resposta => {
          if (resposta.events_totals) {
            const event_totals = resposta.events_totals[event.id] ? resposta.events_totals[event.id] : 0
            resposta.total = event_totals
            total += event_totals
          } else {
            resposta.total = 0;
          }
        })

        dispatch({
          type: RESPOSTAS,
          payload: respostas
        })
        dispatch({
          type: RESPOSTAS_TOTAL,
          payload: total
        })
      })
  }
}

export const enviarEnquete = (event, enquete, enviar) => {
  return (dispatch) => {
    const firebaseShort = firebaseDatabase
      .ref('enquetes')
      .child(event.speakerID)
      .child(enquete.id)

    firebaseShort
      .update({ enviado: enviar })
      .then(callback => {
        dispatch({
          type: ENVIADO,
          payload: enviar
        })
        firebaseShort
          .child("historyEvents")
          .update({
            [event.id]: {
              id: event.id,
              name: event.name,
              date: event.date,
              speakerID: event.speakerID
            }
          })
      })
  }
}

export const pararEnquete = (event, enquete) => {
  return (dispatch) => {
    firebaseDatabase
      .ref('enquetes')
      .child(event.speakerID)
      .child(enquete.id)
      .update({ enviado: false })

    dispatch({
      type: ENVIADO,
      payload: false
    })
  }
}

export const criarEnquete = (enquete, userKey) =>{
  return (dispatch) => {
    firebaseDatabase
    .ref('enquetes')
    .child(userKey)
    .child(enquete.id)
    .update(enquete)
  }
}