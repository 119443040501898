import React, { Component } from 'react'
import {TransparentButton,CircleButton} from '../../components/Button'
import Input from '../../components/Input'
import Icon from '../../components/Icon'
import moment from 'moment'
import { PieChart, Pie, Sector, Cell,} from 'recharts';
import { connect } from 'react-redux'
import {loadAnalytics} from '../../libs/redux/actions/AnalyticsAction'

const dataColor = (index, key) => {
    const colorTextNote = '#a255aa';
    const colorPhotoNote = '#E1AA7D';
    const colorMarkNote = '#FF6600';
    //CoresTemporarias
    const colorHappy = '#FF6699';
    const colorSad = '#517f8e';
    const colorLove = '#43A047';
    const colorDeter = '#8e6a5c';
    const colorZzz = '#2979FF';
    const colorImportante = '#e53935';

    let color = '#fff'
    if(index){
        switch(index){
          case 'happy': color = colorHappy;
          break;
          case 'sad': color = colorSad;
          break;
          case 'love': color = colorLove;
          break;
          case 'determinado': color = colorDeter;
          break;
          case 'zzz': color = colorZzz;
          break;
          case 'importante': color = colorImportante;
          break;
          case 'P': color = colorPhotoNote;
          break;
          case 'M': color = colorMarkNote;
          break;
          case 'T': color = colorTextNote;
          break;
        }
    }

    if(key){
        switch(key){
            case 'happyNotesTime': color = colorHappy;
            break;
            case 'sadNotesTime': color = colorSad;
            break;
            case 'loveNotesTime': color = colorLove;
            break;
            case 'deterNotesTime': color = colorDeter;
            break;
            case 'zzzNotesTime': color = colorZzz;
            break;
            case 'importanteNotesTime': color = colorImportante;
            break;
            case 'photoNotesTime': color = colorPhotoNote;
            break;
            case 'markNotesTime': color = colorMarkNote;
            break;
            case 'textNotesTime': color = colorTextNote;
            break;
          }
    }

    return color;
  }

class Analytics extends Component {
    constructor(props){
        super(props);
        this.state = {
            analytics:{},
            data:{}
        }
    }

    componentDidMount(){
        let event = this.props.event;
        this.props.loadAnalytics(event, 5);
    }

    render(){
        const colorTextNote = '#a255aa';
        const colorPhotoNote = '#E1AA7D';
        const colorMarkNote = '#FF6600';
        const colorHappy = '#FF6699';
        const colorSad = '#517f8e';
        const colorLove = '#43A047';
        const colorDeter = '#8e6a5c';
        const colorZzz = '#2979FF';
        const colorImportante = '#e53935';
        return(
            <div style={{backgroundColor:'rgba(130,130,130,0.5)',position:'absolute', justifyContent:'center', alignItems:'center', display:'flex',height:'100vh', width:'100vw'}}>
                <div style={{backgroundColor:'white', display:'flex', flexDirection:'column', height:700, width:1000, borderRadius:20, padding:20}}>
                    <div style={{width:'100%',flex:0.1, display:'flex', justifyContent:'flex-end', alignItems:'flex-start'}}>
                        <TransparentButton onClick={()=>{this.props.close()}}>
                            <Icon name={'cancelar'} style={{fontSize:30, color:'#FF6600'}}/>
                        </TransparentButton>
                    </div>
                    {
                        this.props.analytics?(
                            <div style={{display:'flex', flex:1, flexDirection:'column', justifyContent:'flex-end'}}>
                                <div style={{flex:1, display:'flex'}}>
                                    <div style={{flex:1}}>
                                    {
                                        this.props.analytics.totals.map(info => (
                                        <p
                                            key={Math.random()}
                                            style={{
                                            color:
                                                (info.type == 'P') ? colorPhotoNote :
                                                (info.type == 'happy') ? colorHappy :
                                                (info.type == 'sad') ? colorSad :
                                                (info.type == 'love') ? colorLove :
                                                (info.type == 'determinado') ? colorDeter :
                                                (info.type == 'zzz') ? colorZzz :
                                                (info.type == 'importante') ? colorImportante :
                                                (info.type == 'T') ? colorTextNote : colorMarkNote,
                                            fontSize: 12
                                            }}>

                                            <span style={{
                                                fontWeight: '800',
                                                color:
                                                (info.type == 'P') ? colorPhotoNote :
                                                (info.type == 'happy') ? colorHappy :
                                                (info.type == 'sad') ? colorSad :
                                                (info.type == 'love') ? colorLove :
                                                (info.type == 'determinado') ? colorDeter :
                                                (info.type == 'zzz') ? colorZzz :
                                                (info.type == 'importante') ? colorImportante :
                                                (info.type == 'T') ? colorTextNote : colorMarkNote
                                                }}>
                                            {info.value}
                                            </span>
                                            {info.text}
                                        </p>
                                        ))
                                    }
                                    </div>
                                    <div style={{flex:0.5}}>
                                        <PieGrafic  analytics={this.props.analytics}/>
                                    </div>
                                </div>
                                <div style={{overflow:'auto', flex:1, alignItems:'flex-end', display:'flex'}}>
                                    <LineBarGrafic  analytics={this.props.analytics}/>
                                </div>
                            </div>
                        ):(null)
                    }
                    <div style={{flex:0.1, width:'100%'}}>
                        {/*
                            <div style={{width:'100%', height:50, display:'flex', justifyContent:'flex-end', alignItems:'flex-end'}}>
                            <CircleButton 
                            style={{backgroundColor:'#FF6600', height:35, width:35}}
                            label={{text:'OK',style:{fontWeight:'bold',color:'white',fontSize:12,marginBottom:0}}} 
                            onClick={()=>{}}/>
                            </div>
                        */}
                    </div>
                </div>
            </div>
        )
    }
}

const PieGrafic = (props) =>{

    const analytics =  props.analytics
    const RADIAN = Math.PI / 180;
    const renderCustomizedLabel = ({
      cx, cy, midAngle, innerRadius, outerRadius, percent, index,
    }) => {
       const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
      const x = cx + radius * Math.cos(-midAngle * RADIAN);
      const y = cy + radius * Math.sin(-midAngle * RADIAN);
    
      return (
        <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
          {`${(percent * 100).toFixed(0)}%`}
        </text>
      );
    };

    const data = {
        amount:[],
        key:[]
     }

     for(let x = 0; x < analytics.totals.length; x++){
         data.amount.push(analytics.totals[x].value)
         data.key.push(analytics.totals[x].type)
     }

    const pieData = data.amount
    .map((value, index) => { 
        console.log('index',index)
    return ({
        value,
        svg: {fill: dataColor(data.key[index])},
        key: `pie-${index}`,
        })
    }
    )

    return (
        <PieChart width={260} height={260}>
            <Pie
                data={pieData}
                cx={125}
                cy={125}
                labelLine={false}
                label={renderCustomizedLabel}
                innerRadius={50}
                outerRadius={130}
                dataKey="value"
                >
                {
                    pieData.map((entry, index) => <Cell key={`cell-${index}`} fill={entry.svg.fill} />)
                }
            </Pie>
        </PieChart>
    )

}

const LineBarGrafic = (props) =>{
    let analytics =  props.analytics
    return (      
        <div style={{display:'flex'}}>
            {
            analytics.notesTime.map((note,index)=>{
                return(
                    <div style={{display:'flex', flexDirection:'column',justifyContent:'center',borderRight:'1px solid #eee'}}>
                        <div style={{display:'flex', width:100,height:150,marginBottom:25,alignItems:'flex-end', justifyContent:'center', borderBottom:'1px solid #eee'}}>
                            {
                                Object.keys(analytics).map(key=>{
                                    if(key.indexOf('NotesTime')>-1){
        
                                        return (analytics[key][index].y > 0)
                                        && (
                                            <div style={{
                                                width:10,
                                                height: `${((analytics[key][index].y / analytics.totalNotes) * 100) * 2}%`,
                                                marginLeft:1,
                                                backgroundColor: dataColor(null,key)
                                            }}/>
                                        )
                                    }
                                })
                            }
                        </div>
                        <div style={{width:80, height:10, display:'flex', flexDirection:'column', justifyContent:'flex-end', alignItems:'center'}}>
                            {index === 0?<p style={{margin:0, fontSize:11, fontWeight:'bold', color:'#FF6600', text:'center'}}>Término</p>:(null)}
                            {index === analytics.notesTime.length -1?<p style={{margin:0, fontSize:11, fontWeight:'bold', color:'#FF6600'}}>Início</p>:(null)}
                            <p style={{margin:0, fontSize:12}}>{note.time}</p>
                        </div>
                    </div>
                )
                })
            }
        </div>  
    )
}

const mapStateToProps = state => ({
    analytics: state.AnalyticsReducer.analytics,
    event : state.EventReducers.event,    
});

const mapDispatchToProps = {
    loadAnalytics,
};
  
export default connect(mapStateToProps, mapDispatchToProps)(Analytics);