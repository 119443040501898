export const LOAD_SECTIONS = "load_sections";
export const LOAD_EVENTS = "load_events";
export const LOAD_PASTAS = "load_pastas";
export const LOADING = 'loading';
export const SELECTED_SECTION = "selected_section";
export const SELECTED_FOLDER = "selected_folder";
export const SELECTED_EVENT = "selected_event";
export const SORT_SECTION = "sort_section";
export const SCREENS = 'screens';
export const SET_SECTION_FOLDER = 'set_section_folder'
export const USER = 'user'
export const ADD_NOTES = 'add_notes'
export const LOAD_ANALYTICS = 'load_analytics';
export const ENQUETES = 'enquetes';
export const SELECTED_ENQUETE = 'selected_enquete';
export const SELECTED_ENQUETE_EVENT = 'selected_enquete_event';
export const RESPOSTAS  = 'respostas';
export const RESPOSTAS_TOTAL  = 'respostas_total';
export const ENVIADO  = 'enviado';
