import ReduxThunk from 'redux-thunk';
import AppReducers from './AppReducers';

import {applyMiddleware, createStore} from 'redux';
import { persistStore, persistReducer } from 'redux-persist';

import storage from 'redux-persist/lib/storage/session';

const middleware = [
    ReduxThunk
];

const persistConfig = {
    key:'root',
    storage
}

const persistedReducer = persistReducer(persistConfig,AppReducers)

const store = createStore(
    persistedReducer,
    applyMiddleware(...middleware)
);

const persistor = persistStore(store)

export {store, persistor};