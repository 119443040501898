/* eslint-disable default-case */
import React,{Component} from 'react';
import { connect } from 'react-redux'
import Icon from '../../components/Icon'
import moment from 'moment';

import AddTextNotes from '../Notas/AddTextNotes'
import AddMarkNotes from '../Notas/AddMarkNotes'
import AddPhotoNotes from '../Notas/AddPhotoNotes'
import Player from '../Audio/Player'
import './SincronizarNotas.css';
import {TransparentButton, CircleButton} from'../../components/Button';
import {delNote} from '../../libs/redux/actions/SectionActions'

class SincronizarNotas extends Component {
    constructor(props){
        super(props);
        this.x = 0
        this.undo = [];
        this.redo = [];
        this.timer = null;
        this.state = {
            currentTime:0,
            duration:0,
            originalDuration:0,
            position:40,
            mouseKeyDown:false,
            seconds:0,
            noteDisplay:[],
            section:{},
            noteModal:0,
            editNoteModal:'',
            editNoteModalShow:false,
            selectedNote:'',
            noteId:'',
            noteType:'',
            moveNote:false,
            moveFollower:false,
            x:0,
        }
    }

    componentDidMount(){
        const section = JSON.parse(sessionStorage.getItem('section'));
        //const section = this.props.section;
        this.undo.push(JSON.stringify(section.notes))
        console.log('add')
        const duration = moment(section.endAt,'DD/MM/YYYY HH:mm:ss').diff(moment(section.startAt,'DD/MM/YYYY HH:mm:ss'),'seconds')
        this.setState({section:section, notes:section.notes, duration:duration, originalDuration:duration})
        console.info('Current section:', section);
    }

    followerUpdate(){
        var follower = document.getElementById('slider-follow');
        var followerVal = document.getElementById('slider-val');
        var slider = document.getElementById('slider');

        followerVal.innerHTML = moment('00:00:00','HH:mm:ss').add(slider.value,'seconds').format('HH:mm:ss');
        follower.style.left = (slider.value * 1) + '%';
    }

    recursiveOffset(aobj){
        let currOffset = { x: 0 } 
        let newOffset = { x: 0 }    
       
        if (aobj) {
          if (aobj.scrollLeft) { 
            currOffset.x = aobj.scrollLeft;
          }
          if (aobj.offsetLeft) { 
            currOffset.x -= aobj.offsetLeft;
          }
          if (aobj.parentNode) { 
             newOffset = this.recursiveOffset(aobj.parentNode);   
          }
          currOffset.x = currOffset.x + newOffset.x;
        }
        
        return currOffset;
    }

    pointerSeekMouse(){
        const left = 34
        const offsetpos = this.recursiveOffset (document.getElementById('slider-container'));
        const x = Math.floor(((window.event.clientX+offsetpos.x)- left) / 8);
        const seconds = x - 2
        const position = 24 + (8 * x )
        const note = this.state.noteDisplay
    
        let time = 0
        
        if(seconds >= 0 && seconds <= this.state.duration){
            if(note[0]){
                time = moment(note[0].registerTime,'HH:mm:ss').diff(moment('00:00:00','HH:mm:ss'), 'seconds')
                if(time !== seconds){
                    this.setState({noteDisplay:[]})
                }
            }
            this.setState({position,seconds, currentTime:seconds})
        }
    }

    renderNotes(notes,x){
        let time = 0
        const multiNotes = []
        const {seconds, currentTime, noteDisplay} = this.state
        const styles={
            iconContainer:{
                paddingTop:50, 
                marginTop:10,
                width:10,
                cursor:'pointer',
                height:60,
                borderLeft:`1px solid ${(Math.floor(currentTime) === x || seconds ===x?'#FF6600':'gray')}`
            },
            iconColor: Math.floor(currentTime) === x || seconds ===x?'#FF6600':'gray',
        }

        return notes.map((note) => {
            time = moment(note.registerTime,'HH:mm:ss').diff(moment('00:00:00','HH:mm:ss'), 'seconds')
            if(note.id === this.state.noteId){
                note.registerTime = moment('00:00:00','HH:mm:ss').set('seconds',this.x).format('HH:mm:ss')
                if(this.state.moveNote === false){ 
                    this.setState({noteId:''})
                }
            }

            if(time <= x && time > x - 1){
                if(seconds === x || Math.floor(this.state.currentTime) === x){
                    if(noteDisplay[0]){
                        multiNotes.push(note)
                        if(JSON.stringify(noteDisplay).indexOf(note.id) === -1) 
                            this.setState({noteDisplay:multiNotes})
                    }else{
                        this.setState({noteDisplay:[note]})
                    }
                }

                switch(note.type){
                    case 'M': return (
                        <TransparentButton style={{borderLeft:`1px solid ${this.state.noteId === note.id?'':styles.iconContainer.color}`, ...styles.iconContainer}} 
                        icon={{name:"uano-bookmark",style:{color:styles.iconColor, fontSize:20, margin:0}}}
                        onDoubleClick={()=>{this.setState({noteId:note.id, noteType:note.type, moveNote:true})}}/>);
                    case 'P': return (
                        <TransparentButton style={{borderLeft:`1px solid ${this.state.noteId === note.id?'':styles.iconContainer.color}`, ...styles.iconContainer}} 
                        icon={{name:"uano-camera",style:{color:styles.iconColor, fontSize:20, margin:0}}}
                        onDoubleClick={()=>{this.setState({noteId:note.id, noteType:note.type, moveNote:true})}}/>);
                    case 'T': return (
                        <TransparentButton style={{borderLeft:`1px solid ${this.state.noteId === note.id?'':styles.iconContainer.color}`, ...styles.iconContainer}} 
                        icon={{name:"uano-abc",style:{color:styles.iconColor, fontSize:20, margin:0}}}
                        onDoubleClick={()=>{this.setState({noteId:note.id, noteType:note.type, moveNote:true})}}/>);
                }
            }
        })
    }

    notesLineBar(notes){
        const stripLines = []
        const {duration, currentTime} = this.state
        const styles = {
            stripContainer:{
                width:8, 
                marginRight:0,
                height:100, 
                textAlign:'center', 
                marginTop:100
            },
            stripBar:(x)=>{
                return({
                    width:x < duration ? 8 : 50, 
                    height:30, borderLeft:`1px solid ${currentTime > x?'#FF6600':'#ddd'}`
                })
            },
            lineBar:{
                display:'flex', 
                width:duration
            }
        }
        for(let x= 0;x<=this.state.duration; x++)
            stripLines.push(
                <div className='div-strip' 
                    style={styles.stripContainer}
                    onPointerMove={()=>{this.setState({drop:x})}}
                    onClick={()=>{
                        this.pointerSeekMouse()
                        }}>
                        <div style={styles.stripBar(x)}/>
                        {
                            notes?this.renderNotes(notes,x):(null)
                        }
                </div>
            )

        return( <div style={styles.lineBar}>{stripLines.map(item=>item)}</div>)
    }

    displayNote(){
        const {noteDisplay} = this.state;
        const styles = {
            text:{
                color:'white',
            },
            markContainer:{
                display:'flex',
                flexDirection:'column',
                justifyContent:'center',
                alignItems:'center',
                width:200,
                height:200
            },
        }
        if(noteDisplay[0]){
            const {
                label_color, 
                title, 
                text, 
                firebasePhotoURL, 
                firebaseThumbURL, 
                type
            } = noteDisplay[0]

            switch(type){
            case "M": return (
                <div style={styles.markContainer}>
                    <Icon name={"uano-bookmark"} style={{fontSize:30,color:label_color}}/>
                    <p style={styles.text}>{title}</p>
                </div>
            );
            case "T": return (
                    <p style={styles.text}>{text}</p>
            );  
            case "P":
                let imagePath = '';
                if(firebaseThumbURL) imagePath = firebaseThumbURL
                else imagePath = firebasePhotoURL

                return (
                    <img onClick={()=>{this.setState({photoURL:firebasePhotoURL,photoModal:true,})}}
                    style={{cursor:'pointer'}} src={imagePath} alt="" height="250"/>
                );
            }
        }
    }

    close(){
        this.setState({noteModal:0, editNoteModalShow:false})
    }

    noteModal(){
        const {noteModal} =this.state;
        const {section, seconds} = this.state;
        const styles = {
            container:{
                position:'absolute',
                width:'100%', 
                height:'100%', 
                display:'flex', 
                flexDirection:'column', 
                justifyContent:'center', 
                alignItems:'center'
            }
        }
        
        switch(noteModal){
            case 1 : return (
                <div style={styles.container}>                    
                    <AddTextNotes currentTime={seconds} section={section} close={()=>this.close()}/>
                </div>);
            case 2 : return (
                <div style={styles.container}>
                    <AddPhotoNotes currentTime={seconds} section={section} close={()=>this.close()}/>
                </div>);
            case 3 : return (
                <div style={styles.container}>
                    <AddMarkNotes currentTime={seconds} section={section} close={()=>this.close()}/>
                </div>
                );
            default:return null;
        }
    }

    editNoteModal(){
        if(this.state.editNoteModalShow){
            const {section, seconds,noteDisplay} = this.state;
            const styles = {
                container:{
                    position:'absolute',
                    width:'100%', 
                    height:'100%', 
                    display:'flex', 
                    flexDirection:'column', 
                    justifyContent:'center', 
                    alignItems:'center'
                }
            }

            switch(noteDisplay[0].type){
                case "T": return (
                    <div style={styles.container}>                    
                        <AddTextNotes currentTime={seconds} section={section} 
                        note={noteDisplay[0]}
                        close={()=>this.close()}/>
                    </div>);
                case "P" : return (
                    <div style={styles.container}>
                        <AddPhotoNotes currentTime={seconds} section={section}
                        note={noteDisplay[0]} 
                        close={()=>this.close()}/>
                        </div>);
                case "M" : return (
                    <div style={styles.container}>
                        <AddMarkNotes currentTime={seconds} section={section}
                        note={noteDisplay[0]} 
                        close={()=>this.close()}/>
                    </div>
                    );
                default:return null;
            }
        }
    }

    excluirNota = (note) =>{
        //const {user} = this.props
        this.props.delNote(note,this.state.section)
        let newSection = this.state.section
        newSection.notes = this.state.section.notes.filter(item => item.id !== note.id)
        sessionStorage.setItem('section',JSON.stringify(newSection))
        this.setState({section:newSection, notes:newSection.notes})
    }

    componentDidUpdate(){
        if(this.state.moveNote)
        document.body.style.cursor ='pointer'
        else document.body.style.cursor = 'default'
    }

    render(){
        const {notes, section, seconds, position} = this.state;
        const folowerWidth = '100%'
        const sliderWidth =  '100%'
        return(
            <div style={styles.mainContainer}
            onClick={()=>{
                const left = 34
                const offsetpos = this.recursiveOffset (document.getElementById('slider-container'));
                const x = Math.floor(((window.event.clientX+offsetpos.x)- left) / 8);
                const seconds = x - 2

                if(this.state.noteId){ 
                    this.undo.push(JSON.stringify(notes))
                    //let note = notes.filter(note => note.id === this.state.noteId)[0]
                    //note.registerTime = moment('00:00:00','HH:mm:ss').add('seconds',seconds).format('HH:mm:ss')

                    this.x = seconds
                    document.getElementById('note').style.left = `${-1000}px`
                    document.getElementById('note').style.top = `${-1000}px`
                    this.setState({moveNote:false, moveFollower:false})
                }
            }}
            
            onPointerMove={()=>{
                if(this.state.noteId !== '' && this.state.moveNote === true){
                    document.getElementById('note').style.position = 'absolute'
                    document.getElementById('note').style.cursor = 'pointer'
                    document.getElementById('note').style.left = `${2+window.event.clientX}px`
                    document.getElementById('note').style.top = `${window.event.clientY - 15}px`
                }
                    
                //if(this.state.moveFollower === true)
                    //this.pointerSeekMouse()
                
            }}>

                <div style={styles.displayNoteMainContainer}>
                    <div style={{flex:1, alignSelf:'flex-start'}}>
                        <TransparentButton 
                            style={{display:'flex', alignItems:'center', cursor:'pointer'}} 
                            icon={{name:'left-arrow',style:{fontSize:20,color:'#FF6600'}}}
                            label={{text:"Sair da Edição",style:{margin:'0 0 0 10px', fontSize:12, color:'#FF6600'}}}
                            onClick={()=>{this.props.history.push('/notes_list')}}
                        />
                    </div>
                    <div style={styles.displayNoteConatiner}>
                        <div style={styles.displayNote}>
                            {this.displayNote()}
                        </div>
                        <div style={styles.displayNoteButtonsConatiner}>
                            {
                                this.state.noteDisplay[0]?
                                (<div>                                    
                                    <TransparentButton onClick={()=>{this.setState({editNoteModalShow:true})}} style={styles.displayNoteButton} icon={{name:'edit', style:{...styles.displayNoteButtonIcon}}}/>
                                    <TransparentButton onClick={()=>{this.excluirNota(this.state.noteDisplay[0])}} style={styles.displayNoteButton} icon={{name:'excluir', style:{...styles.displayNoteButtonIcon}}}/>
                                    <TransparentButton onClick={()=>{console.log(this.state.noteDisplay[0])}} style={styles.displayNoteButton} icon={{name:'share', style:{...styles.displayNoteButtonIcon}}}/>
                                    <TransparentButton onClick={()=>{console.log(this.state.noteDisplay[0])}} style={styles.displayNoteButton} icon={{name:'link-externo', style:{...styles.displayNoteButtonIcon}}}/>
                                </div>):(null)
                            }
                        </div>
                    </div>
                    <div style={{flex:1}}/>
                </div>
                <div style={styles.linebarMainContainer}>                    
                    <div id='slider-container' style={{width:sliderWidth,...styles.sliderContainer}}>
                        <div id='slider-follow-container' style={{width:folowerWidth}}>
                            <div id='slider-background' style={{width:sliderWidth, ...styles.sliderBackground}} 
                                onClick={()=>{this.pointerSeekMouse()}}>
                                <div id='slider-follow' style={{left:position-41,...styles.sliderFollow}} 
                                onClick={()=>{this.setState({moveFollower:!this.state.moveFollower})}}>
                                    <div id='slider-val-container'>
                                        <center>
                                            <span id='slider-val' style={{color:'white'}}>{moment('00:00:00','HH:mm:ss').set('seconds',this.state.seconds).format('HH:mm:ss')}</span>
                                            <div id='slider-triangle' onClick={()=>{this.setState({mouseKeyDown:!this.state.mouseKeyDown})}}/>
                                            <div id='slider-pointer' onClick={()=>{this.setState({mouseKeyDown:!this.state.mouseKeyDown})}}/>
                                        </center>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={{margin:0}}>
                            {this.notesLineBar(this.state.notes)}
                        </div>
                    </div>
                </div>
                <div style={styles.bottomBar}>
                    <div style={{flex:1}}></div>
                    <div style={styles.playerContainer}>
                        <div>
                            <p style={{color:'#FF6600'}}>Acrescentar:</p>
                        </div>
                        <div style={styles.noteButtonsContainer}>
                            <CircleButton onClick={()=>{this.setState({noteModal:1})}} style={styles.noteButtonStyle} icon={{name:'uano-abc', style:{...styles.noteButtonIcon}}}/>
                            <CircleButton onClick={()=>{this.setState({noteModal:2})}} style={styles.noteButtonStyle} icon={{name:'camera', style:{...styles.noteButtonIcon}}}/>
                            <CircleButton onClick={()=>{this.setState({noteModal:3})}} style={styles.noteButtonStyle} icon={{name:'uano-bookmark', style:{...styles.noteButtonIcon}}}/>
                        </div>
                        <div style={{display:'flex', justifyContent:'center'}}>
                            {
                                section.audios?
                                (<Player audios={section.audios} time={seconds} simple={true}
                                    audioCurrentTime={(time)=>{this.setState({currentTime:time})}}/>):(null)
                            }
                        </div>
                    </div>
                    <div style={{flex:1}}>
                        <div style={{display:'flex', backgroundColor:'', position:'absolute', right:10}}>
                        </div>
                    </div>
                </div>
                {this.noteModal()}
                {this.editNoteModal()}
                <div id="note" style={{height:75, width:1, borderLeft:`${this.state.noteId?1:0}px solid #FF6600`, display:'flex', flexDirection:'column', justifyContent:'flex-end'}}>
                    <Icon name={
                        this.state.noteType === "M"?"uano-bookmark":
                        this.state.noteType==="P"?'camera':
                        this.state.noteType ==="T"?'uano-abc':''} style={{fontSize:30, color:'#FF6600'}}/>
                </div>
            </div>
        )
    }  
}

const styles = {
    mainContainer:{
        display:'flex', 
        backgroundColor:'#2E2D33', 
        flexDirection:'column', 
        position:'absolute', 
        bottom:0, top:0, left:0, right:0
    },
    displayNoteMainContainer:{
        minHeight:275,
        flex:1.5,
        display:'flex', 
        justifyContent:'center', 
        alignItems:'center'
    },
    displayNoteConatiner:{
        backgroundColor:'', 
        display:'flex', 
        justifyContent:'center', 
        alignItems:'center', 
        width:500, 
        height:250
    },
    displayNote:{
        flex:1, 
        display:'flex', 
        justifyContent:'center', 
        alignItems:'center'
    },
    displayNoteButtonsConatiner:{
        flex:0.1,
        display:'flex',
        flexDirection:'column', 
        justifyContent:'space-around', 
        height:'100%'
    },
    displayNoteButton:{
        width:40, 
        height:40, 
        display:'flex', 
        justifyContent:'center', 
        alignItems:'center'
    },
    displayNoteButtonIcon:{
        fontSize:20, 
        color:'#FF6600'
    },
    linebarMainContainer:{
        flex:1, 
        minHeight:170,
        display:'flex', 
        flexDirection:'column'
    },
    sliderContainer:{
        flex:1,
        margin:0, 
        marginTop:-80, 
        paddingLeft:50,
        overflow:'auto'
    },
    sliderBackground:{
        height:50, 
        top:86, 
        position:'absolute',
    },
    sliderFollow:{
        position:'absolute', 
    },
    bottomBar:{
        flex:2,
        display:'flex'
    },
    playerContainer:{
        flex:1, 
        display:'flex', 
        flexDirection:'column', 
        justifyContent:'center', 
        alignItems:'center'
    },
    noteButtonsContainer:{
        display:'flex', 
        width:'100%', 
        justifyContent:'space-around', 
        paddingLeft:20, 
        paddingRight:20
    },
    noteButtonStyle:{
        backgroundColor:'#ff6600', 
        width:40, 
        height:40, 
        borderRadius:100, 
        display:'flex', 
        justifyContent:'center', 
        alignItems:'center'
    },
    noteButtonIcon:{
        fontSize:25, 
        color:'white'
    }
}

const mapStateToProps = state => ({
    section: state.SectionReducers.section,
    user: state.UserReducer.user,
  });
  
  const mapDispatchToProps = {
    delNote,
  };
  
export default connect(mapStateToProps, mapDispatchToProps)(SincronizarNotas);