import React,{Component} from 'react';
import { connect } from 'react-redux'
import { Row, Col } from 'react-bootstrap';

import Icon from '../../components/Icon'
import {TransparentButton} from '../../components/Button'
import {selectFolder} from '../../libs/redux/actions/SectionActions'
import SectionList from '../Sessoes/SectionList';
import {searchFolderText} from '../../libs/functions/searchText';
import {sortFolders} from '../../libs/functions/sortData';

class FoldersList extends Component {
    constructor(props){
        super(props);
        this.state = {
            pastas:[],
            selectedFolder:undefined
        }
    }

    loadPastasData(pastas){
        if(pastas){
            pastas = sortFolders(pastas, this.props.sortType);
            pastas = searchFolderText(pastas, this.props.searchText)
            const numCols = 4 
            let items = []
            let index = 0

            for(let row = 0; row < (pastas.length/numCols); row++){
            let itemCol = []
            for(let col= 0; col < numCols; col++){

                if(pastas[index]){
                    itemCol.push(
                        <Col md={6} lg={3} style={{...styles.folderContainer}}>
                            <TransparentButton id={`pasta-${index}`}
                            icon={{name:'pasta',style:{fontSize:65,color:pastas[index].color}}}
                            label={{text:pastas[index].name,style:{fontSize:12, textAlign:'center'}}}
                            onClick={ event => this.selectFolder(event, pastas)}/>
                        </Col>
                    )
                    }else{
                        itemCol.push(
                            <Col md={6} lg={3} style={{padding:10}}>
                                <Icon name="pasta" size={65} style={{fontSize:65,color:'rgba(0,0,0,0.0)'}}/>
                            </Col>
                        )}
                    index++
                }
                let itemRow = <Row>{itemCol.map(item=>item)}</Row>;            
                items.push(itemRow)
            }
            index = 0
            return items.map((item)=>item)
        }
    }

    selectedFolder(){
        const {selectedFolder} = this.state;
        const {pastas} = this.props;

        if(selectedFolder)
            return (<SectionList folderId={selectedFolder.id} folder={selectedFolder}/>)
        else
            return this.loadPastasData(pastas);
    }

    selectFolder(event, pastas){
        const {selectFolder, onClick} = this.props
        selectFolder(pastas[event.currentTarget.id.split('-')[1]])
       
        if(onClick)
        onClick(pastas[event.currentTarget.id.split('-')[1]])

        this.setState({selectedFolder:pastas[event.currentTarget.id.split('-')[1]]})
    
    }

    render(){
        return(
            <div style={styles.mainContainer}>
                <div style={{overflowY:'auto', overflowX:'hidden', width:'100%'}}>
                    {this.selectedFolder()}
                </div>
            </div>
        )
    }  
}

const styles = {
    mainContainer:{
        marginTop:50,
        height:'100%',
    },
    folderContainer:{
        display:'flex', 
        flexDirection:'column', 
        alignItems:'center', 
        padding:10,
    },
}

const mapStateToProps = state => ({
    pastas : state.SectionReducers.pastas
  });
  
  const mapDispatchToProps = {
   selectFolder
  };
  
export default connect(mapStateToProps, mapDispatchToProps)(FoldersList);