import moment from 'moment';

const sortSections = (sections, type) => {    
    /*
    types:
        1 e Default - Ordenar por Data (a partir da ultima adicionada);
        2 - Ordenar por data (a partir da primeira adcicionada);
        3 - Ordenar por Nome (a partir da letra A);
        4 - Ordenar por Nome (a partir da letra Z);
    */

    let sortedSections;
    sections = sections.filter(section => section);

    switch(type){
        case 1 : 
            sortedSections = sections.sort((a,b)=>{
                const a_date = moment(a.startAt,'DD/MM/YYYY HH:mm:ss').format('X');
                const b_date = moment(b.startAt,'DD/MM/YYYY HH:mm:ss').format('X');
                return  b_date - a_date;
            });break;

        case 2 : 
            sortedSections = sections.sort((a,b)=>{
                const a_date = moment(a.startAt,'DD/MM/YYYY HH:mm:ss').format('X');
                const b_date = moment(b.startAt,'DD/MM/YYYY HH:mm:ss').format('X');
                return  a_date - b_date;
            });break;
        case 3 : 
            sortedSections = sections.sort((a,b)=>{
                return  a.name.toLowerCase().localeCompare(b.name.toLowerCase());
            });break;
        case 4 : 
            sortedSections = sections.sort((a,b)=>{
                return  b.name.toLowerCase().localeCompare(a.name.toLowerCase())
            });break;
        default : 
            sortedSections = sections.sort((a,b)=>{
                const a_date = moment(a.startAt,'DD/MM/YYYY HH:mm:ss').format('X');
                const b_date = moment(b.startAt,'DD/MM/YYYY HH:mm:ss').format('X');
            return  b_date - a_date;
        })
    }
    return sortedSections;
};
const sortFolders = (folders, type) => {    

    let sortedSections;
    folders = folders.filter(folder => folder);

    switch(type){
        case 1 : 
            sortedSections = folders.sort((a,b)=>{
                const a_date = moment(a.createdAt,'DD/MM/YYYY HH:mm:ss').format('X');
                const b_date = moment(b.createdAt,'DD/MM/YYYY HH:mm:ss').format('X');
                return  b_date - a_date;
            });break;

        case 2 : 
            sortedSections = folders.sort((a,b)=>{
                const a_date = moment(a.createdAt,'DD/MM/YYYY HH:mm:ss').format('X');
                const b_date = moment(b.createdAt,'DD/MM/YYYY HH:mm:ss').format('X');
                return  a_date - b_date;
            });break;
        case 3 : 
            sortedSections = folders.sort((a,b)=>{
                return  a.name.toLowerCase().localeCompare(b.name.toLowerCase());
            });break;
        case 4 : 
            sortedSections = folders.sort((a,b)=>{
                return  b.name.toLowerCase().localeCompare(a.name.toLowerCase())
            });break;
        default : 
            sortedSections = folders.sort((a,b)=>{
                const a_date = moment(a.createdAt,'DD/MM/YYYY HH:mm:ss').format('X');
                const b_date = moment(b.createdAt,'DD/MM/YYYY HH:mm:ss').format('X');
            return  b_date - a_date;
        })
    }
    return sortedSections;
};

export {sortSections, sortFolders};