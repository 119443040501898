import {
  SCREENS,
  USER
} from '../../../config/Types'

import {firebaseDatabase} from '../../../config/FirebaseUtils';

export const selectScreen = (screen) =>{
    return (dispatch) =>{
      dispatch({
        type:SCREENS,
        payload:screen
      })
    }
  }
  
  export const getUserData = (key) =>{
    console.log("chamado",'load user data')

    return (dispatch) =>{
      firebaseDatabase.ref(`/users/${key}`).once("value").then(
        (snapshot)=>{
            sessionStorage.setItem('user',JSON.stringify(snapshot.val()))
            dispatch({
              type:USER,
              payload:snapshot.val()
            })
        }
    )
  }
}

  export const updateUserData = (user) =>{
    console.log("chamado",'update user data')

    return (dispatch) =>{
      firebaseDatabase.ref(`/users/${user.key}`).update(user)
      .then(()=>{
        sessionStorage.setItem('user',JSON.stringify(user))        
        dispatch({
          type:USER,
          payload:user
        })
        console.log({message:'dados atualizados'
      })})
  }
}

  