import {
    LOAD_EVENTS,
    SELECTED_EVENT,
    ENQUETES
} from '../../../config/Types';
import {firebaseDatabase} from '../../../config/FirebaseUtils';

export const loadEvents = (key) =>{
    console.log("chamado",'load event')

    return (dispatch) =>{
        firebaseDatabase.ref(`/events/`).once("value").then(
            (snapshot)=>{
                const snap = snapshot.val();
                const events = Object.values(snap).filter(event => event.userOwner === key)
                dispatch({
                    type:LOAD_EVENTS,
                    payload:events
                })
            }
        )
    }
}

export const selectedEvent = (event) =>{
    return (dispatch) =>{
        dispatch({
            type:SELECTED_EVENT,
            payload:event
        })
    }
}
