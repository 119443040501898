import React,{Component} from 'react';
import Icon from './Icon';

class SquareButton extends Component {
    render(){
        const {color, text, icon, size, textStyle, radius, onClick, shadow} = this.props;

        const containerStyle = {
            display:'flex',
            flexDirection:'column',
            backgroundColor:color?color:'#888',
            width:size?size:100,
            height:size?size:100,
            borderRadius:radius?radius:10,
            justifyContent:'center',
            alignItems:'center',
            textAlign:'center',
            boxShadow:shadow === false? '':'1px 1px 5px rgba(0,0,0,0.2), 3px 3px 5px rgba(0,0,0,0.25)',
            cursor:'pointer',
        };
        
        return(
            <button style={containerStyle} onClick={()=>{if(onClick) return onClick()}}>
                <div style={{marginTop:10}}>
                    {icon?<Icon name={icon.name} color={icon.color} size={icon.size}/>:null}
                </div>
                <div>
                    <p style={textStyle?textStyle:{}}>{text}</p>
                </div>
            </button>
        )
    }
}

const Button = (props) => {
    const {style, label,icon} = props

    return(
        <button {...props} style={{...style}}>
            {icon?<Icon name={icon.name} style={{...icon.style}}/>:null}
            {label?(<p style={label.style}>{label.text}</p>):null}
            {props.children}
        </button>
    );
}

const TransparentButton = (props) => {
    const {style, label,icon} = props
    return(
        <button {...props} style={{...style, backgroundColor:'rgba(0,0,0,0.0)'}}>
            {icon?<Icon name={icon.name} style={{...icon.style}}/>:null}
            {label?(<p style={label.style}>{label.text}</p>):null}
            {props.children}
        </button>
    );
}

const CircleButton = (props) => {
    const {style, label,icon} = props
    return(
        <button {...props} style={{...style,borderRadius:100}}>
            {icon?<Icon name={icon.name} style={{...icon.style}}/>:null}
            {label?(<p style={label.style}>{label.text}</p>):null}
            {props.children}
        </button>
    );
}

export {SquareButton, Button, TransparentButton, CircleButton}