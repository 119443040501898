import {firebaseLinkDatabase} from '../../config/FirebaseUtils';
import axios from 'axios';

const createFirebaseLink = async (section) => {
    return new Promise((resolve, reject) => {
        firebaseLinkDatabase
        .ref('links')
        .push({
            audios:section.audios,
            endAt:section.endAt,
            startAt:section.startAt,
            name:section.name,
            notes:section.notes
        })
        .then(({key})=>{
            key = key.replace('-','')
            generateDeepLink(key)
            .then(response=>resolve(response.url))
            .catch(err=>reject(err))
        })
    })
}

const generateDeepLink = async (key) => {
    return new Promise ((resolve, reject)=>{
        const url = "https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyD0HfewnEbHhpKl_lyIKJT_wNEzigYRV4g";
        const domain = "https://uanoapp.page.link";
        const link = `https://uanoapp/?${key}`;
        const packageName = "br.com.uano";
    
        axios({
            method: 'post',
            url,
            data: {
                "dynamicLinkInfo": {
                    "domainUriPrefix": domain,
                    "link":link,
                    "androidInfo": {"androidPackageName": packageName},
                    "iosInfo": {"iosBundleId": packageName}
                },
                "suffix": {"option": "SHORT"}
            },
            headers: {"Content-Type": "application/json"}
        })
        .then(dynamic_link => {
            updateFirebaseLink(dynamic_link.data,key)
            resolve(
                {
                    dynamic_link,
                    url:`https://uano-lite.000webhostapp.com/?${key}`
                }
            )
        })
        .catch(err=>reject(err))
    })
}

const updateFirebaseLink = async (link, key) => {
        firebaseLinkDatabase
        .ref('links')
        .child(`-${key}`)
        .update({'deepLink':link.shortLink})
}

export default createFirebaseLink