import React, { Component } from 'react'
import {TransparentButton,CircleButton} from '../../components/Button'
import {connect} from 'react-redux'
import Icon from '../../components/Icon'
import {selectEnquete, selectEnqueteEvent} from '../../libs/redux/actions/EnquetesActions'
import {Link} from 'react-router-dom'
class ListarEnquetes extends Component {
    constructor(props){
        super(props);
        this.state = {
            
        }
    }

    render(){
        return(
            <div style={{backgroundColor:'rgba(130,130,130,0.5)',position:'absolute', justifyContent:'center', alignItems:'center', display:'flex',height:'100vh', width:'100vw'}}>
                <div style={{backgroundColor:'white', display:'flex', flexDirection:'column', height:520, width:370, borderRadius:20, padding:20}}>
                    <div style={{width:'100%',flex:0.2, display:'flex', justifyContent:'flex-end', alignItems:'flex-start'}}>
                        <p style={{textAlign:'center', flex:1, marginLeft:25, fontWeight:'bold'}}>Enquetes</p>
                        <TransparentButton onClick={()=>{this.props.close()}}>
                            <Icon name={'cancelar'} style={{fontSize:30, color:'#FF6600'}}/>
                        </TransparentButton>
                    </div>
                    <div style={{display:'flex', flexDirection:'column', flex:1, overflow:'auto'}}>
                        <Enquetes enquetes={this.props.enquetes} selectEnquete={this.props.selectEnquete} event={this.props.event} selectEnqueteEvent={this.props.selectEnqueteEvent}/>
                    </div>
                </div>
            </div>
        )
    }
}

const Enquetes = (props) => {
    const {enquetes,selectEnquete, selectEnqueteEvent, event} = props;
    //const enquetes = {}
    const enquetesLength = Object.keys(enquetes).length;
    if(enquetesLength < 1){
        return <p>Nenhuma enquete criada.</p>
    }else{
        return (
            Object.values(enquetes)
            .map(enquete => 
                <Link to='/visualizar_enquete'>                
                    <TransparentButton 
                    onClick={()=>{
                        selectEnqueteEvent(event)
                        selectEnquete(enquete)
                        }} style={{margin:5, borderBottom:'1px solid #eee', textAlign:'left'}}>
                        <p style={{fontSize:14, marginBottom:5}}>{enquete.name}</p>
                        <p style={{fontSize:12, marginBottom:5}}>{enquete.date}</p>
                    </TransparentButton>
                </Link>
            )
        )
    }
}

const mapStateToProps = state => ({
    event : state.EventReducers.event,
    enquetes : state.EnquetesReducer.enquetes,
});

const mapDispatchToProps = {
    selectEnquete,
    selectEnqueteEvent
};
  
export default connect(mapStateToProps, mapDispatchToProps)(ListarEnquetes);