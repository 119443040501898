import {
    ENQUETES,
    SELECTED_ENQUETE,
    SELECTED_ENQUETE_EVENT,
    RESPOSTAS,
    RESPOSTAS_TOTAL,
    ENVIADO,
} from '../../../config/Types'

const INITIAL_STATE = {
    enquetes:{},
    selectedEnquete:{},
    selectedEnqueteEvent:{},
    respostas:{},
    respostasTotal:0,
    enviado:false,
}

export default (state = INITIAL_STATE, action) => {
    switch(action.type){
        case ENQUETES: return {...state, enquetes: action.payload};
        case SELECTED_ENQUETE: return {...state, selectedEnquete: action.payload};
        case SELECTED_ENQUETE_EVENT: return {...state, selectedEnqueteEvent: action.payload};
        case RESPOSTAS: return {...state, respostas: action.payload};
        case RESPOSTAS_TOTAL: return {...state, respostasTotal: action.payload};
        case ENVIADO: return {...state, enviado: action.payload};
        default: return state;
    }
}