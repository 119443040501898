import React,{Component} from 'react';

class TouchableOpacity extends Component {
    constructor(props){
        super(props);
        this.state = {
            color:'',
        }
    }

    componentDidMount(){}

    effect(){
        let x = 0.2
             this.refs['button'].style.opacity = x
             var interval = setInterval(()=>{
                x++
                this.refs['button'].style.opacity = x
                if( this.refs['button'].style.opacity >= 1){
                    clearInterval(interval)
                    this.refs['button'].style.opacity = 1
                }
            },300)
    }

    formatText(text, fontCase){
        if(text){
            switch(fontCase){
                case 'upper':return text.toUpperCase();
                case 'lower':return text.toLowerCase();
                default: return text;
            }
        }else{
            return ''
        }
    }

    hover(type){
        const {color, hoverColor} = this.props;
        if(hoverColor){
            if(type === "in"){
                this.refs['button'].style.backgroundColor = hoverColor
            }else{
                this.refs['button'].style.backgroundColor = color?color:"#999";
            }
        }
    }

    render(){
        const {color, text, fontCase, hoverColor, textAlign, onClick} = this.props;
        const styles = {
            button:{
                backgroundColor:color?color:'#999',
                display:'flex',
                borderRadius:7,
                fontSize:12,
                padding:'8px 20px 8px 20px',
                jusfyContent:'center',
                alignItems:'center',
                color:'white',
                fontWeight:'700',
                marginTop:20,
                cursor:'pointer',
                justifyContent:textAlign?textAlign:'center',
                transition:'opacity 300ms ease-in-out'
            }
        }
        return(
            <div ref="button" style={styles.button} 
            onClick={()=>{
                this.effect()
                onClick()
            }} 
            onMouseEnter={()=>{this.hover("in")}} 
            onMouseLeave={()=>{this.hover()}}>
                <p style={{margin:0}}>{this.formatText(text, fontCase)}</p>
            </div>
        )
    }  
}

export default TouchableOpacity;
