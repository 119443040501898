import React,{Component} from 'react';
import { connect } from 'react-redux'
import Icon from '../../components/Icon'
import {selectedEvent} from '../../libs/redux/actions/EventActions'
import {eventTimes} from '../../libs/functions/sectionTimes'
import {Link} from 'react-router-dom'
import './SectionList.css';
import '../../icons/style.css'
import { TransparentButton } from '../../components/Button';


class EventList extends Component {
    constructor(props){
        super(props);
        this.state = {
            events:[],
            pastas:[]
        }
    }

    loadEventData(data){
        if(data){
          return data.map((event) => {
            const {day,date,initHour, initMinute, endHour, endMinute} = eventTimes(event)
              return (
                <Link to={'/selected_event'} style={{textDecoration:'none', color:'#333', marginBottom:20}} key={event.id}>
                  <TransparentButton style={{textAlign:'left'}}
                  onClick={()=>{this.props.selectedEvent(event)}}>
                    <div style={styles.row}>
                        <div style={styles.iconContainer}>
                            <div style={styles.icon}>
                                <Icon name="play" style={{color:'white', fontSize:15}}/>
                            </div>
                        </div>
                        <div style={styles.textContainer}>
                            <p style={{...styles.text, color:'#FF6600'}}>{day} - {date} - {initHour}h{initMinute} a {endHour}h{endMinute}</p>
                            <p style={styles.title}>{event.name}</p>
                            <p style={styles.text}>{event.address}</p>
                        </div>
                    </div>
                  </TransparentButton>
                </Link>
              );
          })
        }else{
            return <div/>
        }
    }

    render(){
        const {events} = this.props;
        return(
            <div>
                <div className="container">
                    <p id="ultimasSessoes">Ultimos Eventos</p>
                </div>
                <div style={{overflow:'auto', display:'flex', flexDirection:'column'}}>
                    {this.loadEventData(events)}
                </div>
            </div>
        )
    }  
}

const styles = {
    row:{
        margin:15,
        display:'flex',
        flexDirection:'row',
        alignItems:'center',
        cursor:'pointer'
    },
    iconContainer:{
        minWidth:50
    },
    icon:{
        backgroundColor:'#FF6600',
        width:30,
        height:30,
        borderRadius:100,
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        paddingLeft:5
    },
    text:{
        fontSize:10,
        margin:2,
        color:'#777'
    },
    title:{
        fontSize:16,
        fontWeight:'bold',
        color:'#555',
        margin:2,
    }
}

const mapStateToProps = state => ({
    events : state.EventReducers.events,
    user: state.UserReducer.user,
  });
  
const mapDispatchToProps = {
    selectedEvent,
};

export default connect(mapStateToProps, mapDispatchToProps)(EventList);