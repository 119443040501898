import React,{Component} from 'react';

import generateUUID from '../../libs/functions/uuid'
import moment from 'moment'
import Icon from '../../components/Icon'
import {connect} from 'react-redux'
import {addNote, editNote} from '../../libs/redux/actions/SectionActions'
import {TransparentButton, CircleButton} from '../../components/Button'

 class AddNotesFooter extends Component{
    constructor(props){
        super(props)
        this.state = {
            time:'00:00:00',
            duration:'00:00:00',
            firstTime:'00:00:00',
            firstDuration:'00:00:00',
            circleIndex:0
        }
    }


    componentDidMount(){
        setTimeout(()=>{
            const {section,currentTime} = this.props;
            const time = moment(section.startAt, 'DD/MM/YYYY HH:mm:ss').add('seconds',currentTime).format('HH:mm:ss')
            const duration = moment('00:00:00', 'HH:mm:ss').add('seconds',currentTime).format('HH:mm:ss')
            this.setState({
                firstTime:time,
                time:time,
                firstDuration:duration,
                duration:duration
            })
        },500)

    }

    setTime(seconds){
        let {firstTime, firstDuration} = this.state;
        let newTime = moment(firstTime,'HH:mm:ss')
        let newDuration = moment(firstDuration,'HH:mm:ss')

        if((seconds === null) || (seconds < 0 && newDuration.seconds() < (seconds * -1))){
            this.setState({
                time:firstTime,
                duration: firstDuration
            })
        }else{
            this.setState({
                time:newTime.set('second',(newTime.seconds() + seconds)).format('HH:mm:ss'),
                duration:newDuration.set('second',(newDuration.seconds() + seconds)).format('HH:mm:ss'),
            })
        }
    }

    AddNoteContainer = () => {
        return  (
        <div style={styles.buttonsContainer}>
            <TransparentButton style={{...styles.roundButton,width:150, flexDirection:'row'}} onClick={()=>{
                this.setTime(5)
                this.setState({circleIndex:1})
            }}>
                <p style={{color:'gray',fontSize:12,marginBottom:0}}>Inserir ANTES da nota atual</p>
                <Icon name="left-arrow" style={{fontSize:22, color:'#FF6600'}}/>
                <div style={{border:'1px solid gray', minWidth:25, minHeight:25, display:'flex', alignItems:'center', justifyContent:'center', borderRadius:100}}>
                    <div style={{backgroundColor:this.state.circleIndex===1?'#FF6600':'', width:17, height:17, borderRadius:100}}/>
                </div>
            </TransparentButton>

            <TransparentButton style={{...styles.roundButton,width:150, flexDirection:'row'}} onClick={()=>{
                this.setTime(5)
                this.setState({circleIndex:2})
            }}>
                <div style={{border:'1px solid gray', minWidth:25, minHeight:25, display:'flex', alignItems:'center', justifyContent:'center', borderRadius:100}}>
                    <div style={{backgroundColor:this.state.circleIndex===2?'#FF6600':'', width:17, height:17, borderRadius:100}}/>
                </div>
                <Icon name="right-arrow" style={{fontSize:22, color:'#FF6600'}}/>
                <p style={{color:'gray',fontSize:12,marginBottom:0}}>Inserir DEPOIS da nota atual</p>
            </TransparentButton>
        </div>)
    }

    render(){
        const {time, duration} =  this.state;
        return(
            <div style={{display:'flex'}}>
                <this.AddNoteContainer/>
                <div style={styles.submitContainer}>
                    <CircleButton 
                    style={styles.roundButton}
                    label={{text:'OK',style:{fontWeight:'bold',color:'white',fontSize:12,marginBottom:0}}}
                    onClick={()=>{
                        const {section, close, imagePath, type, text, color,note, user} = this.props;

                        let newNote = {
                            id: generateUUID(),
                            registerHour: time?time:'',
                            registerTime: duration?duration:'',
                            status: 1,
                            text: text?text:'',
                            title: text?text:'',
                            type: type?type:'',
                            platform:'web',
                            label_color: color?color:'',
                            photoURL:imagePath?imagePath:''
                        }
                        if(note){
                            editNote(user.key, note, section)
                        }else{
                            addNote(user.key,newNote,section);
                        }
                        close();
                        }}/>
                </div>
            </div>
        )
    }
}

const styles = {
    syncContainer:{
        display:'flex',
        flex:0.4,
        flexDirection:'column',
        textAlign:"center", 
        padding:10
    },
    buttonsContainer:{
        display:'flex',
        flex:1,
        flexDirection:'row',
        justifyContent:'space-between',
        padding:20,
    },
    submitContainer:{
        display:'flex',
        flex:0.6,
        flexDirection:'row',
        justifyContent:'flex-end',
        padding:10,
        paddingRight:20,
    },
    textTime:{
        fontSize:10,
        margin:0
    },
    roundButton:{
        width:40, 
        height:40, 
        borderRadius:100, 
        backgroundColor:'#FF6600', 
        display:'flex', 
        flexDirection:'column',
        justifyContent:'center', 
        alignItems:'center',
        margin:5
    },
    secondsLabel:{
        fontWeight:'bold',
        color:'white',
        fontSize:12,
        marginBottom:-5
    }
}

const mapStateToProps = state => ({
    pastas : state.SectionReducers.pastas,
    user: state.UserReducer.user,
  });
  
  const mapDispatchToProps = {
    editNote,
    addNote
  };
  
export default connect(mapStateToProps, mapDispatchToProps)(AddNotesFooter);