import React, { Component } from 'react';
import { connect } from 'react-redux';
import Menu from '../Menu/Menu';
import { TransparentButton, Button } from '../../components/Button';
import Icon from '../../components/Icon'
import { selectEnqueteEvent, respostasRealTime, enviarEnquete, pararEnquete } from '../../libs/redux/actions/EnquetesActions';

class VisualizarEnquete extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    componentDidMount() {
        const { selectedEnqueteEvent, selectedEnquete, respostasRealTime } = this.props
        respostasRealTime(selectedEnqueteEvent, selectedEnquete);
    }

    enqueteHandle(){
        const { enviado, pararEnquete, enviarEnquete, selectedEnqueteEvent, selectedEnquete } = this.props;
        
        if (enviado) {
            pararEnquete(selectedEnqueteEvent, selectedEnquete)
        } else {
            enviarEnquete(selectedEnqueteEvent, selectedEnquete, selectedEnqueteEvent.id)
        }
    }

    render() {
        const { respostas, respostasTotal, selectedEnquete,selectedEnqueteEvent } = this.props
        return (
            <div style={styles.mainContainer}>
                <div style={styles.menuContainer}>
                    <Menu />
                </div>
                <div style={styles.enqueteContainer}>
                    <p style={{...styles.enqueteTitle, borderBottom:'1px solid #eee', margin:10,}}>Respostas</p>
                    <div style={styles.respostasContainer}>
                        <Respostas respostas={respostas} respostasTotal={respostasTotal} />
                    </div>
                </div>
                <div style={styles.sideBar}>
                    <div style={{ alignItems: 'center', justifyContent: 'center', display: "flex" }}>
                        <Icon name={"uano-event"} style={{ fontSize: 28, color: "#ff6600" }} />
                        <p style={styles.enqueteTitle}>{selectedEnqueteEvent.name}</p>
                    </div>
                    <div style={{ margin: '15px 20px 40px 20px', display: 'flex', flexDirection: 'column' }}>
                        <p style={styles.enqueteTitle}>{selectedEnquete.name}</p>
                        <p style={styles.enqueteDescricao}>{selectedEnquete.descricao}</p>
                    </div>
                    <div style={{ justifyContent: 'center', display: 'flex' }}>
                        <Button
                            onClick={() => this.enqueteHandle()}
                            style={styles.enviarButton}>
                            <p style={styles.enviarButtonText}>{this.props.enviado ? 'Parar Enquete' : 'Iniciar Enquete'}</p>
                        </Button>
                    </div>
                </div>
            </div>
        )
    }
}

const Respostas = (props) => {
    const { respostas, respostasTotal } = props;

    return (
        Object.values(respostas)
            .map(resposta => {
                console.log(resposta.total)
                let porcentagem = (((resposta.total / respostasTotal) * 100) * 1).toFixed(2)
                if (isNaN(porcentagem)) porcentagem = 0;
                return (
                    <div style={styles.respostaSingleContainer}>
                        <p style={styles.respostasText}>{resposta.name}</p>
                        <div style={styles.respostasPorcentagemContainer}>
                            <div style={{ margin:'5px 0px 5px 10px',height: 10, backgroundColor: '#FF6600', marginRight: 10, width: `${porcentagem / 1.5}%` }} />
                            <p style={styles.respostasText}>{porcentagem}%</p>
                        </div>
                        <div>
                            <p style={styles.respostasText}>Total: {resposta.total}</p>
                        </div>
                    </div>
                )
            })
    )
}

const styles = {
    mainContainer: {
        display: 'flex',
        padding: 0,
        margin: 0,
        height: '100vh'
    },
    menuContainer: {
        flex: 1,
        maxWidth: 300,
        minWidth: 250
    },
    enqueteContainer: {
        flex: 1,
        display: 'flex',
        padding: '20px 0 0 0',
        backgroundColor: 'white',
        flexDirection: 'column',
    },
    titleContainer: {
        borderBottom: '1px solid #eee',
        textAlign: 'center'
    },
    enqueteTitle: {
        margin: 0,
        fontSize: 16,
        color: '#555'
    },
    enqueteDescricao: {
        margin: 0,
        fontSize: 10,
        color: '#555'
    },
    respostasContainer: {
        marginTop:10,
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        overflow: 'auto'
    },
    sideBar: {
        borderLeft: '1px solid #eee',
        backgroundColor: 'white',
        paddingTop: 40,
        maxWidth: 400,
        minWidth: 300,
        marginLeft: -3,
        zIndex: 1
    },
    enviarButton: {
        border: "2px solid #FF6600",
        display: 'flex',
        borderRadius: 20,
        padding: '5px 10px 5px 10px',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'row'
    },
    enviarButtonText: {
        fontSize: 14,
        color: '#FF6600',
        fontWeight: 'bold',
        margin: 0
    },
    respostaSingleContainer: {
        borderBottom: '1px solid #eee'
    },
    respostasPorcentagemContainer: {
        display: 'flex',
        alignItems: 'center',
        height: 10
    },
    respostasText:{
        margin:'5px 0px 5px 10px'
    }
}

const mapStateToProps = state => ({
    user: state.UserReducer.user,
    enquetes: state.EnquetesReducer.enquetes,
    selectedEnquete: state.EnquetesReducer.selectedEnquete,
    selectedEnqueteEvent: state.EnquetesReducer.selectedEnqueteEvent,
    respostas: state.EnquetesReducer.respostas,
    respostasTotal: state.EnquetesReducer.respostasTotal,
    enviado: state.EnquetesReducer.enviado,
});

const mapDispatchToProps = {
    selectEnqueteEvent,
    respostasRealTime,
    enviarEnquete,
    pararEnquete
};

export default connect(mapStateToProps, mapDispatchToProps)(VisualizarEnquete);