import {
    LOAD_PASTAS,
    SELECTED_SECTION,
    SELECTED_FOLDER,
    SORT_SECTION,
    SET_SECTION_FOLDER,
    ADD_NOTES,
    LOAD_SECTIONS
} from '../../../config/Types';
import moment from 'moment';
import {firebaseDatabase, firebaseStorage} from '../../../config/FirebaseUtils';
import uploadImage from '../../functions/uploadImage';

export const loadFolders = (key) =>{
    console.log("chamado",'load folders')

    const loadFirebase = () => new Promise ((resolve, reject)=>{
        firebaseDatabase.ref(`/users/${key}/pastas`).once("value").then(
            (snapshot)=>{
                let snap = snapshot.val()
                let folders = []
                Object.keys(snap).map( key => folders.push(snap[key]) )
                sessionStorage.setItem('folders',JSON.stringify(folders))
                resolve(folders)
            }
        ).catch(err=>reject({error:err}))
    })

    return (dispatch) =>{
        loadFirebase()
        .then( pastas =>
            dispatch({
                type:LOAD_PASTAS,
                payload: pastas
            })
        ).catch(err=>err)
    }
}

export const setSectionFolder = (key, section, sections)=> {
    console.log("chamado",'set section folder')

    sections.map(item=>{
        if(item.id === section.id)
            item = section
        return item;
    })

    const updateFirebase = new Promise ((resolve, reject)=>{
        firebaseDatabase.ref(`/users/${key}/sections`)
        .update(sections)
        .then(()=>resolve(sections))
        .catch(error=>reject({error}))
    })
    
    return (dispatch) => {
        updateFirebase
        .then(data =>{             
            dispatch({
                type:SET_SECTION_FOLDER,
                payload:data
            })}
        )
        .catch(err => console.log(err))
    }
}

export const selectFolder = (folder) =>{
    return (dispatch) =>{
        dispatch({
            type:SELECTED_FOLDER,
            payload:folder
        })
    }
}

export const addFolder = (key, folders) =>{
    console.log("chamado",'add folders')

    const updateFirebase = new Promise ((resolve, reject)=>{
        firebaseDatabase.ref(`/users/${key}/pastas`)
        .update(folders)
        .then(()=>resolve(folders))
        .catch(error=>reject({error}))
    })

    return (dispatch) => {
        updateFirebase.then(
            folder =>
            dispatch({
                type:LOAD_PASTAS,
                payload:folder
            })
        ).catch(err=>console.log(err))
    }
}

export const loadSections = (key) =>{

    console.log("chamado",'load sections')


    const sectionDataabse = firebaseDatabase
    .ref("users")
    .child(key)
    .child("sections")

    return(dispatch)=>{
        //Carrega todas as sessões encontradas no firebase.
        sectionDataabse
        .once("value")
        .then((snapshot) => {
            const sections = snapshot.val();

            // Add _index property in each section (to easily identify what is the index of the section in the array when only the section itself is available)
            sections.forEach((s, index) => {
                sections[index]._index = index;
            });

            sessionStorage.setItem('sections',JSON.stringify(sections))
            dispatch({
                type:LOAD_SECTIONS,
                payload: sections
            })
        })
        
        //A cada mudança que aconteça nas sessões do usuario (dentro do firebase)
        // é alterado aqui em tempo real e repassado para o reducer.
        //sectionDataabse
        //.on("child_changed", (callback) => {                
        //    sectionDataabse
        //    .once("value")
        //    .then((snapshot) => {
        //        sessionStorage.setItem('sections',JSON.stringify(snapshot.val()))
        //        dispatch({
        //            type:LOAD_SECTIONS,
        //            payload:snapshot.val()
        //            })
        //        })
        //    }
        //)
    }
}

export const selectedSection = (section) =>{
    sessionStorage.setItem('section',JSON.stringify(section))

    return (dispatch)=>{
        dispatch({
            type:SELECTED_SECTION,
            payload:section
        })
    }
}

export const addNote = (key, note, section)=>{
    console.log("chamado",'add note')

    const refPath = `/users/${key}/sections/`;
    let notes = [];

    if (section.notes) notes = section.notes;

    notes.push(note)

    section.notes = notes.sort((a,b) =>{
        const a_time= moment(a.registerTime,'HH:mm:ss').format('X');
        const b_time = moment(b.registerTime,'HH:mm:ss').format('X');
        return  a_time - b_time;
    })

    const updateFirebase = new Promise((resolve, reject)=>{
        firebaseDatabase
        .ref(refPath)
        .once("value")
        .then((snapshot)=>{

                const filtredSection = snapshot.val()
                    .filter((item, index)=>{
                        item.index = index; 
                        return item.id == section.id;
                    })
                const imagePath = `${refPath}${filtredSection[0].index}/notes/${notes.length - 1}`

                firebaseDatabase
                .ref(refPath)
                .child(filtredSection[0].index)
                .update(section)
                .then(()=>{
                    if(note.photoURL){
                        uploadImage(note,imagePath)
                        .then(()=>resolve(section))
                    }else{
                        resolve(section)                       
                    }
                })
                .catch((err)=>console.log({status:false,message:err}))
            }
        )
    }) 

    return (dispatch) => {
        updateFirebase.then( data =>
            dispatch({
                type:SELECTED_SECTION,
                payload:data
            })
        ).catch(err=>console.log(err))
    }
}

export const editNote =(key ,note, section )=>{
    console.log("chamado",'edit note')

    const refPath = `/users/${key}/sections/`

    section.notes = section.notes.map(item=> {
        if(item.id === note.id) return note
        else return item
    })
    
    const updateFirebase = new Promise((resolve, reject)=>{    
        firebaseDatabase
        .ref(refPath)
        .once("value")
        .then((snapshot) => {
            const filtredSection = snapshot.val()
                .filter((item, index)=>{
                item.index = index;
                    return item.id == section.id;
                })

            const noteIndex = snapshot.val()[filtredSection[0].index].notes
                .filter((item, index)=>{
                    item.index = index;
                    return item.id == note.id;
                })

            const imagePath = `${refPath}${filtredSection[0].index}/notes/${noteIndex[0].index}`

            firebaseDatabase
            .ref(refPath)
            .child(filtredSection[0].index)
            .update(section)
            .then(()=>{
                if(note.photoURL){
                    uploadImage(note,imagePath)
                    .then(() => resolve(section))
                }else{
                    resolve(section)
                }
            })
            .catch(err=>console.log(err))
            }
        )
    })

    return (dispacth) => {
        updateFirebase
        .then( data =>             
            dispacth({
                type:SELECTED_SECTION,
                payload:data
            })
        ).catch(err=>console.log(err))
    }
}

export const delNote = (key ,note, section) =>{
    console.log("chamado",'del note')
    const refPath = `/users/${key}/sections`;    
    const updateFirebase = new Promise((resolve,reject) => {
        firebaseDatabase
        .ref(refPath)
        .once('value')
        .then( snap =>{ 

           const sections = snap.val()
           const notes = []
           const filtredSections = []

            for(let item of section.notes){
                if(item.id !== note.id)
                    notes.push(item)
            }

            section.notes = notes

            for(let item of sections){
                if(item.id === section.id){
                    item = section
                }
                filtredSections.push(item)
            }
           firebaseDatabase
           .ref(refPath)
           .set(filtredSections)
           .then(()=>resolve(section))
           .catch((err)=>reject(err))
        })
        .catch(err => reject(err))
    })

    return (dispacth) =>{
        updateFirebase
        .then(data => dispacth(
            dispacth({
                type:SELECTED_SECTION,
                payload:data
            })        ))
        .catch(err=>console.log('erro',err))
    }
}
