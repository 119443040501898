import React,{Component} from 'react';

import moment from 'moment'
import TextArea from '../../components/TextArea'
import Input from '../../components/Input'
import {CircleButton} from '../../components/Button'
import AddNotesFooter from './AddNotesFooter';
import EditNotes from './EditNotes'
import Modal from '../../components/Modal'

const colors = [
    '#FF6600',
    '#4D0757',
    '#1C63B1',
    '#1C6A63',
];

export default class AddMarkNotes extends Component{

    constructor(props){
        super(props);
        this.state = {
            selectedButton:'#FF6600',
            text:''
        };
    }

    componentDidMount(){
        const {note} = this.props
        if(note){
            this.setState({text:note.text, selectedButton:note.label_color})
        }
    }

    markColorButtons(){
        const {selectedButton} = this.state;
        const {note} =  this.props
        return colors.map((color)=>{
            if(selectedButton === color){
                return (
                    <CircleButton 
                        style={{...styles.colorButton, backgroundColor:color, border:'9px solid #ddd', height:70, width:70}}
                        icon={{name:'uano-check',style:{color:'white', fontSize:38}}}
                        onClick={()=>{
                            if(note){
                                note.label_color = color
                            }
                            this.setState({selectedButton:color})
                    }}/>)
            }else{
                return(
                    <CircleButton style={{...styles.colorButton, backgroundColor:color}} 
                        onClick={()=>{
                            if(note)
                                note.label_color = color
                            
                            this.setState({selectedButton:color})
                    }}/>
                )
            }
        })
    }

    MarksDiv = (props) =>{
        return (
        <div>
            <div style={{flex:0.2, display:'flex', justifyContent:'center', alignItems:'center'}}>
                <p style={{fontSize:12, fontWeight:'bold',color:'gray'}}>Opcional:</p>
                <p style={{fontSize:12, marginLeft:5,color:'gray'}}>escolha uma cor para organizar suas marcações.</p>
            </div>
            <div style={{flex:1, display:'flex',padding:'0 90px 0 90px', alignItems:'flex-start'}}>
                <div style={{display:'flex', width:"100%", justifyContent:'space-around', alignItems:'center'}}>
                    {this.markColorButtons()}
                </div>
            </div>
        </div>
        )
    }

    EditNotesDiv = (props) =>{
        return (
            <div style={{flex:1,display:'flex', flexDirection:'column'}}>
                <EditNotes
                    {...props}
                    text={this.state.text} 
                    color={this.state.selectedButton} 
                    type="M" 
                />
            </div>
        )
    }

    render(){
        const {close, note} = this.props;

        return(
            <Modal headerTitle = "Marcações no Audio" close={()=>{close()}}>
                <main style={{display:'flex', flexDirection:'column', flex:1}}>
                    <div style={{display:'flex'}}>
                        <Input 
                        style={styles.input}
                        placeholder='Nome da marcação (Opcional)' 
                        defaultValue = {this.state.text}
                        onTextChange={(text)=>{
                            this.setState({text:text})
                            if(note){
                                note.text = text
                                note.title = text
                            }
                        }}/>
                    </div>
                    {this.props.editar?<this.EditNotesDiv {...this.props}/>:<this.MarksDiv/>}
                </main>
                <footer style={{flex:1}}>

                {this.props.editar?(null):<AddNotesFooter {...this.props} text={this.state.text} color={this.state.selectedButton} type="M" close={()=>{close()}}/>}
                </footer>    
            </Modal>
        )
    }
}

const styles = {
    colorButton:{
        height:60,
        width:60,
        borderRadius:100,
        display:'flex',
        cursor:'pointer'
    },
    input:{
        fontWeight:'100',
        backgroundColor:"white", 
        flex:1,
        border:'1px solid #ddd', 
        margin:'0 40px 0 40px', 
        height:70, 
        borderRadius:50, 
        padding:20, 
        fontSize:18,
    }
}