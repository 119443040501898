import React,{Component} from 'react';

class TextArea extends Component {
    constructor(props){
        super(props);
    }

    componentDidMount(){}

    render(){
        const {onTextChange, defaultValue, placeholder, style, ...propsWithoutOnTextChange} = this.props;
        return(
            <textarea style={style} {...propsWithoutOnTextChange}
            defaultValue={defaultValue?defaultValue:''} 
            placeholder={placeholder?placeholder:''} id="text" 
            onChange={({target})=>{
                if(onTextChange){
                    onTextChange(target.value)
                }else{
                    console.log('USE onTextChange PROPS TO RETURN INPUT TEXT.')
                }
            }}/>
        )
    }  
}

export default TextArea;
