import React,{Component} from 'react';
import { connect } from 'react-redux'

import {ToastsContainer, ToastsStore,ToastsContainerPosition} from 'react-toasts';

import {TransparentButton} from '../../components/Button'
import {setSectionFolder} from '../../libs/redux/actions/SectionActions'
import {sortSections} from '../../libs/functions/sortData'
import {sectionTimes} from '../../libs/functions/sectionTimes';
import { Row } from 'react-bootstrap';


class SelectEvent extends Component{

    constructor(props){
        super(props);
        this.state = {
            selectedButton:'#FF6600',
            pastas:[],            
            sections:[],
        };
    }

    loadSectionData(){
        let sections  = ''

        if(this.props.sections){
            sections = this.props.sections.filter(item=>item)
            if(this.props.pasta.id){
                sections = this.props.sections.filter(item => {
                    if(item.pasta)return item.pasta.id !== this.props.pasta.id
                    else return item
                })
            }

            const sortedData =  sortSections(sections, 1);
            return sortedData.map((section) => {
                
                const {day,date,initHour, initMinute, endHour, endMinute} = sectionTimes(section)
                return (
                    <Row className="item" style={{alignItems:'center'}} 
                    onClick={()=>{
                        section.pasta = this.props.pasta;
                        this.props.setSectionFolder(this.props.user.key,section, this.props.sections)
                        ToastsStore.warning("Sessão adicionada a pasta!")
                    }}>
                        <div className="iconContainer" style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                            <span className="icon-play" style={{color:'white', fontSize:28, marginLeft:7, padding:0}}></span>
                        </div>
                        <div>
                            <p className="textItem" id="textDay">{`${day} - ${date} - ${initHour}h${initMinute} a ${endHour}h${endMinute}`}</p>
                            <p id="textSection">{section.name}</p>
                            <p className="textItem" id="textLocal">Local de Gravação da Sessão</p>
                        </div>
                </Row>
                );
            })
        }else{
            return <div/>
        }
    }

    render(){
        const {close} = this.props;

        return(
            <div style={styles.mainContainer}>
                <div style={styles.titleDiv}>
                    <p style={{marginTop:15}}>Selecionar Evento</p>
                    <TransparentButton icon={{name:'uano-cancelar',style:{color:'#999',fontSize:25}}} onClick={()=>{close()}}/>
                </div>
                <div style={{overflowY:'auto',marginBottom:10,flex:1}}>
                    {this.loadSectionData()}
                    <ToastsContainer store={ToastsStore} position={ToastsContainerPosition.TOP_CENTER}/>
                </div>
            </div>
        )
    }
}

const styles = {
    mainContainer:{
        backgroundColor:'white', 
        borderRadius:20,
        width:450, 
        height:600,
        display:'flex',
        flexDirection:'column',
        boxShadow:'5px 2px 10px rgba(0,0,0,0.2), 8px 2px 20px rgba(0,0,0,0.25)'
    },
    titleDiv:{
        marginTop:20,
        margin:'20px 10px 0px 10px',
        height:50,
        borderBottom:'solid 1px #ddd',
        display:'flex',
        justifyContent:'space-between',
        alignItems:'center'
    },
    textArea:{
        marginTop:10,
        outline:'none',
        width:'100%', 
        resize:'none',
        border:'none', 
        height:450,
        padding:10
    },
    textTime:{
        fontSize:10,
        margin:0
    },
    roundButton:{
        width:33, 
        height:33, 
        borderRadius:100, 
        backgroundColor:'#FF6600', 
        display:'flex', 
        flexDirection:'column',
        justifyContent:'center', 
        alignItems:'center'
    },
    colorButton:{
        height:40,
        width:40,
        borderRadius:100,
        display:'flex',
        cursor:'pointer'
    }
}

const mapStateToProps = state => ({
    pasta: state.SectionReducers.folder,
    sections:state.SectionReducers.sections,
    user: state.UserReducer.user,
});
    
const mapDispatchToProps = {
    setSectionFolder
};
    
export default connect(mapStateToProps, mapDispatchToProps)(SelectEvent);
