import React from 'react';
const Input = (props) => {
    
    let styles = {
        outline:'none',
        backgroundColor:'#fff', 
        border:'1px solid #dfdfdf',
        color:'#444',
        width:'100%',
        height:null,
        borderRadius:7,
        padding:8,
        fontSize:12,
    }

    Object.keys(styles)
    .map( key => {
        if(props.style && props.style[key])
            styles[key] = props.style[key]
    })

    if(props.style){
        Object.keys(props.style)
        .map( key => {
            if(!styles[key])
                styles[key] = props.style[key]
        })
    }

    let {onTextChange, ...propsWithoutOnTextChange} = props; // This just removes the warning about the unknown onTextChange <input> attribute, when spreading props directly
    return (
        <input {...propsWithoutOnTextChange} style={styles} autoComplete="off" onChange={({target}) =>{
            if(props.onTextChange) 
                props.onTextChange(target.value)
            else 
                console.log('USE onTextChange PROPS TO RETURN INPUT TEXT.')
        }}/>
    )
}
export default Input;
