import React,{Component} from 'react';

import moment from 'moment'
import Input from '../../components/Input'
import Icon from '../../components/Icon'
import {connect} from 'react-redux'
import {addFolder} from '../../libs/redux/actions/SectionActions'
import Modal from '../../components/Modal';
import {CircleButton} from '../../components/Button'

const colors = [
    '#FFFF00',
    '#FFCC00',
    '#B8962F',
    '#FFAA71',
    '#FF9100',
    '#FF6600',
    '#FF0000',
    '#8A0000',
    '#FF00FF',
    '#CB0080',
    '#FFB8FF',
    '#E394FF',
    '#B757F6',
    '#8A00E5',
    '#4D0757',
    '#74E0FF',
    '#00A2FF',
    '#1C63B1',
    '#002058',
    '#0CFF00',
    '#0AC900',
    '#1D6A64',
    '#426800',
    '#CCCCCC',
    '#AEAEAE',
    '#7E7B7B',
    '#434343',
    '#252525',
  ];

class AddMarkNotes extends Component{

    constructor(props){
        super(props);
        this.state = {
            selectedButton:'#FF6600',
            text:'Nova Pasta',
            pastas:[],
        };
    }

    markColorButtons(){
        const {selectedButton} = this.state;

        let buttons = 
            colors.map((color, index)=>{
                if(selectedButton === color){
                    return(
                    <CircleButton 
                        style={{...styles.colorButton, backgroundColor:color, margin:8, border:'2px solid gray'}}
                        icon = {{name:"uano-check",style:{color:"white",fontSize:20}}}
                        onClick={()=>{this.setState({selectedButton:color})}}
                    />)
                }else{
                    return(
                    <CircleButton 
                        style={{...styles.colorButton, backgroundColor:color, margin:8}} 
                        onClick={()=>{this.setState({selectedButton:color})}}
                    />)
                }
            })

        let divs = []
        const rows = 4
        return buttons.map((button, index) => {
            if(index % rows === 0){
               for(let x = 0 + index; x < index + rows; x++) divs.push(buttons[x])
               return (<div style={{display:'', flexDirection:'column', flex:1}}>{divs}</div>)
            }else{
                divs =[]
            }
        })
    }

    render(){
        const {close, user} = this.props;
        return(
            <Modal headerTitle = 'Nova Pasta' close={()=>{close()}}>
                <header >
                    <Input 
                    style={{backgroundColor:'white'}}
                    placeholder='Nova Pasta' 
                    defaultValue = {this.state.text}
                    onTextChange={(text)=>{
                        this.setState({text})
                    }}/>
                </header>
                <main style={{display:'flex', flexDirection:'column', flex:1}}>
                    <div style={{borderBottom:'solid 1px #ddd', margin:'0 10px 0 10px'}}/>
                    <div style={{flex:1, display:'flex', justifyContent:'space-around', padding:'0 90px 0 90px'}}>
                        {this.markColorButtons()}
                    </div>
                </main>
                <footer style={{display:'flex', flex:1, justifyContent:'flex-end', paddingBottom:10}}>
                    <CircleButton 
                        style={styles.roundButton}
                        label={{text:'OK',style:{fontWeight:'bold',color:'white',fontSize:12,marginBottom:0}}} 
                        onClick={()=>{
                            const {selectedButton,text} = this.state;
                            const pastas = this.props.pastas
                            const pasta = {
                                color: selectedButton,
                                createdAt: moment().format("DD/MM/YYYY HH:mm:ss"),
                                id: moment().format("x"),
                                name: text,
                            }

                            pastas.push(pasta)
                            this.props.addFolder(user.key,pastas)
                            close();}}
                    />
                </footer>
            </Modal>
        )
    }
}

const styles = {
    roundButton:{
        width:33, 
        height:33, 
        borderRadius:100, 
        backgroundColor:'#FF6600', 
        display:'flex', 
        flexDirection:'column',
        justifyContent:'center', 
        alignItems:'center'
    },
    colorButton:{
        height:40,
        width:40,
        borderRadius:100,
        display:'flex',
        cursor:'pointer'
    }
}

const mapStateToProps = state => ({
    pastas: state.SectionReducers.pastas,
    user: state.UserReducer.user,
});
    
const mapDispatchToProps = {
    addFolder
};
    
export default connect(mapStateToProps, mapDispatchToProps)(AddMarkNotes);