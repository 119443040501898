import React from 'react';
import { Link } from 'react-router-dom';
import Icon from './Icon'

const BackContainer = ({ href, style}) => (
    <div style={{...styles.backContainer, ...style}}>
        <Link to={href} style={{ textDecoration: 'none' }}>
            <Icon name={"left-arrow"} style={{ color: "#FF6600", fontSize: 20 }} />
        </Link>
    </div>
)

const styles ={
    backContainer:{
        position:'absolute',
        zIndex:3,
        backgroundColor:'#2E2D33',
        width:25, 
        height:"100%",
        display:'flex',
        alignItems:'center',
        justifyContent:'center',
        boxShadow:'5px 2px 10px rgba(0,0,0,0.2), 8px 2px 20px rgba(0,0,0,0.25)',
    }
}

export default BackContainer;