import React, {Component, useEffect, useState} from 'react';
import Input from '../../components/Input';
import {Button} from '../../components/Button';
import {firebaseAuth, firebaseDatabase, getUserKey} from '../../config/FirebaseUtils'
import {loadFolders, loadSections} from '../../libs/redux/actions/SectionActions'
import {getUserData} from '../../libs/redux/actions/UserActions'
import {listarEnquetes} from '../../libs/redux/actions/EnquetesActions'
import {loadEvents} from '../../libs/redux/actions/EventActions'
import { connect } from 'react-redux'

class Login extends Component{
    constructor(props){
        super(props);
        this.state = {
            email:'',
            password:'',
            loginText:'',
            login:false,
            conection:null
        }
    }

    componentDidMount(){
        //const key = this.props.user.key
        //
        //firebaseDatabase.ref(`users/${key}/`)
        //.once('value')
        //.then(snapshot => {
        //    this.setState({conection:true})
        //    getUserData(key)
        //    loadFolders(key)
        //    loadSections(key)
//
        //    if(this.props.user.palestrante){
        //        listarEnquetes(key)
        //        loadEvents(key)
        //    }
//
        //    this.props.history.push('/sections')
        //})
    }

    login(){
        const {email, password} = this.state;
        const {getUserData, loadFolders,loadSections, loadEvents, history, listarEnquetes} = this.props;

        this.setState({loginText:'Conectando...', login:true, conection:null})

        firebaseAuth.signInWithEmailAndPassword(email,password).then(
            ({user})=>{
                const key = getUserKey()
                // const key = email.toLowerCase().replace(/[^\w\s]/gi, '')
                console.info(`User ${email} signed in. Key: ${key}`);

                firebaseDatabase.ref(`users/${key}/`)
                .once('value')
                .then(snapshot => {
                    const loggedUser = snapshot.val()
                    this.setState({conection:true})
                    getUserData(key)
                    loadFolders(key)
                    loadSections(key)
                    if(loggedUser.palestrante){
                        listarEnquetes(key)
                        loadEvents(key)
                    }
                    
                    history.push('/sections')
            })}
        ).catch(err=>{
            setTimeout(()=>{ this.setState({loginText:'Login ou Senha incorretos!', conection:false})},1000)
            setTimeout(()=>{this.setState({login:false})},3000)
        })
    }

    render(){
        return(
            <div style={{backgroundColor:'white', display:'flex', flexDirection:'column',justifyContent:'center', alignItems:'center', position:'absolute', top:0,bottom:0,left:0,right:0}} 
                onKeyDownCapture={(event)=>{if(event.keyCode === 13) this.login()}}
            >
                <div style={{flexDirection:'column', display:'flex', justifyContent:'space-between',height:170}}>
                    <p style={{textAlign:'center'}}>UANO - ALFA</p>
                    <Input style={{width:400}} placeholder="Email" onTextChange={(text)=>{this.setState({email:text})}}/>
                    <Input style={{width:400}} placeholder="Senha" onTextChange={(text)=>{this.setState({password:text})}} type="password"/>
                    <Button 
                    style={{textAlign:'center',width:150, height:30, padding:10, backgroundColor:"#FF6600", borderRadius:5,}}
                    label={{text:'Fazer Login',style:{color:'white', marginTop:-6}}}
                    onClick={()=>this.login()}/>
                </div>
                {this.state.login === false?(null):<LoginBadge conection ={this.state.conection}/>}
            </div>
        )
    }
}

const LoginBadge = (props) => {
    const [mounted, setMounted] = useState(false)
    const [animation, setAnimation] = useState('init')

    useEffect(() => {

        const badge = document.querySelector('.badge')

        setTimeout(()=>{
            if(mounted === false && animation==='init'){
                setMounted(true)
                badge.animate([{transform:'translateY(-25vh)'},{transform:'translateY(0vh)'}],{fill:'forwards', duration:200})
                setTimeout(()=>{setAnimation('end')},500)
            }else if(animation === 'end'){
                switch(props.conection){
                    case true: 
                        badge.animate(
                            [{transform:'translateY(0vh)'},{transform:'translateY(-40vh)'}],
                            {fill:'forwards', duration:500, delay:100});
                        setAnimation('');
                        break
                    case false: 
                        badge.animate(
                            [
                                {transform:'translateX(0px)'},
                                {transform:'translateX(-20px)'},
                                {transform:'translateX(20px)'},
                                {transform:'translateX(0px)'},
                            ],
                            {fill:'forwards', duration:200})
                        badge.animate(
                            [
                                {opacity:1},
                                {opacity:0},
                            ],
                            {fill:'forwards', duration:500, delay:1300})
                        setAnimation('')
                        break
                    default:break;
                }
            }
        },100)

    });

    return (
        <div style={{width:'100vh', height:'100vh',position:'absolute', pointerEvents:'none', opacity:mounted === true?1:0, display:'flex', justifyContent:'center'}}>
            <div 
            className='badge'
            style={{
                backgroundColor:'#FF8800', 
                padding:'7px 15px 7px 15px',
                display:'flex',
                maxHeight:40,
                marginTop:'25vh',
                justifyContent:'center',
                alignItems:'center',
                borderRadius:30,
            }}>
                <p style={{margin:0, color:'white', fontWeight:'bold', textAlign:'center'}}>
                    {props.conection === false?'Login ou senha incorretos!':props.conection === true?'Conectado com sucesso':'Conectando...'}
                </p>
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    user: state.UserReducer.user,
});

const mapDispatchToProps = {
    getUserData,
    loadFolders,
    loadSections,
    loadEvents,
    listarEnquetes,
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);