import React,{Component} from 'react';
import { connect } from 'react-redux'
import { Row, Col, Modal} from 'react-bootstrap';
import Slider from 'react-input-slider';
import { Link } from 'react-router-dom'
import moment from 'moment';
import {TransparentButton} from '../../components/Button';
import '../../icons/style.css'
import Axios from 'axios';
import fileDownload from 'js-file-download';

var timer;
var audio;

const PLAYBACK_RATES = [0.25, 0.5, 1, 1.25, 1.5, 1.75, 2];

class Player extends Component {
    constructor(props){
        super(props)
        this.state = {
            audios:"",
            isPlayng:false,
            currentTime:0,
            maxTime:0,
            noteTime:0,
            playbackRateIndex: 2,
            enableForward:true,
            index:null
        }
    }
    componentDidMount(){
        setTimeout(()=>{
            audio =  new Audio(this.props.audios[0].storageURL);
            audio.loop = false;
            this.setState({maxTime:moment.duration(this.props.audios[0].duration).asSeconds()})
        },100)
    }

    componentWillUnmount() {
        if (audio) {
            audio.currentTime = 0;
            audio.pause();
            audio.currentTime = 0;
        }
        clearInterval(timer);
    }

    // Prompts download to the audio files of the current section
    downloadAudios() {
        this.props.audios.forEach((a, index) => {
            const fileName = `Áudio_${a.name.replace(/[^\w\d]/g, '_').substr(0, 30)}_${index}.${a.format}`;
            // Since the audio stored in Storage doesn't have the format on its file name, the browser can't recognize it (?), and it's bad UX to download manually
            // So instead of opening a link to storageURL in the browser, we have to download the file.
            Axios.get(a.storageURL, { responseType: 'blob' })
                .then(res => { fileDownload(res.data, fileName); })
                .catch(err => { console.error(`Error downloading section audio ${index}:`, err); })
        })
    }

    timer(){
        timer = setInterval(()=>{
            this.setState({currentTime: audio.currentTime, enableForward:true})
            if(this.state.currentTime >= this.state.maxTime ){
                audio.pause();
                this.setState({isPlaying:false})
                audio.currentTime = 0;
                clearInterval(timer)
            }
            this.props.audioCurrentTime(this.state.currentTime)
        }, 30)
    }

    play(){
        audio.play();
        this.setState({isPlaying:true})
        this.timer();
    }

    pause(){
        clearInterval(timer)
        audio.pause();
        this.setState({isPlaying:false})
    }

    forward(time){
            let noteIndex = this.props.noteIndex?this.props.noteIndex:0
            let noteListIndex = this.props.index
            
            if(this.state.index === null)this.setState({index:noteIndex})
            let index = this.state.index + time;

            if(index < 0) index = 0
            if(index > noteListIndex.length) index = noteListIndex.length - 1

            if (noteListIndex[index]) {
                audio.currentTime = noteListIndex[index].time
                this.setState({currentTime: audio.currentTime,index:index, enableForward:false})
            }
    }

    updatePlaybackRate() {
        const index = this.state.playbackRateIndex;
        const newIndex = index === PLAYBACK_RATES.length - 1 ? 0 : index + 1;
        const playbackRate = PLAYBACK_RATES[newIndex];
        
        console.log('New Player playbackRate:', playbackRate);
        audio.playbackRate = playbackRate;

        this.setState({ playbackRateIndex: newIndex });
        
    }

    componentDidUpdate(){
        const {time} = this.props
        const {noteTime} =  this.state
        if(time !== null){
            if(time !== noteTime){
                audio.currentTime = time;
                this.setState({currentTime: time, noteTime:time});
            }
        } 
    }

    slider(time){
        audio.currentTime = time
    }

    selector(isPlaying){
        const {simple} = this.props;
        if(simple){
            return this.simplePlayer(isPlaying)
        }else{
            return this.notesPlayer(isPlaying)
        }
    }

    simplePlayer(isPlaying){
        return(
            <div style={{...styles.playButton}}>
                {
                    !isPlaying?
                    (<span onClick={()=>{this.play()}} className="icon-play"/>)
                    :
                    (<span onClick={()=>{this.pause()}} className="icon-pause" style={{...styles.pauseIcon}}/>)
                }
            </div>
        )
    }

    notesPlayer(isPlaying){
        return(
            <div style={{display:"flex",flexDirection:'column'}}>
            <Slider
                style={{width:"calc(100% - 20px)",top:5, left:20, cursor:'pointer'}}
                styles={{
                    track:{
                        borderRadius:0
                    },
                    active: {
                      backgroundColor: '#FF6600',
                      borderRadius:0
                    },
                    thumb:{
                        backgroundColor:"#FF6600"
                    }
                  }}

                axis="x"
                x={this.state.currentTime}
                xmax={this.state.maxTime}
                onChange={({ x }) => this.slider(x)}
            />
            <div style={styles.container}>
                <div>
                    <div style={styles.playButton}>
                        {
                            !isPlaying?
                            (<span onClick={()=>{this.play()}} className="icon-play"/>)
                            :
                            (<span onClick={()=>{this.pause()}} className="icon-pause" style={{...styles.pauseIcon}}/>)
                        }
                    </div>
                </div>
                <TransparentButton onClick={()=>{this.forward(-1)}} 
                    style={{transform:"scaleX(-1)", margin:10}}
                    icon={{name:'uano-avancar',style:{...styles.forwardIcons}}}/>
                <div style={{marginTop:10}}>
                    <span style={styles.currentTimeText}>{moment('00:00:00','HH:mm:ss').add(this.state.currentTime,'seconds').format("HH:mm:ss")}</span>
                    <p style={styles.playbackRateText} onClick={this.updatePlaybackRate.bind(this)}>{PLAYBACK_RATES[this.state.playbackRateIndex] + 'x'}</p>
                </div>
                <TransparentButton onClick={()=>{this.forward(1)}} style={{margin:10}} 
                icon={{name:'uano-avancar',style:{...styles.forwardIcons}}}/>
                {
                    !this.props.hideExtraOptions ? (
                        <>
                            <div style={styles.noteContainer}>
                                <TransparentButton style={styles.noteButton} 
                                    onClick={()=>{this.props.openNoteModal('T')}}
                                    icon={{name:'uano-abc'}}
                                />
                                <TransparentButton style={styles.noteButton} 
                                    onClick={()=>{this.props.openNoteModal('P')}}
                                    icon={{name:'camera'}}
                                />
                                <TransparentButton style={styles.noteButton} 
                                    onClick={()=>{this.props.openNoteModal('M')}}
                                    icon={{name:'uano-bookmark'}}
                                />
                            </div>
                            <div style={{...styles.noteContainer, justifyContent: 'end' }}>
                                <TransparentButton style={{...styles.buttonWithBottomLabel, fontSize: 56 }} 
                                    onClick={this.downloadAudios.bind(this)}
                                    icon={{name:'uano-download-nuvem'}}
                                    label={{text:"Download do áudio", style: {...styles.buttonBottomLabel, bottom: 0}}}
                                />
                                <Link to={'/sincronizar_notas'} style={{textDecoration:'none'}}>
                                    <TransparentButton style={styles.buttonWithBottomLabel} 
                                        icon={{name:'sincronizar'}} 
                                        label={{text:"Sincronizar conteudo", style: {...styles.buttonBottomLabel }}}
                                    />
                                </Link>
                            </div>
                        </>
                    ) : null
                }
            </div>
            </div>
        )
    }

    render(){
        const {isPlaying} = this.state;
        return this.selector(isPlaying)
        
    }
}

const styles = {
    container:{
        display:'flex',
        alignItems:'center',
        backgroundColor:"#2E2D33",
        padding: 15,
        paddingTop: 16,
        minHeight: 125
    },
    playButton:{
        background:'linear-gradient(150deg, #FF6600, #4D0757)',
        width: 65,
        height: 65,
        paddingLeft:4,
        borderRadius:100,
        display:"flex",
        justifyContent:'center',
        alignItems:'center',
        fontSize: 36,
        color:"white",
        marginLeft: 50,
        marginRight: 30,
        cursor:'pointer',
        boxShadow: '10px 10px 10px rgb(0,0,0,.5)'
    },
    pauseIcon: {
        paddingRight: '0.29rem'
    },
    forwardIcons:{
        fontSize: 38,
        color:"#777",
        cursor:'pointer'

    },
    playerText: {
        color: 'gray',
        fontSize: 12,
    },
    currentTimeText: {
        color:"#FF6600", 
        fontSize:12,
        margin: 0,
    },
    playbackRateText: {
        cursor:'pointer',
        color: 'white',
        fontSize: 14,
        textAlign: 'center',
        margin: 0,
    },
    noteButton:{
        margin: '0 20px',
        fontSize: 42,
        color:'#FF6600',
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        cursor:'pointer',
    },
    buttonWithBottomLabel:{
        margin: '0 20px',
        fontSize: 42,
        color:'#FF6600',
        cursor:'pointer',
    },
    buttonBottomLabel: {
        position: 'relative',
        bottom: -12,
        fontSize: 10,
        color: 'white',
        marginBottom: 0,
        marginTop: -20,
    },
    noteContainer:{
        display:'flex',
        alignItems:'center',
        flex:1,
        // marginLeft:20,
    }
}

const mapStateToProps = state => ({});
  
  const mapDispatchToProps = {
  };
  
export default connect(mapStateToProps, mapDispatchToProps)(Player);
