import React,{Component} from 'react';
import { connect } from 'react-redux'
import { Row, Col } from 'react-bootstrap';
import {Button, TransparentButton,CircleButton} from '../../components/Button'
import Input from '../../components/Input'
import Icon from '../../components/Icon'
import Menu from '../Menu/Menu'
import {Link} from 'react-router-dom'
import PalestranteAudioRecorder from '../Audio/PalestranteAudioRecorder'
import PalestrandeAudioUpload from '../Audio/PalestrandeAudioUpload'
import EditEvent from './EditEvent'
import Leads from '../Analytics/Leads'
import Analytics from '../Analytics/Analytics'
import moment from 'moment'
import ListarEnquetes from '../Enquetes/ListarEnquetes';
import BackContainer from '../../components/BackContainer'

class SelectedEvent extends Component {
    constructor(props){
        super(props);
        this.state = {
            event : {},
            isRecording: false,
            blobURL: '',
            isBlocked: false,
            modalSelect:'',
        }
    }

    render(){
        const {event} = this.props;
        return(
            <div style={{ width:'100vw', height:'100vh'}}>
                <Row id="row">
                    <Col  md={3}>
                        <Menu style={{flex:1}}/>
                    </Col>
                    <Col md={6} style={{zIndex:0}}>
                        <BackContainer href={'/events'} style={{marginLeft:-15}}/>
                        <div style={{width:'100%', height:'100%', display:'flex', flexDirection:'column', padding:10, marginLeft:20}}>
                            <div style={{flex:0.7, borderBottom:'1px solid #eee', display:'flex', flexDirection:'column', justifyContent:'flex-end', paddingBottom:20}}>
                                <div>
                                    <p style={styles.eventTitle}>{event.name}</p>
                                    <p style={styles.eventAddress}>{event.address}</p>
                                </div>
                                <div>
                                    <p style={styles.dateText}>{event.date}</p>
                                    <p style={styles.dateText}>Início aproximado: {event.initTime}</p>
                                    <p style={{...styles.dateText, marginBottom:20}}>Fim aproximado: {event.endTime}</p>
                                    
                                    <TransparentButton onClick={()=>{this.setState({modalSelect:'edit_event'})}}>
                                        <Icon name={'edit'} style={{color:'#FF6600', fontSize:25}}/>
                                    </TransparentButton>
                                </div>
                            </div>
                            <div style={styles.squareContainer}>
                                    <Button 
                                        icon={{name:'enquete', style:{color:'#EEE', fontSize:40}}} 
                                        label={{text:'Gerenciar Enquetes de Evento', style:{color:'white', fontSize:9, marginBottom:5, paddingTop:12}}}
                                        style={{backgroundColor:'#FF6600',...styles.squareButton}}
                                        onClick={()=>{this.setState({modalSelect:'enquetes'})}}
                                    />
                                    <Button 
                                        icon={{name:'mic-preto', style:{color:'#EEE', fontSize:50}}} 
                                        label={{text:'Gravar Audio de Evento', style:{color:'white', fontSize:10, marginBottom:5, paddingTop:12}}}
                                        style={{backgroundColor:'#4D0757',...styles.squareButton}}
                                        onClick={()=>{this.setState({modalSelect:'audio'})}}
                                    />
                                    <Button 
                                        icon={{name:'upload-cloud', style:{color:'#EEE', fontSize:50}}} 
                                        label={{text:'Upload Audio de Evento', style:{color:'white', fontSize:10, marginBottom:5, paddingTop:12}}}
                                        style={{backgroundColor:'#4D0757',...styles.squareButton}}
                                        onClick={()=>{this.setState({modalSelect:'audio_upload'})}}
                                    />
                                    {

                                        //<Button 
                                        //    icon={{name:'upload-cloud', style:{color:'#FF6600', fontSize:50}}} 
                                        //    label={{text:'Upload de Sessão Completa', style:{color:'#FF6600', fontSize:10, marginBottom:5, paddingTop:12}}}
                                        //    style={{backgroundColor:'#4D0757',...styles.squareButton}}
                                        //    onClick={()=>{}}
                                        ///>
                                    }
                            </div>
                            <div style={styles.squareContainer}>
                                    <Button 
                                        icon={{name:'analytics', style:{color:'#EEE', fontSize:50}}} 
                                        label={{text:'Analytics', style:{color:'white', fontSize:10, marginBottom:5, paddingTop:12}}}
                                        style={{backgroundColor:'#777',...styles.squareButton}}
                                        onClick={()=>{this.setState({modalSelect:'analytics'})}}
                                    />
                                    <Button 
                                        icon={{name:'people', style:{color:'#EEE', fontSize:70}}} 
                                        label={{text:'LEADs', style:{color:'white', fontSize:10, marginBottom:12}}}
                                        style={{backgroundColor:'#777',...styles.squareButton}}
                                        onClick={()=>{this.setState({modalSelect:'leads'})}}
                                    />
                                    <Button 
                                        icon={{name:'content-copy', style:{color:'#EEE', fontSize:50}}} 
                                        label={{text:'Duplicar Evento', style:{color:'white', fontSize:10, marginBottom:5, paddingTop:12}}}
                                        style={{backgroundColor:'#777',...styles.squareButton}}
                                        onClick={()=>{}}
                                    />
                                    {
                                        
                                     //   <Button 
                                     //       icon={{name:'sincronizar', style:{color:'#EEE', fontSize:30}}} 
                                     //       label={{text:'Gerenciar Enquetes de Evento', style:{color:'white', fontSize:10, marginBottom:5, paddingTop:12}}}
                                     //       style={{backgroundColor:'#777',...styles.squareButton}}
                                     //       onClick={()=>{}}
                                     //   />
                                    }
                            </div>
                        </div>
                    </Col>
                    <Col md={2} style={{backgroundColor:'white'}}>
                    </Col>
                </Row>
                <ModalGroup modal={this.state.modalSelect} close={()=>{this.setState({modalSelect:''})}} event={event}/>
            </div>
        )
    }  
}

const ModalGroup = (props) => {
    let event = props.event;
    switch(props.modal){
        case 'enquetes':return (<ListarEnquetes speakerID = {event.speakerID} close={()=>props.close()}/>);
        case 'audio':return (<PalestranteAudioRecorder close={()=>props.close()}/>);
        case 'edit_event':return (<EditEvent close={()=>props.close()}/>);
        case 'leads':return (<Leads close={()=>props.close()}/>);
        case 'audio_upload':return (<PalestrandeAudioUpload close={()=>props.close()}/>);
        case 'analytics': return(<Analytics close={()=>props.close()}/>)
        default: return (null);
    }
}

const styles = {
    eventTitle:{
        fontWeight:'bold',
        marginBottom:0,
    },
    eventAddress:{
        fontSize:14,
        color:'gray',
    },
    dateText:{
        color:'#FF6600',
        fontWeight:'600',
        marginBottom:0,
        fontSize:14
    },
    squareContainer:{
        display:'flex', 
        flexDirection:'row', 
        justifyContent:'space-around', 
        paddingTop:10
    },
    squareButton:{
        display:'flex',
        flexDirection:'column',
        width:100,
        height:100,
        borderRadius:10,
        justifyContent:'center',
        alignItems:'center',
        textAlign:'center',
        boxShadow:'1px 1px 5px rgba(0,0,0,0.2), 3px 3px 5px rgba(0,0,0,0.25)',
    }
}

const mapStateToProps = state => ({
    event : state.EventReducers.event,
});

const mapDispatchToProps = {
};
  
export default connect(mapStateToProps, mapDispatchToProps)(SelectedEvent);